
import { defineComponent, provide } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { apolloClient } from './apollo';
import '@/static/fonts/_fonts.scss';

export default defineComponent({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  }
});
