import { ConditionEnum } from "@/generated/graphql";

// eslint-disable-next-line max-len
export const capitalizeFirstLetter = (enumObj: typeof ConditionEnum): {id: string, name: string}[] => Object.keys(enumObj)
  .filter(key => typeof enumObj[key] === 'string')
  .map(key => ({
    id: enumObj[key],
    name: enumObj[key].toLowerCase().replace(/\b\w/g, (c: string) => c.toUpperCase()).replace(/_/g, ' ')
  }));

export default {};
