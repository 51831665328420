import { createApp } from 'vue';
import VueLazyload from 'vue3-lazyload';
import BootstrapVue3 from 'bootstrap-vue-3';
import FontAwesomeIcon from '@/plugins/font-awesome';
import Toaster from '@meforma/vue-toaster';
import VueSplide from '@splidejs/vue-splide';
import Notifications from '@kyvg/vue3-notification';
import VueAwesomeSwiper, { directive as SwiperDirective } from 'vue-awesome-swiper';
import VueUniversalModal from 'vue-universal-modal';
import mitt from 'mitt';
import VTooltip from 'v-tooltip';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import vSelect from 'vue-select';
import App from './App.vue';
import router from './router';
import store from './store';
import components from './components/index';
import { apolloProvider } from './apollo';

import 'animate.css/animate.min.css';
import 'v-tooltip/dist/v-tooltip.css';
import 'simple-line-icons/dist/styles/simple-line-icons.css';
import 'vue3-treeselect/dist/vue3-treeselect.css';
import 'vue-select/dist/vue-select.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

import '@/static/sass/style.scss';
import '@/static/sass/demo/_demo-base.scss';
import '@/static/fonts/_fonts.scss';
import '@/static/sass/app.scss';
import '@/static/css/porto-icons.min.css';

const app = createApp(App);

const emitter = mitt();

if (process.env.VUE_APP_NODE_ENV === 'development') {
  app.config.performance = true;
}

components.forEach((component) => {
  app.component(component.name, component);
});

app
  .use(store)
  .use(router)
  .use(apolloProvider)
  .use(VueAwesomeSwiper)
  .use(VueLazyload)
  .use(BootstrapVue3)
  .use(Toaster)
  .use(VueUniversalModal, {
    teleportTarget: '#modals',
    modalComponent: 'Modal'
  })
  .use(VTooltip)
  .use(Notifications)
  .use(VueSplide)
  .use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY })
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_API_KEY,
      language: 'en'
    }
  })
  .component('fa', FontAwesomeIcon)
  .component('v-select', vSelect)
  .mount('#app');

app.directive('swiper', SwiperDirective);
app.directive('tooltip', VTooltip.VTooltip);
app.provide('emitter', emitter);
