
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import { itemsCreateStore as store } from '@/store/item-create';
import { defineComponent } from 'vue';
import moment from 'moment';

export default defineComponent({
  components: { Datepicker },
  setup() {
    const format = (value) => `${moment(value).format('YYYY/MM/DD')}`;

    return {
      store,
      format
    };
  }
});
