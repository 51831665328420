
import { defineComponent, ref } from 'vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

import '@splidejs/vue-splide/dist/css/splide.min.css';

export default defineComponent({
  name: 'v-slider',
  props: {
    slides: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    Splide,
    SplideSlide
  },
  setup(props){
    const settings = ref({
      rewind: true,
      autoplay: true,
      pagination: props.slides.length > 1
    });

    const handleImageError = (e) => {
      // eslint-disable-next-line global-require, no-return-assign
      e.target.src = require('@/static/images/generic.jpeg');
    };


    return {
      handleImageError,
      settings
    };
  }
});
