
import { UPDATE_MODEL_VALUE, ITEMS_SEARCH } from '@/const/events';
import { SelectItem } from '@/types/item-create';
import { defineComponent, ref, watch, onMounted } from 'vue';
import VueSelect from 'vue-next-select';
import 'vue-next-select/dist/index.min.css';

export default defineComponent({
  name: 'v-select-filter',
  emits: [UPDATE_MODEL_VALUE, ITEMS_SEARCH],
  components: {
    VueSelect
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => [{ name: '', id: '' }]
    },
    modelValue: {
      type: [Object],
      default: () => ({})
    },
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const selectedOption = ref<SelectItem | null>(null);
    const selectOptions = ref<SelectItem[]>([]);

    onMounted(() => {
      if (props.modelValue) selectedOption.value = props.modelValue as SelectItem;
    });

    watch(
      () => props.modelValue,
      (newVal) => {
        selectedOption.value = newVal as SelectItem;
      }
    );

    watch(
      () => props.options,
      (newVal) => {
        selectOptions.value = newVal as SelectItem[];
      }
    );

    const handleInput = (inputEvent: InputEvent) => {
      const target = inputEvent.target as HTMLInputElement | null;
      emit(ITEMS_SEARCH, target?.value);
    };

    const onUpdate = (value) => {
      emit(UPDATE_MODEL_VALUE, value);
    };

    return {
      selectedOption,
      handleInput,
      onUpdate,
      selectOptions
    };
  }
});
