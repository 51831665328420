
import { SET_MODAL } from '@/const/events';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'terms-and-conditions-modal',
  emits: [SET_MODAL],
  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isShow = ref(props.openModal);

    function closeModal() {
      emit(SET_MODAL);
    }

    watch(
      () => props.openModal,
      (newVal) => {
        isShow.value = newVal;
      }
    );

    return {
      isShow,
      closeModal
    };
  }
});
