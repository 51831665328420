
import { SET_MODAL, IMAGE_UPLOADED } from '@/const/events';
import VueCropper from 'vue-cropperjs';
import { defineComponent, PropType, Ref, ref, watch } from 'vue';
import 'cropperjs/dist/cropper.css';

export default defineComponent({
  emits: [SET_MODAL, IMAGE_UPLOADED],
  components: {
    VueCropper
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object as PropType<File>
    }
  },
  setup(props, { emit }) {
    const isShow = ref(props.openModal);
    const imgSrc = ref<string | ArrayBuffer | null | undefined>(null);
    const cropImg = ref(null);
    const cropper = ref<Ref | null>(null);

    const closeModal = () => {
      emit(SET_MODAL);
    };

    const cropImage = () => {
      cropImg.value = cropper?.value.$refs.img.cropper.getCroppedCanvas().toDataURL();
    };

    const rotateLeft = () => {
      cropper?.value.$refs.img.cropper.rotate(-90);
    };

    const rotateRight = () => {
      cropper?.value.$refs.img.cropper.rotate(90);
    };

    const zoomIn = () => {
      cropper?.value.$refs.img.cropper.zoom(0.1);
    };

    const zoomOut = () => {
      cropper?.value.$refs.img.cropper.zoom(-0.1);
    };

    const submitImage = () => {
      cropImage();
      emit(IMAGE_UPLOADED, cropImg.value);
      closeModal();
    };

    watch(
      () => props.openModal,
      (newVal) => {
        isShow.value = newVal;
      }
    );

    watch(
      () => props.file,
      (newVal) => {
        if (newVal) {
          const reader = new FileReader();
          reader.onload = (event) => {
            imgSrc.value = event?.target?.result;
            cropper?.value?.$refs?.img?.cropper?.replace(event?.target?.result);
          };

          reader.readAsDataURL(newVal);
        }
      }
    );

    return {
      isShow,
      closeModal,
      imgSrc,
      cropper,
      cropImage,
      submitImage,
      zoomOut,
      zoomIn,
      rotateRight,
      rotateLeft,
      cropImg
    };
  }
});
