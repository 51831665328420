
import { UPDATE_MODEL_VALUE, ITEMS_SEARCH } from '@/const/events';
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';

interface Option {
  id: string;
  name: string;
}

export default defineComponent({
  name: 'v-form-checkbox',
  emits: [ITEMS_SEARCH, UPDATE_MODEL_VALUE],
  props: {
    modelValue: {
      type: [String, Boolean],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array as PropType<Option[]>,
      default: () => []
    },
    searchKey: {
      type: String
    }
  },
  setup(props, { emit }) {
    const checkValue = ref<string | boolean>('');

    const checkItem = (ev) => {
      emit(UPDATE_MODEL_VALUE, ev);
      emit(ITEMS_SEARCH, props.searchKey, ev);
    };

    onMounted(() => {
      if (props.modelValue) checkValue.value = props.modelValue;
    });

    watch(
      () => props.modelValue,
      (newVal) => {
        checkValue.value = newVal;
      }
    );

    return {
      checkValue,
      checkItem
    };
  }
});
