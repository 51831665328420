
import { useReCaptcha, IReCaptchaComposition } from 'vue-recaptcha-v3';
import { ON_HANDLE_CLICK } from '@/const/events';
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'v-captcha',
  emits: [ON_HANDLE_CLICK],
  props: {
    title: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_SITEKEY);

    const { recaptchaLoaded } = useReCaptcha() as IReCaptchaComposition;

    const recaptcha = async () => {
      await recaptchaLoaded().then(() => {
        emit(ON_HANDLE_CLICK);
      });
    };

    return {
      recaptcha,
      siteKey
    };
  }
});
