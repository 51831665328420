// eslint-disable-next-line
const convertFn = (num: number): string => {
  if (!num) {
    return `$0`;
  }
  // Checks for num
  if (num > 999 && num < 1000000) {
    return `$${(num / 1000).toFixed(0)}K`; // convert to K for number from > 1000 < 1 million
  }
  if (num > 1000000) {
    return `$${(num / 1000000).toFixed(0)}M`; // convert to M for number from > 1 million
  }
  if (num < 900) {
    return `$${num}`; // if value < 1000, nothing to do
  }

  return '';
};

export default convertFn;
