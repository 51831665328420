
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'v-card',
  props: {
    middleIcon: {
      type: String
    },
    icon: {
      type: String,
      default: 'shopping-cart'
    },
    headerTitle: {
      type: String
    },
    route: {
      type: Object,
      default: () => ({})
    },
    count: {
      type: [String, Number]
    },
    title: {
      type: String
    }
  }
});
