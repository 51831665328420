import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** Json type */
  JSON: any;
  /**
   * Loose type that allows any value. Be careful when passing in large `Int` or `Float` literals,
   * as they may not be parsed correctly on the server side. Use `String` literals if you are
   * dealing with really large numbers to be on the safe side.
   */
  Mixed: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
};

export type AccessTokenInput = {
  user_id?: InputMaybe<Scalars['ID']>;
};

export type AccessTokenResponse = {
  __typename?: 'AccessTokenResponse';
  access_token?: Maybe<Scalars['String']>;
};

export type AddInput = {
  filters?: InputMaybe<Array<FiltersInput>>;
  reason_for_allocation_comment?: InputMaybe<Scalars['String']>;
  reason_for_allocation_id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<AddTypes>;
  values?: InputMaybe<Array<Scalars['ID']>>;
};

/** Driver Add Types */
export enum AddTypes {
  /** Category */
  Category = 'category',
  /** Fleet_item */
  FleetItem = 'fleet_item',
  /** Fleet_type */
  FleetType = 'fleet_type',
  /** No_split */
  NoSplit = 'no_split',
  /** Organization */
  Organization = 'organization',
  /** Request_hub */
  RequestHub = 'request_hub',
  /** Service_line */
  ServiceLine = 'service_line'
}

export type Address = {
  __typename?: 'Address';
  addr1: Scalars['String'];
  addr2?: Maybe<Scalars['String']>;
  attention_to?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country?: Maybe<Country>;
  id: Scalars['ID'];
  organizationAddress?: Maybe<Array<Maybe<OrganizationAddress>>>;
  postal_code?: Maybe<Scalars['String']>;
  state_or_province?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['Int']>;
};

export type AddressInput = {
  addr1: Scalars['String'];
  addr2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  state_or_province: Scalars['String'];
  zip?: InputMaybe<Scalars['Int']>;
};

export type Affiliate = {
  __typename?: 'Affiliate';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Answer = {
  __typename?: 'Answer';
  answer?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  question: Question;
  url: Array<Scalars['String']>;
};

export type AnswerInput = {
  answer: Array<Scalars['ID']>;
  question_id: Scalars['ID'];
};

/** Allows to determine from which application a request was made or what application should receive a response */
export enum ApplicationRefererEnum {
  /** Determines that application call was made from ccm */
  Ccm = 'CCM',
  /** Determines that application call was made from marketplace */
  Marketplace = 'MARKETPLACE',
  /** Determines that application call was made from OEM portal */
  Oem = 'OEM'
}

/** Following enum represents approval group types */
export enum ApprovalGroupTypeEnum {
  /** This group can belong to many approvals */
  GeneralPurpose = 'GENERAL_PURPOSE',
  /** This group belongs to only one approval */
  SinglePurpose = 'SINGLE_PURPOSE'
}

export type ApprovalNotification = {
  __typename?: 'ApprovalNotification';
  created_at?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mark_as_read?: Maybe<Scalars['Boolean']>;
  notifiable: Notifiable;
  notifiable_type: Scalars['String'];
  status?: Maybe<ApprovalNotificationStatusEnum>;
  user?: Maybe<User>;
};

/** A paginated list of ApprovalNotification items. */
export type ApprovalNotificationPaginator = {
  __typename?: 'ApprovalNotificationPaginator';
  /** A list of ApprovalNotification items. */
  data: Array<ApprovalNotification>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum ApprovalNotificationStatusEnum {
  ApprovalApproved = 'APPROVAL_APPROVED',
  ApprovalPending = 'APPROVAL_PENDING',
  ApprovalRejected = 'APPROVAL_REJECTED',
  ApprovalReviewed = 'APPROVAL_REVIEWED',
  ApprovalSkipped = 'APPROVAL_SKIPPED'
}

export type ApprovalOption = {
  __typename?: 'ApprovalOption';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum ApprovalWorkFlowContingencyEnum {
  All = 'ALL',
  DoesNotHaveContingency = 'DOES_NOT_HAVE_CONTINGENCY',
  HasContingency = 'HAS_CONTINGENCY'
}

export enum ApprovalWorkFlowObjectStateEnum {
  Approved = 'APPROVED',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  Reviewed = 'REVIEWED'
}

export enum ApprovalWorkFlowTypeEnum {
  NewCapital = 'NEW_CAPITAL',
  Replacement = 'REPLACEMENT',
  UnplannedNewCapital = 'UNPLANNED_NEW_CAPITAL',
  UnplannedReplacement = 'UNPLANNED_REPLACEMENT'
}

export type ApprovalWorkflow = {
  __typename?: 'ApprovalWorkflow';
  active?: Maybe<Scalars['Boolean']>;
  approvalGroups: Array<ApprovalWorkflowGroup>;
  approvalGroupsPivot: Array<ApprovalWorkflowApprovalWorkflowGroupPivot>;
  author: User;
  categories: Array<Category>;
  contingency: ApprovalWorkFlowContingencyEnum;
  costCenters: Array<CostCenter>;
  customRules?: Maybe<Array<ApprovalWorkflowCustom>>;
  departments: Array<LocationDept>;
  fallback?: Maybe<Scalars['Boolean']>;
  groups: Array<ApprovalWorkflowGroup>;
  id: Scalars['ID'];
  locations: Array<Location>;
  mergedGroups: Array<MemberApprovalGroup>;
  name?: Maybe<Scalars['String']>;
  objects: Array<ApprovalWorkflowObject>;
  organizations: Array<Organization>;
  priority?: Maybe<Scalars['Int']>;
  serviceLines: Array<ServiceLine>;
  type: WorkFlowTypeEnum;
  types: Array<ApprovalWorkflowType>;
  values: Array<ApprovalWorkflowValue>;
};

export type ApprovalWorkflowApprovalWorkflowGroupPivot = {
  __typename?: 'ApprovalWorkflowApprovalWorkflowGroupPivot';
  approvalWorkfow: ApprovalWorkflow;
  approvalWorkfowField: ApprovalWorkflowField;
  group: ApprovalWorkflowGroup;
  id: Scalars['ID'];
  sort_order: Scalars['Int'];
};

/** Workflow Condition input */
export type ApprovalWorkflowConditionInput = {
  /** From value condition that starts from specified value. */
  from?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  /** To value condition that ends at specified value. */
  to?: InputMaybe<Scalars['Float']>;
  /** Reviewing field Id */
  workflow_field_id?: InputMaybe<Scalars['ID']>;
};

export type ApprovalWorkflowCustom = {
  __typename?: 'ApprovalWorkflowCustom';
  answer: Question;
  id: Scalars['ID'];
  question: Question;
  workflow: ApprovalWorkflow;
};

export type ApprovalWorkflowField = {
  __typename?: 'ApprovalWorkflowField';
  code: ReviewingFieldEnum;
  fieldName?: Maybe<ApprovalWorkflowFieldName>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ApprovalWorkflowFieldName = {
  __typename?: 'ApprovalWorkflowFieldName';
  code: ReviewingFieldEnum;
  label?: Maybe<Scalars['String']>;
};

export enum ApprovalWorkflowFieldTypeEnum {
  Questions = 'QUESTIONS',
  RequestHubControls = 'REQUEST_HUB_CONTROLS'
}

export type ApprovalWorkflowFilterInput = {
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type ApprovalWorkflowGroup = {
  __typename?: 'ApprovalWorkflowGroup';
  approvals: Array<ApprovalWorkflow>;
  field?: Maybe<Field>;
  field_id?: Maybe<Scalars['ID']>;
  field_type?: Maybe<ApprovalWorkflowFieldTypeEnum>;
  id: Scalars['ID'];
  members: Array<ApprovalWorkflowGroupMember>;
  name: Scalars['String'];
  openApprovals: Scalars['Int'];
  sort_order?: Maybe<Scalars['Int']>;
  type: ApprovalGroupTypeEnum;
};

export type ApprovalWorkflowGroupInput = {
  members: Array<Scalars['ID']>;
  name: Scalars['String'];
  type: ApprovalGroupTypeEnum;
};

export type ApprovalWorkflowGroupMember = {
  __typename?: 'ApprovalWorkflowGroupMember';
  approver?: Maybe<User>;
  id: Scalars['ID'];
  sort_order: Scalars['Int'];
};

/** A paginated list of ApprovalWorkflowGroup items. */
export type ApprovalWorkflowGroupPaginator = {
  __typename?: 'ApprovalWorkflowGroupPaginator';
  /** A list of ApprovalWorkflowGroup items. */
  data: Array<ApprovalWorkflowGroup>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ApprovalWorkflowInput = {
  conditions: Array<ApprovalWorkflowConditionInput>;
  contingency: ApprovalWorkFlowContingencyEnum;
  custom_question_answers?: InputMaybe<Array<CustomQuestionAnswer>>;
  id?: InputMaybe<Scalars['ID']>;
  rules: ApprovalWorkflowRulesInput;
  save_anyway?: InputMaybe<Scalars['Boolean']>;
  types?: InputMaybe<Array<ApprovalWorkFlowTypeEnum>>;
  user_groups: Array<ApprovalWorkflowUserGroup>;
  workflow_name?: InputMaybe<Scalars['String']>;
  workflow_type: WorkFlowTypeEnum;
};

export type ApprovalWorkflowObject = {
  __typename?: 'ApprovalWorkflowObject';
  groups?: Maybe<Array<ApprovalWorkflowGroup>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  object: RequestHub;
  pivotGroups?: Maybe<Array<ApprovalWorkflowGroup>>;
  state: ApprovalWorkFlowObjectStateEnum;
};

/** A paginated list of ApprovalWorkflow items. */
export type ApprovalWorkflowPaginator = {
  __typename?: 'ApprovalWorkflowPaginator';
  /** A list of ApprovalWorkflow items. */
  data: Array<ApprovalWorkflow>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ApprovalWorkflowPriorityInput = {
  serial_number: Scalars['Int'];
  workflow_id: Scalars['ID'];
};

export type ApprovalWorkflowReviewField = {
  __typename?: 'ApprovalWorkflowReviewField';
  code?: Maybe<Scalars['String']>;
  control_type: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type ApprovalWorkflowRule = {
  __typename?: 'ApprovalWorkflowRule';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type ApprovalWorkflowRulesInput = {
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type ApprovalWorkflowType = {
  __typename?: 'ApprovalWorkflowType';
  id: Scalars['ID'];
  type: ApprovalWorkFlowTypeEnum;
};

/** Approval user group input */
export type ApprovalWorkflowUserGroup = {
  /** Reviewing field Id */
  field_id?: InputMaybe<Scalars['ID']>;
  /** Reviewing field type */
  field_type?: InputMaybe<ApprovalWorkflowFieldTypeEnum>;
  id?: InputMaybe<Scalars['ID']>;
  /** Tag Id to be tagged to group */
  tag_id?: InputMaybe<Scalars['ID']>;
  /** Enum to know if a group has been selected or users */
  type?: InputMaybe<ApprovalGroupTypeEnum>;
  /** Array of user Ids in the group */
  user_ids: Array<Scalars['ID']>;
  /** Reviewing field Id */
  workflow_field_id?: InputMaybe<Scalars['ID']>;
};

export type ApprovalWorkflowValue = {
  __typename?: 'ApprovalWorkflowValue';
  from?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  to?: Maybe<Scalars['Float']>;
};

export type AsimilyData = {
  __typename?: 'AsimilyData';
  asimilyRecall?: Maybe<AsimilyRecall>;
  asimily_data: Scalars['JSON'];
  ce_number: Scalars['String'];
  fleet_item_id: Scalars['ID'];
  id: Scalars['ID'];
  mac_address: Scalars['String'];
};

export type AsimilyRecall = {
  __typename?: 'AsimilyRecall';
  id: Scalars['ID'];
  ip_address: Scalars['String'];
  mac_address: Scalars['String'];
  recalls?: Maybe<Scalars['JSON']>;
};

export type AssetAgeRange = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type AssetIssue = {
  __typename?: 'AssetIssue';
  /** Address Id */
  address_id?: Maybe<Scalars['Int']>;
  age?: Maybe<Scalars['Float']>;
  /** Asset tag */
  asset_tag?: Maybe<Scalars['String']>;
  /** Budget name of asset where this item has been added */
  budget?: Maybe<Scalars['String']>;
  /** Budget Id of the asset */
  budget_id?: Maybe<Scalars['ID']>;
  capital_number?: Maybe<Scalars['String']>;
  /** CE number */
  ce_number?: Maybe<Scalars['String']>;
  costCenter?: Maybe<CostCenter>;
  cost_center?: Maybe<Scalars['String']>;
  cost_center_id?: Maybe<Scalars['ID']>;
  dispositionReason?: Maybe<DispositionReason>;
  disposition_date?: Maybe<Scalars['Date']>;
  /** Organization of the asset */
  facility?: Maybe<Scalars['String']>;
  floor?: Maybe<Floor>;
  /** Id of the item */
  id?: Maybe<Scalars['ID']>;
  labels: Array<GlobalLabel>;
  /** Location of the asset */
  location?: Maybe<Scalars['String']>;
  /** Location Id */
  location_id?: Maybe<Scalars['Int']>;
  location_room?: Maybe<Scalars['String']>;
  /** Manufacturer name */
  manufacturer?: Maybe<Scalars['String']>;
  /** Product mpn */
  mpn?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  orderHubs: Array<OrderHub>;
  /** Product description */
  product_description?: Maybe<Scalars['String']>;
  product_name?: Maybe<Scalars['String']>;
  /** Purchase date */
  purchase_date?: Maybe<Scalars['String']>;
  /** Purchase price */
  purchase_price?: Maybe<Scalars['Float']>;
  replacement_cost?: Maybe<Scalars['Float']>;
  /** Score of the item */
  score?: Maybe<Scalars['Int']>;
  /** Serial number */
  serial_number?: Maybe<Scalars['String']>;
  serviceLine?: Maybe<ServiceLine>;
  service_line?: Maybe<Scalars['String']>;
  /** Service Line Id of the asset */
  service_line_id?: Maybe<Scalars['ID']>;
  stage?: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  useful_life?: Maybe<Scalars['Float']>;
};

/** A paginated list of AssetIssue items. */
export type AssetIssuePaginator = {
  __typename?: 'AssetIssuePaginator';
  /** A list of AssetIssue items. */
  data: Array<AssetIssue>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type AssetReplacementType = {
  __typename?: 'AssetReplacementType';
  age?: Maybe<Scalars['Float']>;
  asset_tag?: Maybe<Scalars['String']>;
  budget_name?: Maybe<Scalars['String']>;
  capital_number?: Maybe<Scalars['String']>;
  ce_number: Scalars['String'];
  costCenter?: Maybe<CostCenter>;
  floor?: Maybe<Floor>;
  id: Scalars['ID'];
  labels: Array<GlobalLabel>;
  location?: Maybe<Scalars['String']>;
  location_room?: Maybe<Scalars['String']>;
  manufacturer: Scalars['String'];
  mpn: Scalars['String'];
  orderHubCount: Scalars['Int'];
  orderHubs: Array<OrderHub>;
  organization?: Maybe<Scalars['String']>;
  product_name: Scalars['String'];
  purchase_date?: Maybe<Scalars['Date']>;
  purchase_price?: Maybe<Scalars['Float']>;
  quarter?: Maybe<FleetItemQuarterEnum>;
  replacement_cost?: Maybe<Scalars['Float']>;
  requestHubCount: Scalars['Int'];
  requestHubs: Array<RequestHubProductReplaceItem>;
  score: Scalars['Int'];
  selected: Scalars['Boolean'];
  serial_number?: Maybe<Scalars['String']>;
  serviceLine?: Maybe<ServiceLine>;
  tags: Array<Tag>;
  useful_life?: Maybe<Scalars['Float']>;
};

/** A paginated list of AssetReplacementType items. */
export type AssetReplacementTypePaginator = {
  __typename?: 'AssetReplacementTypePaginator';
  /** A list of AssetReplacementType items. */
  data: Array<AssetReplacementType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum AssetSelectionSelectOptionsEnum {
  All = 'ALL',
  Deselected = 'DESELECTED',
  Selected = 'SELECTED'
}

export type AssetsFilterInput = {
  budget_id?: InputMaybe<Scalars['ID']>;
  category_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  cost_center_id?: InputMaybe<Scalars['ID']>;
  department_id?: InputMaybe<Scalars['ID']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  good: Scalars['Boolean'];
  keywords?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['ID']>;
  manufacturer_id?: InputMaybe<Scalars['ID']>;
  organization_id?: InputMaybe<Scalars['ID']>;
  plan: Scalars['Boolean'];
  product_id?: InputMaybe<Scalars['ID']>;
  region_id?: InputMaybe<Scalars['ID']>;
  replace: Scalars['Boolean'];
  score: Scalars['Boolean'];
  service_line_id?: InputMaybe<Scalars['ID']>;
};

export type AssetsReplaced = {
  __typename?: 'AssetsReplaced';
  ce_number: Scalars['String'];
  id: Scalars['ID'];
  manufacturer: Scalars['String'];
  order_hub: Scalars['String'];
  order_hub_id: Scalars['ID'];
  organization: Scalars['String'];
  product: Scalars['String'];
  service_line: Scalars['String'];
};

export type AssetsReplacedFilters = {
  category_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  organization_id?: InputMaybe<Scalars['Int']>;
  service_line_id?: InputMaybe<Scalars['Int']>;
  top_level_category_id?: InputMaybe<Scalars['ID']>;
};

export enum AssetsReplacedOrderByColumnEnum {
  Category = 'category',
  CeNumber = 'ce_number',
  Organization = 'organization',
  ProductDescription = 'product_description',
  ServiceLine = 'service_line',
  TopCategoryName = 'top_category_name'
}

export type AssetsReplacedOrderByInput = {
  column: AssetsReplacedOrderByColumnEnum;
  order?: InputMaybe<OrderByDirectionEnum>;
};

/** A paginated list of AssetsReplaced items. */
export type AssetsReplacedPaginator = {
  __typename?: 'AssetsReplacedPaginator';
  /** A list of AssetsReplaced items. */
  data: Array<AssetsReplaced>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type AssetsStatsType = {
  __typename?: 'AssetsStatsType';
  avg_score: Scalars['Float'];
  count: Scalars['Int'];
  has_children?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum AssetsToBeReplacedGroupByEnum {
  Category = 'CATEGORY',
  Vendor = 'VENDOR'
}

export type AssetsTotalStatsType = {
  __typename?: 'AssetsTotalStatsType';
  asset_count: Scalars['Int'];
  avg_age: Scalars['Float'];
  avg_score: Scalars['Float'];
  replacement_cost: Scalars['Float'];
};

export type Auction = {
  __typename?: 'Auction';
  begin_at: Scalars['String'];
  created_at?: Maybe<Scalars['String']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  end_at?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lots?: Maybe<Array<Maybe<AuctionLot>>>;
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

export type AuctionLot = {
  __typename?: 'AuctionLot';
  active: Scalars['Boolean'];
  auction?: Maybe<Auction>;
  bid?: Maybe<Bid>;
  bid_count: Scalars['Int'];
  bid_increment?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<Item>>>;
  name: Scalars['String'];
  ownBidsCount: Scalars['Int'];
  reserve_amount?: Maybe<Scalars['Float']>;
  winner?: Maybe<User>;
  winning_bid_amount?: Maybe<Scalars['Float']>;
};

export type AuctionLotAskQuestionInput = {
  question?: InputMaybe<Scalars['String']>;
};

export type AuctionLotFilters = {
  created_at?: InputMaybe<DateRange>;
  winner_id?: InputMaybe<Scalars['ID']>;
};

/** A paginated list of AuctionLot items. */
export type AuctionLotPaginator = {
  __typename?: 'AuctionLotPaginator';
  /** A list of AuctionLot items. */
  data: Array<AuctionLot>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum AuctionOrderByColumnEnum {
  BeginAt = 'BEGIN_AT',
  EndAt = 'END_AT',
  Id = 'ID'
}

export type AuctionOrderByInput = {
  column: AuctionOrderByColumnEnum;
  direction?: InputMaybe<OrderByDirectionEnum>;
};

/** A paginated list of Auction items. */
export type AuctionPaginator = {
  __typename?: 'AuctionPaginator';
  /** A list of Auction items. */
  data: Array<Auction>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum AuctionStatusEnum {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export enum AuctionStatusFilter {
  Active = 'ACTIVE',
  History = 'HISTORY'
}

export type AuctionsFilters = {
  auction_date?: InputMaybe<DateRange>;
  created_at?: InputMaybe<DateRange>;
  end_at?: InputMaybe<DateRange>;
  status?: InputMaybe<AuctionStatusFilter>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  access_token?: Maybe<Scalars['String']>;
  expires_in?: Maybe<Scalars['Int']>;
  firstLogin: Scalars['Boolean'];
  refresh_token?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  user_permissions: UserPermissionsPayload;
};

export type AutomaticLabel = {
  __typename?: 'AutomaticLabel';
  code: AutomaticLabelEnum;
  id: Scalars['ID'];
  selected?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export enum AutomaticLabelEnum {
  Orders = 'ORDERS'
}

export type AverageAgeByCategory = {
  __typename?: 'AverageAgeByCategory';
  avg_age: Scalars['Float'];
  has_children: Scalars['Boolean'];
  id: Scalars['ID'];
  lifespan: Scalars['Int'];
  name: Scalars['String'];
};

export type AverageAgeByFleet = {
  __typename?: 'AverageAgeByFleet';
  avg_age: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Bid = {
  __typename?: 'Bid';
  amount?: Maybe<Scalars['Float']>;
  automatic_increment?: Maybe<Scalars['Boolean']>;
  bid_count?: Maybe<Scalars['Int']>;
  customer?: Maybe<User>;
  id: Scalars['ID'];
  lot?: Maybe<AuctionLot>;
  max_bid_flat?: Maybe<Scalars['Boolean']>;
};

export type BucketCondition = {
  __typename?: 'BucketCondition';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  locked?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type BudgetComparison = {
  __typename?: 'BudgetComparison';
  id: Scalars['ID'];
  name: Scalars['String'];
  red_items_amount?: Maybe<Scalars['Float']>;
  red_items_count?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Float']>;
  total_fleet_items?: Maybe<Scalars['Int']>;
};

export type BudgetDispositionFleetFilterInput = {
  /** Capital Budget */
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Product Category */
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Cost Center */
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Department */
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  hide_items_in_order?: Scalars['Boolean'];
  /** Location */
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Manufacturer */
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Facility */
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Model / MPN */
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Service Line */
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Stage Of Life */
  stage_of_life?: InputMaybe<Array<StageOfLifeEnum>>;
};

export type BudgetGroup = {
  __typename?: 'BudgetGroup';
  active: Scalars['Boolean'];
  allSelectedFleetAmount: Scalars['Float'];
  allSelectedRequestsAmount: Scalars['Float'];
  budgets?: Maybe<Array<CapitalCalendarBudget>>;
  childrens?: Maybe<Array<BudgetGroup>>;
  created_by: Scalars['String'];
  delete_requested_by?: Maybe<User>;
  delete_requested_date?: Maybe<Scalars['DateTime']>;
  end_date: Scalars['Date'];
  fleetItemsCount: Scalars['Int'];
  id: Scalars['ID'];
  modelBudgets?: Maybe<Array<ModelBudget>>;
  name: Scalars['String'];
  newContigencySpent: Scalars['Float'];
  newPlannedTotal: Scalars['Float'];
  newSpentTotal: Scalars['Float'];
  organization_id: Scalars['Int'];
  parent_id?: Maybe<Scalars['ID']>;
  redItemsAmount: Scalars['Float'];
  redItemsCount: Scalars['Int'];
  replacementContigencySpent: Scalars['Float'];
  replacementPlannedTotal: Scalars['Float'];
  replacementSpentTotal: Scalars['Float'];
  start_date: Scalars['Date'];
  totalAmount: Scalars['Float'];
  totalFleetItemPlannedSpend: Scalars['Float'];
  totalNewCapitalSpendingCost: Scalars['Float'];
  totalNewContingencyCost: Scalars['Float'];
  totalNewSpendingPaidCost: Scalars['Float'];
  totalPaidReplacementSpent: Scalars['Float'];
  totalPlannedAmount: Scalars['Float'];
  totalReplacementContingencyCost: Scalars['Float'];
  totalReplacementRequestSpendingCost: Scalars['Float'];
  totalReplacementSpendingPaidCost: Scalars['Float'];
  totalRequestHubPlannedSpend: Scalars['Float'];
  type?: Maybe<BudgetGroupTypeEnum>;
};

export type BudgetGroupCreateInput = {
  end_date: Scalars['Date'];
  name: Scalars['String'];
  parent_id?: InputMaybe<Scalars['ID']>;
  start_date: Scalars['Date'];
  type: BudgetGroupTypeEnum;
};

/** A paginated list of BudgetGroup items. */
export type BudgetGroupPaginator = {
  __typename?: 'BudgetGroupPaginator';
  /** A list of BudgetGroup items. */
  data: Array<BudgetGroup>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Allows to determine the type of budget */
export enum BudgetGroupTypeEnum {
  /** Determines that budget is capital calendar budget */
  Budget = 'BUDGET',
  /** Determines that budget is model budget */
  ModelBudget = 'MODEL_BUDGET'
}

export type BudgetGroupUpdateInput = {
  end_date: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  parent_id?: InputMaybe<Scalars['ID']>;
  start_date: Scalars['Date'];
  type: BudgetGroupTypeEnum;
};

export type BudgetList = {
  __typename?: 'BudgetList';
  approved_requests?: Maybe<Scalars['Int']>;
  approved_spending?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  red_items_amount?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Int']>;
  selected_approved_requests?: Maybe<Scalars['Int']>;
};

export type BudgetNewCapitalStats = {
  __typename?: 'BudgetNewCapitalStats';
  new_capital_spent: Scalars['Float'];
  new_capital_statuses: Array<BudgetOrdersStatus>;
  new_capital_value: Scalars['Float'];
  project_completed: Scalars['Int'];
  project_in_progress: Scalars['Int'];
};

export type BudgetOrdersStatus = {
  __typename?: 'BudgetOrdersStatus';
  code: OrderHubStatusEnum;
  count: Scalars['Int'];
};

export type BudgetReplacementStats = {
  __typename?: 'BudgetReplacementStats';
  assets_replaced: Scalars['Int'];
  assets_to_be_replaced: Scalars['Int'];
  replacement_spent: Scalars['Float'];
  replacement_statuses: Array<BudgetOrdersStatus>;
  replacement_value: Scalars['Float'];
};

export type BudgetSetting = {
  __typename?: 'BudgetSetting';
  budget_type: Scalars['String'];
  code: BudgetSettingEnum;
  id: Scalars['ID'];
  setting?: Maybe<BudgetType>;
};

/** Resolves budget settings */
export enum BudgetSettingEnum {
  /** Determines that this budget won't be calculated on the overall metrics of a group */
  ExcludeFromOverallMetrics = 'EXCLUDE_FROM_OVERALL_METRICS',
  /** Determines that this budget does not have max spendings */
  NoMaxAmount = 'NO_MAX_AMOUNT'
}

export type BudgetSettingInput = {
  budget_id: Scalars['ID'];
  code: BudgetSettingEnum;
  type: BudgetTypeEnum;
};

export type BudgetSplitByFilter = {
  __typename?: 'BudgetSplitByFilter';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** Resolves by which split type the driver to proceed on budget manager */
export enum BudgetSplitTypeEnum {
  /** Determines the type to be category */
  Category = 'CATEGORY',
  /** Determines the type to not be splited */
  NoSplit = 'NO_SPLIT',
  /** Determines the type to be organization */
  Organization = 'ORGANIZATION',
  /** Determines the type to be service_line */
  ServiceLine = 'SERVICE_LINE'
}

export enum BudgetStatsEnum {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Planning = 'PLANNING'
}

export type BudgetStatsFilter = {
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export enum BudgetStatusEnum {
  Active = 'ACTIVE',
  AssetSelection = 'ASSET_SELECTION',
  BudgetAllocation = 'BUDGET_ALLOCATION',
  BudgetDetails = 'BUDGET_DETAILS',
  BudgetSummary = 'BUDGET_SUMMARY',
  Completed = 'COMPLETED'
}

export type BudgetSummary = {
  __typename?: 'BudgetSummary';
  id: Scalars['ID'];
  name: Scalars['String'];
  new_cost_sum: Scalars['Float'];
  new_replacement_cost_sum: Scalars['Float'];
  replacement_cost_sum: Scalars['Float'];
  replacement_count: Scalars['Int'];
  replacement_new_cost_sum: Scalars['Float'];
  replacement_request_count: Scalars['Int'];
  request_hubs_count: Scalars['Int'];
  request_hubs_product_count: Scalars['Int'];
  request_replacement_count: Scalars['Int'];
  total: Scalars['Float'];
};

export type BudgetSummaryPie = {
  __typename?: 'BudgetSummaryPie';
  id: Scalars['ID'];
  name: Scalars['String'];
  new_cost_sum: Scalars['Float'];
  replacement_cost_sum: Scalars['Float'];
};

export type BudgetTotalsStats = {
  __typename?: 'BudgetTotalsStats';
  new_capital_spent: Scalars['Float'];
  planned: Scalars['Float'];
  replacement_spent: Scalars['Float'];
  unplanned_spent: Scalars['Float'];
};

export type BudgetType = {
  __typename?: 'BudgetType';
  amount: Scalars['Int'];
  assets_amount?: Maybe<Scalars['Int']>;
  budgetGroup: BudgetGroup;
  conditions?: Maybe<Array<Maybe<BucketCondition>>>;
  currentVersion?: Maybe<BudgetVersion>;
  end_date: Scalars['Date'];
  fit_to_budget: Scalars['Boolean'];
  fleetCount: Scalars['Int'];
  fleetItems?: Maybe<Array<FleetItem>>;
  has_unallowed_fleet_items: Scalars['Boolean'];
  has_version: Scalars['Boolean'];
  id: Scalars['ID'];
  individual_selected_replacement_cost: Scalars['Float'];
  individual_selected_replacement_count: Scalars['Int'];
  name: Scalars['String'];
  newContingencyCost: Scalars['Float'];
  newContingencySpent: Scalars['Float'];
  new_capital_cost: Scalars['Float'];
  new_capital_spending_cost: Scalars['Float'];
  new_capital_spending_count: Scalars['Int'];
  new_capital_spent: Scalars['Float'];
  new_capital_spent_count: Scalars['Int'];
  new_capital_spent_paid: Scalars['Float'];
  new_capital_spent_paid_count: Scalars['Int'];
  new_spending_paid_cost: Scalars['Float'];
  planned_spend: Scalars['Float'];
  quarter_range?: Maybe<QuarterRangeType>;
  red_amount: Scalars['Float'];
  red_count: Scalars['Int'];
  replacementContingencyCost: Scalars['Float'];
  replacementContingencySpent: Scalars['Float'];
  replacement_cost: Scalars['Float'];
  replacement_request_cost: Scalars['Float'];
  replacement_request_count: Scalars['Int'];
  replacement_request_equipment_cost: Scalars['Float'];
  replacement_request_spending_cost: Scalars['Float'];
  replacement_request_spending_count: Scalars['Int'];
  replacement_request_spent: Scalars['Float'];
  replacement_spending_paid_cost: Scalars['Float'];
  replacement_spent: Scalars['Float'];
  replacement_spent_count: Scalars['Int'];
  replacement_spent_paid: Scalars['Float'];
  replacement_spent_paid_count: Scalars['Int'];
  requestHubs?: Maybe<Array<RequestHub>>;
  requestHubsCount: Scalars['Int'];
  request_product_count: Scalars['Int'];
  request_replacement_count: Scalars['Int'];
  settings?: Maybe<Array<Maybe<BudgetSetting>>>;
  spending_unpaid_cost: Scalars['Float'];
  split_type: BudgetSplitTypeEnum;
  start_date: Scalars['Date'];
  status: BudgetStatusEnum;
  unallocated_approved_requests: Scalars['Float'];
  unallocated_red_amount: Scalars['Float'];
  unplanned_spent: Scalars['Float'];
  yellow_count: Scalars['Int'];
};

/** Allows to determine the type of budget */
export enum BudgetTypeEnum {
  /** Determines that budget is capital calendar budget */
  Budget = 'BUDGET',
  /** Determines that budget is model budget */
  ModelBudget = 'MODEL_BUDGET'
}

export type BudgetVersion = {
  __typename?: 'BudgetVersion';
  budget: CapitalCalendarBudget;
  conditions?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  user: User;
};

export type BudgetWidgetType = {
  __typename?: 'BudgetWidgetType';
  amount: Scalars['Float'];
  end_date: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  new_capital_spent: Scalars['Float'];
  replacement_spent: Scalars['Float'];
  start_date: Scalars['Date'];
  status: BudgetStatusEnum;
  unplanned_spent: Scalars['Float'];
};

export type BulkTagInput = {
  action: Scalars['String'];
  exclude_fleet_items?: InputMaybe<Array<Scalars['String']>>;
  filters?: InputMaybe<FleetFilterInput>;
  fleet_items?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['ID']>>;
};

export type CapitalCalendarBudget = {
  __typename?: 'CapitalCalendarBudget';
  budgetGroup: BudgetGroup;
  budget_name: Scalars['String'];
  budget_value: Scalars['Float'];
  created_at?: Maybe<Scalars['DateTime']>;
  created_by: User;
  currentVersion?: Maybe<BudgetVersion>;
  edit_user?: Maybe<User>;
  end_date: Scalars['Date'];
  fit_to_budget: Scalars['Boolean'];
  fleetCount: Scalars['Int'];
  fleetItems?: Maybe<Array<Maybe<FleetItem>>>;
  has_unallowed_fleet_items: Scalars['Boolean'];
  has_version: Scalars['Boolean'];
  id: Scalars['ID'];
  newContingencyCost: Scalars['Float'];
  newContingencySpent: Scalars['Float'];
  new_capital_cost: Scalars['Float'];
  new_capital_spending_cost: Scalars['Float'];
  new_capital_spent: Scalars['Float'];
  new_capital_spent_count: Scalars['Int'];
  new_capital_spent_paid: Scalars['Float'];
  new_capital_spent_paid_count: Scalars['Int'];
  new_spending_paid_cost: Scalars['Float'];
  organization: Organization;
  planned_spend: Scalars['Float'];
  red_amount: Scalars['Float'];
  red_count: Scalars['Int'];
  replacementContingencyCost: Scalars['Float'];
  replacementContingencySpent: Scalars['Float'];
  replacement_cost: Scalars['Float'];
  replacement_request_cost: Scalars['Float'];
  replacement_request_count: Scalars['Int'];
  replacement_request_equipment_cost: Scalars['Float'];
  replacement_request_spending_cost: Scalars['Float'];
  replacement_request_spent: Scalars['Float'];
  replacement_spending_paid_cost: Scalars['Float'];
  replacement_spent: Scalars['Float'];
  replacement_spent_count: Scalars['Int'];
  replacement_spent_paid: Scalars['Float'];
  replacement_spent_paid_count: Scalars['Int'];
  requestHubsCount: Scalars['Int'];
  request_product_count: Scalars['Int'];
  request_replacement_count: Scalars['Int'];
  settings?: Maybe<Array<Maybe<BudgetSetting>>>;
  split_type?: Maybe<BudgetSplitTypeEnum>;
  start_date: Scalars['Date'];
  status: BudgetStatusEnum;
  unplanned_spent: Scalars['Float'];
  updated_at?: Maybe<Scalars['DateTime']>;
  version_id?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<Maybe<BudgetVersion>>>;
  yellow_count: Scalars['Int'];
};

/** A paginated list of CapitalCalendarBudget items. */
export type CapitalCalendarBudgetPaginator = {
  __typename?: 'CapitalCalendarBudgetPaginator';
  /** A list of CapitalCalendarBudget items. */
  data: Array<CapitalCalendarBudget>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Cart = {
  __typename?: 'Cart';
  braintree_token?: Maybe<Scalars['String']>;
  customer?: Maybe<User>;
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<CartItem>>>;
  shipping?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxes?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  type: CartTypeEnum;
};

export type CartAddItemInput = {
  itemId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
};

export type CartAddOrUpdateItemInput = {
  itemId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
};

export type CartClearInput = {
  id: Scalars['ID'];
};

export type CartItem = {
  __typename?: 'CartItem';
  auction?: Maybe<Auction>;
  cart?: Maybe<Cart>;
  id: Scalars['ID'];
  internal_redeployment_item?: Maybe<InternalRedeployment>;
  item?: Maybe<Item>;
  item_id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  sell_price?: Maybe<Scalars['Float']>;
};

export type CartItemDeleteInput = {
  id: Scalars['ID'];
};

export type CartItemUpdateInput = {
  quantity?: InputMaybe<Scalars['Int']>;
};

export enum CartTypeEnum {
  Auction = 'AUCTION',
  InternalRedeployment = 'INTERNAL_REDEPLOYMENT',
  Items = 'ITEMS'
}

export type CategoriesByOrganization = {
  __typename?: 'CategoriesByOrganization';
  data: Array<Maybe<CategoryWithOrganizationStats>>;
  is_my_organization: Scalars['Boolean'];
  is_part_of_ratio_analysis?: Maybe<Scalars['Boolean']>;
};

export type Category = {
  __typename?: 'Category';
  categoryAttributes?: Maybe<Array<Maybe<CategoryAttribute>>>;
  children: Array<Category>;
  description: Scalars['String'];
  fleetType?: Maybe<FleetType>;
  fleet_type?: Maybe<FleetType>;
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  marketplaceChildren: Array<Category>;
  name: Scalars['String'];
  parent?: Maybe<Category>;
  show_in_marketplace: Scalars['Boolean'];
  vendors?: Maybe<Array<Vendor>>;
};


export type CategoryVendorsArgs = {
  authBasedVendors?: InputMaybe<Scalars['Boolean']>;
};

export type CategoryAttribute = {
  __typename?: 'CategoryAttribute';
  category: Category;
  id: Scalars['ID'];
  is_primary: Scalars['Boolean'];
  name: Scalars['String'];
  productCategoryAttributes?: Maybe<Array<Maybe<ProductCategoryAttribute>>>;
};

export type CategoryLifespanInput = {
  category_id: Scalars['ID'];
  lifespan: Scalars['Int'];
};

export type CategoryOrganizationVendorContract = {
  __typename?: 'CategoryOrganizationVendorContract';
  created_at: Scalars['DateTime'];
  created_by?: Maybe<User>;
  end_date: Scalars['Date'];
  files?: Maybe<Array<Maybe<ContractFile>>>;
  id: Scalars['ID'];
  start_date: Scalars['Date'];
  state: CategoryOrganizationVendorContractStateEnum;
  terms: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export enum CategoryOrganizationVendorContractStateEnum {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Expired = 'EXPIRED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW'
}

/** A paginated list of Category items. */
export type CategoryPaginator = {
  __typename?: 'CategoryPaginator';
  /** A list of Category items. */
  data: Array<Category>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type CategoryTreeOptionType = {
  __typename?: 'CategoryTreeOptionType';
  children: Array<CategoryTreeOptionType>;
  children_exists: Scalars['Boolean'];
  key: Scalars['ID'];
  title: Scalars['String'];
};

/** A paginated list of CategoryTreeOptionType items. */
export type CategoryTreeOptionTypePaginator = {
  __typename?: 'CategoryTreeOptionTypePaginator';
  /** A list of CategoryTreeOptionType items. */
  data: Array<CategoryTreeOptionType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type CategoryTreeType = {
  __typename?: 'CategoryTreeType';
  children: Array<CategoryTreeType>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CategoryVendor = {
  __typename?: 'CategoryVendor';
  asset_count: Scalars['Int'];
  has_children: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  vendors: Array<Vendor>;
};

export type CategoryWithOrganizationStats = {
  __typename?: 'CategoryWithOrganizationStats';
  category_id: Scalars['Int'];
  category_name: Scalars['String'];
  fleet_items_count: Scalars['Int'];
};

export type CcmLitePermission = {
  __typename?: 'CcmLitePermission';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ChildManufacturer = {
  __typename?: 'ChildManufacturer';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum ConditionEnum {
  DamagedOrForParts = 'DAMAGED_OR_FOR_PARTS',
  New = 'NEW',
  OemCertified = 'OEM_CERTIFIED',
  Refurbished = 'REFURBISHED',
  Used = 'USED'
}

export type ConditionsInput = {
  add: Array<AddInput>;
  remove: Array<RemoveInput>;
};

export type ContactUsInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  message: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type ContractFile = {
  __typename?: 'ContractFile';
  category_organization_vendor_contract_id: Scalars['ID'];
  contract?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  original_name?: Maybe<Scalars['String']>;
};

export type CostCenter = {
  __typename?: 'CostCenter';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  type?: Maybe<InternalRedeploymentType>;
};

export type CostCenterInput = {
  name: Scalars['String'];
};

/** A paginated list of CostCenter items. */
export type CostCenterPaginator = {
  __typename?: 'CostCenterPaginator';
  /** A list of CostCenter items. */
  data: Array<CostCenter>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Country = {
  __typename?: 'Country';
  abbreviation: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateBid = {
  amount: Scalars['Float'];
  lot_id: Scalars['ID'];
};

export type CreateBudgetInput = {
  amount: Scalars['Float'];
  budget_group_id?: InputMaybe<Scalars['ID']>;
  end_date: Scalars['Date'];
  name: Scalars['String'];
  split_type?: InputMaybe<BudgetSplitTypeEnum>;
  start_date: Scalars['Date'];
  status: BudgetStatusEnum;
};

export type CreateUserAddressAddressInput = {
  addr1: Scalars['String'];
  addr2?: InputMaybe<Scalars['String']>;
  attention_to?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country_id: Scalars['ID'];
  postal_code?: InputMaybe<Scalars['String']>;
  state_or_province?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type CreateUserAddressInput = {
  active: Scalars['Boolean'];
  address: CreateUserAddressAddressInput;
  is_primary: Scalars['Boolean'];
  type: UserAddressTypeEnum;
};

export type CustomField = {
  __typename?: 'CustomField';
  fields: Scalars['JSON'];
  id: Scalars['ID'];
};

export type CustomQuestionAnswer = {
  answer_id: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  question_id: Scalars['ID'];
};

export type CustomTable = {
  __typename?: 'CustomTable';
  code: Scalars['String'];
  fields?: Maybe<CustomField>;
  id: Scalars['ID'];
  pivot: Array<CustomTableUserPivot>;
  table_name: Scalars['String'];
};

export type CustomTableUserPivot = {
  __typename?: 'CustomTableUserPivot';
  custom_table_id: Scalars['ID'];
  sort_order?: Maybe<Scalars['JSON']>;
  table: CustomTable;
  user: User;
  user_id: Scalars['ID'];
};

export type Customer = {
  __typename?: 'Customer';
  active: Scalars['Boolean'];
  billing_id?: Maybe<Scalars['ID']>;
  distributor?: Maybe<Scalars['String']>;
  erp?: Maybe<Scalars['String']>;
  gpo?: Maybe<Scalars['String']>;
  hospitals?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  inventory_system?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  msro?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  org?: Maybe<Organization>;
  parent?: Maybe<Customer>;
  seller: Scalars['Boolean'];
  shipping_id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
};

export type DataHeathCheckFilterInput = {
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  disposed?: InputMaybe<Scalars['Boolean']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  label_ids?: InputMaybe<Array<Scalars['ID']>>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  orderBy?: InputMaybe<OrderBy>;
  order_status_ids?: InputMaybe<Array<Scalars['ID']>>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  product_ids?: InputMaybe<Array<Scalars['String']>>;
  replacement_cost_range?: InputMaybe<MaxMinRange>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  show_only_assets_in_budget?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Scalars['String']>;
  tag_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type DateRange = {
  date_from: Scalars['Date'];
  date_to: Scalars['Date'];
};

export type DefaultApprovalWorkflowInput = {
  conditions?: InputMaybe<Array<ApprovalWorkflowConditionInput>>;
  contingency: ApprovalWorkFlowContingencyEnum;
  id?: InputMaybe<Scalars['ID']>;
  rules?: InputMaybe<ApprovalWorkflowRulesInput>;
  types?: InputMaybe<Array<ApprovalWorkFlowTypeEnum>>;
  user_groups?: InputMaybe<Array<ApprovalWorkflowUserGroup>>;
  workflow_name?: InputMaybe<Scalars['String']>;
  workflow_type: WorkFlowTypeEnum;
};

export type DeleteQuestionInput = {
  delete_anyway?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type DetailedFleetItemType = {
  __typename?: 'DetailedFleetItemType';
  age?: Maybe<Scalars['Float']>;
  asimilyDatas?: Maybe<Array<Maybe<AsimilyData>>>;
  asset_tag?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['ID']>;
  budget_name?: Maybe<Scalars['String']>;
  budget_split_type?: Maybe<BudgetSplitTypeEnum>;
  budget_status?: Maybe<BudgetStatusEnum>;
  capital_number?: Maybe<Scalars['String']>;
  ce_number?: Maybe<Scalars['String']>;
  construction_cost?: Maybe<Scalars['Float']>;
  cost_center?: Maybe<Scalars['String']>;
  cost_center_id?: Maybe<Scalars['ID']>;
  custom_score_contributions?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  department_id?: Maybe<Scalars['ID']>;
  device_id?: Maybe<Scalars['String']>;
  disposition_id?: Maybe<Scalars['ID']>;
  facility?: Maybe<Scalars['String']>;
  files: Array<File>;
  id: Scalars['ID'];
  it_cost?: Maybe<Scalars['Float']>;
  labels: Array<GlobalLabel>;
  location?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  orderHubs: Array<OrderHub>;
  order_hub?: Maybe<OrderHub>;
  organization?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['ID']>;
  other_cost?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  purchase_date?: Maybe<Scalars['Date']>;
  purchase_price?: Maybe<Scalars['Float']>;
  quarter?: Maybe<FleetItemQuarterEnum>;
  reason_for_disposition?: Maybe<Scalars['String']>;
  replacement_cost?: Maybe<Scalars['Float']>;
  room?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['String']>;
  service_line?: Maybe<Scalars['String']>;
  service_line_id?: Maybe<Scalars['ID']>;
  tags: Array<Tag>;
};

export type DispositionReason = {
  __typename?: 'DispositionReason';
  id: Scalars['ID'];
  reason: Scalars['String'];
};

/** Disposition reasons */
export enum DispositionReasonEnum {
  /** Marked as Disposed in Feed */
  DisposedInFeed = 'DISPOSED_IN_FEED',
  /** List on marketplace */
  ListOnMarketplace = 'LIST_ON_MARKETPLACE',
  /** No disposition */
  None = 'NONE',
  /** Other */
  Other = 'OTHER',
  /** Recycle */
  Recycle = 'RECYCLE',
  /** Removed from feed */
  RemovedFromFeed = 'REMOVED_FROM_FEED',
  /** Threw in dumpster */
  ThrewInDumpster = 'THREW_IN_DUMPSTER',
  /** Trade in to OEM */
  TradeInToManufacturer = 'TRADE_IN_TO_MANUFACTURER',
  /** Trashed */
  Trashed = 'TRASHED'
}

/** A paginated list of DispositionReason items. */
export type DispositionReasonPaginator = {
  __typename?: 'DispositionReasonPaginator';
  /** A list of DispositionReason items. */
  data: Array<DispositionReason>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Entity such as manufacturers, products and its fleet count */
export type EntityFleetCount = {
  __typename?: 'EntityFleetCount';
  /** Fleet Item count associated with the entity */
  count: Scalars['Int'];
  /** Entity Id */
  id: Scalars['ID'];
  /** Entity name */
  name?: Maybe<Scalars['String']>;
};

export type EntityFleetStats = {
  __typename?: 'EntityFleetStats';
  /** Fleet Item count per stages for Good, Plan and Replace. */
  count?: Maybe<FleetStageCount>;
  /** Entity Id */
  id: Scalars['ID'];
  /** Entity name */
  name?: Maybe<Scalars['String']>;
  /** Fleet Item sum per stages for Good, Plan and Replace. */
  sum?: Maybe<FleetStageSum>;
};

export enum EntityTypeEnum {
  Budget = 'BUDGET',
  RequestHub = 'REQUEST_HUB'
}

export enum ExcludeItemResponseStatusEnum {
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export type ExcludedAsset = {
  __typename?: 'ExcludedAsset';
  age?: Maybe<Scalars['Float']>;
  /** Asset tag or CE number */
  asset_tag?: Maybe<Scalars['String']>;
  /** Budget name of asset where this item has been added */
  budget?: Maybe<Scalars['String']>;
  /** Budget Id of the asset */
  budget_id?: Maybe<Scalars['Int']>;
  /** Idenify if the asset is a capital or non capital item. (OVER_THRESHOLD/UNDER_THRESHOLD) */
  capital_flag?: Maybe<Scalars['String']>;
  capital_number?: Maybe<Scalars['String']>;
  /** CE number */
  ce_number?: Maybe<Scalars['String']>;
  costCenter?: Maybe<Scalars['String']>;
  costCenterName?: Maybe<Scalars['String']>;
  dispositionReason?: Maybe<Scalars['String']>;
  disposition_date?: Maybe<Scalars['Date']>;
  /** Organization of the asset */
  facility?: Maybe<Scalars['String']>;
  /** Fleet item id */
  fleet_item_id?: Maybe<Scalars['ID']>;
  floor?: Maybe<Floor>;
  /** Id of the customer table */
  id?: Maybe<Scalars['ID']>;
  labels?: Maybe<Array<GlobalLabel>>;
  /** Location of the asset */
  location?: Maybe<Scalars['String']>;
  location_room?: Maybe<Scalars['String']>;
  /** Manufacturer name */
  manufacturer?: Maybe<Scalars['String']>;
  /** Identify if the asset is a medical or non medical item. (MEDICAL/NON_MEDICAL) */
  medical_flag?: Maybe<Scalars['String']>;
  /** Product mpn */
  mpn?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  orderHubs?: Maybe<Array<OrderHub>>;
  /** Product description */
  product_description?: Maybe<Scalars['String']>;
  product_name?: Maybe<Scalars['String']>;
  /** Purchase date */
  purchase_date?: Maybe<Scalars['String']>;
  /** Purchase price */
  purchase_price?: Maybe<Scalars['Float']>;
  replacement_cost?: Maybe<Scalars['Float']>;
  /** Score of the item */
  score?: Maybe<Scalars['Int']>;
  /** Serial number */
  serial_number?: Maybe<Scalars['String']>;
  serviceLine?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Tag>>;
  useful_life?: Maybe<Scalars['Float']>;
};

/** A paginated list of ExcludedAsset items. */
export type ExcludedAssetPaginator = {
  __typename?: 'ExcludedAssetPaginator';
  /** A list of ExcludedAsset items. */
  data: Array<ExcludedAsset>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ExcludedProductInfoType = {
  __typename?: 'ExcludedProductInfoType';
  excluded_product_id: Scalars['ID'];
  mpn?: Maybe<Scalars['String']>;
  product_description?: Maybe<Scalars['String']>;
  product_details?: Maybe<Scalars['String']>;
};

/** A paginated list of ExcludedProductInfoType items. */
export type ExcludedProductInfoTypePaginator = {
  __typename?: 'ExcludedProductInfoTypePaginator';
  /** A list of ExcludedProductInfoType items. */
  data: Array<ExcludedProductInfoType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FacilityInput = {
  name: Scalars['String'];
  region_id?: InputMaybe<Scalars['ID']>;
};

/** Following enum representing full list of available features in the system. */
export enum FeaturesEnum {
  /** Hide split by categories from budget logic */
  CapitalCalendarAllowSplitByCategories = 'CAPITAL_CALENDAR_ALLOW_SPLIT_BY_CATEGORIES',
  /** Hide split by facility from budget logic */
  CapitalCalendarAllowSplitByFacility = 'CAPITAL_CALENDAR_ALLOW_SPLIT_BY_FACILITY',
  /** Hide split by service line from budget logic */
  CapitalCalendarAllowSplitByServiceLine = 'CAPITAL_CALENDAR_ALLOW_SPLIT_BY_SERVICE_LINE',
  /** Hide Asimily data in the fleet. */
  FleetAllowAsimily = 'FLEET_ALLOW_ASIMILY'
}

export type Fee = {
  __typename?: 'Fee';
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  orders?: Maybe<Array<Maybe<Order>>>;
  type?: Maybe<FeeType>;
};

/** A paginated list of Fee items. */
export type FeePaginator = {
  __typename?: 'FeePaginator';
  /** A list of Fee items. */
  data: Array<Fee>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FeeType = {
  __typename?: 'FeeType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  created_at: Scalars['DateTime'];
  file_name: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['DateTime'];
  url: Scalars['String'];
  user: User;
};

/** Resolves the filters to be applied from the filter driver */
export enum FilterTypeEnum {
  /** Determines the amount_limit filter */
  AmountLimit = 'AMOUNT_LIMIT',
  /** Determines the score_above filter */
  ScoreAbove = 'SCORE_ABOVE',
  /** Determines the score_below filter */
  ScoreBelow = 'SCORE_BELOW'
}

export enum FilterUsageEnum {
  ApprovalWorkflow = 'APPROVAL_WORKFLOW',
  Fleet = 'FLEET',
  None = 'NONE',
  OrderAssemblyAssets = 'ORDER_ASSEMBLY_ASSETS',
  OrderAssemblyRequests = 'ORDER_ASSEMBLY_REQUESTS',
  OrderHub = 'ORDER_HUB',
  RequestHub = 'REQUEST_HUB'
}

export type FiltersInput = {
  type?: InputMaybe<FilterTypeEnum>;
  values?: InputMaybe<Array<Scalars['ID']>>;
};

export type FleetAudit = {
  __typename?: 'FleetAudit';
  audit_id_value: Scalars['ID'];
  fleetItem?: Maybe<FleetItem>;
  fleet_item_found: Scalars['Boolean'];
  floor?: Maybe<Floor>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  location_room?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  organization: Organization;
  user: User;
};

/** Fleet bar graph data point */
export type FleetBarGraphDataPoint = {
  __typename?: 'FleetBarGraphDataPoint';
  /** Fleet Item count per stages for Good, Plan and Replace. */
  count?: Maybe<FleetStageCount>;
  /** Category has children true / false */
  has_children?: Maybe<Scalars['Boolean']>;
  /** Category Id */
  id: Scalars['Int'];
  /** Name of the category */
  name?: Maybe<Scalars['String']>;
  /** Fleet Item sum per stages for Good, Plan and Replace. */
  sum?: Maybe<FleetStageSum>;
};

/** Filters for fleet */
export type FleetFilterInput = {
  /** budget_id */
  budget_id?: InputMaybe<Scalars['ID']>;
  /** filter by several budgets */
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Category Ids. */
  category_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Cost center Ids. */
  cost_center_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Department Ids. */
  department_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  disposed_date_range?: InputMaybe<DateRange>;
  disposition_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  facility_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Fleet types eg Medical/Equipment */
  fleet_type?: InputMaybe<FleetTypeEnum>;
  forecast_years?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  label_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Limit number of records, By default records retrieved will be 50 */
  limit?: InputMaybe<Scalars['Int']>;
  /** Location Ids */
  location_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Manufacturer Ids */
  manufacturer_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  not_in_budget?: InputMaybe<Scalars['Boolean']>;
  not_in_replaced?: InputMaybe<Scalars['Boolean']>;
  order_status_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Organization Ids */
  organization_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Parent category Id to be passed */
  parent_category_id?: InputMaybe<Scalars['ID']>;
  /** Product Ids */
  product_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  purchase_date_range?: InputMaybe<DateRange>;
  /** Region Ids */
  region_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  replacement_cost_range?: InputMaybe<MaxMinRange>;
  request_hub_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show data with score */
  score?: InputMaybe<Scalars['Boolean']>;
  /** Searching the fleet items by mpn, product_description, manufacturer, serial_number, ce_number, asset_tag */
  search?: InputMaybe<Scalars['String']>;
  /** Service line Ids */
  service_line_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show data with stage of life */
  stage_of_life?: InputMaybe<Array<InputMaybe<StageOfLifeEnum>>>;
  tag_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Include fleet items that were present in budgets with status "Completed" */
  with_completed_budget_items?: InputMaybe<Scalars['Boolean']>;
};

export type FleetItem = {
  __typename?: 'FleetItem';
  asset_tag?: Maybe<Scalars['String']>;
  audits?: Maybe<Array<Maybe<FleetAudit>>>;
  barcode?: Maybe<Scalars['String']>;
  bed_pump_ratio?: Maybe<Scalars['Float']>;
  budget?: Maybe<CapitalCalendarBudget>;
  capital_number?: Maybe<Scalars['String']>;
  case_load?: Maybe<Scalars['Float']>;
  case_mix?: Maybe<Scalars['Float']>;
  ce_number?: Maybe<Scalars['String']>;
  construction_cost: Scalars['Float'];
  cosmetic_rating?: Maybe<Scalars['Boolean']>;
  costCenter?: Maybe<CostCenter>;
  cycle_count?: Maybe<Scalars['Float']>;
  dept_rate?: Maybe<Scalars['Float']>;
  disposition_comments?: Maybe<Scalars['String']>;
  fleet_item_movement?: Maybe<FleetItemsMovement>;
  fleet_utilization?: Maybe<FleetUtilization>;
  floor?: Maybe<Floor>;
  gi_case_rate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  it_cost: Scalars['Float'];
  item?: Maybe<Item>;
  location?: Maybe<Location>;
  locationDept?: Maybe<LocationDept>;
  location_of_disposition?: Maybe<Scalars['String']>;
  location_room?: Maybe<Scalars['String']>;
  meter_rate?: Maybe<Scalars['Float']>;
  movements?: Maybe<Array<Maybe<FleetItemsMovement>>>;
  notes?: Maybe<Scalars['String']>;
  occupancy_rate?: Maybe<Scalars['Float']>;
  orderHubs: Array<OrderHub>;
  organization: Organization;
  other_cost: Scalars['Float'];
  patient_safety_rating?: Maybe<Scalars['Boolean']>;
  procedure_time_rate?: Maybe<Scalars['Float']>;
  product: Product;
  purchase_price?: Maybe<Scalars['Float']>;
  qtytype_qty?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  quantityType?: Maybe<QuantityType>;
  quarter?: Maybe<Scalars['String']>;
  reason_for_disposition_id?: Maybe<Scalars['ID']>;
  replacement_cost?: Maybe<Scalars['Float']>;
  score: Scalars['Int'];
  serial_number?: Maybe<Scalars['String']>;
  serviceLine?: Maybe<ServiceLine>;
  service_agreement?: Maybe<Scalars['Int']>;
  standardization_variance?: Maybe<Scalars['Boolean']>;
  tags: Array<Tag>;
  top_category_name?: Maybe<Scalars['String']>;
  transfer_rate?: Maybe<Scalars['Float']>;
  transfers?: Maybe<Array<Maybe<FleetItemTransfer>>>;
  user: User;
};

export type FleetItemDetailRow = {
  __typename?: 'FleetItemDetailRow';
  age?: Maybe<Scalars['Float']>;
  asset_tag?: Maybe<Scalars['String']>;
  budget_name?: Maybe<Scalars['String']>;
  capital_number?: Maybe<Scalars['String']>;
  ce_number?: Maybe<Scalars['String']>;
  costCenter?: Maybe<CostCenter>;
  dispositionReason?: Maybe<DispositionReason>;
  disposition_date?: Maybe<Scalars['Date']>;
  floor?: Maybe<Floor>;
  id: Scalars['ID'];
  labels: Array<GlobalLabel>;
  location?: Maybe<Scalars['String']>;
  location_room?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  mpn?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  orderHubs: Array<OrderHub>;
  organization?: Maybe<Scalars['String']>;
  product_name?: Maybe<Scalars['String']>;
  purchase_date?: Maybe<Scalars['Date']>;
  purchase_price?: Maybe<Scalars['Float']>;
  replacement_cost?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['String']>;
  serviceLine?: Maybe<ServiceLine>;
  stage?: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  useful_life?: Maybe<Scalars['Float']>;
};

/** A paginated list of FleetItemDetailRow items. */
export type FleetItemDetailRowPaginator = {
  __typename?: 'FleetItemDetailRowPaginator';
  /** A list of FleetItemDetailRow items. */
  data: Array<FleetItemDetailRow>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FleetItemDetailsInput = {
  construction_cost?: InputMaybe<Scalars['Float']>;
  cost_center_id?: InputMaybe<Scalars['ID']>;
  department_id?: InputMaybe<Scalars['ID']>;
  files?: InputMaybe<UpdateRelatedItemsInput>;
  it_cost?: InputMaybe<Scalars['Float']>;
  location_id?: InputMaybe<Scalars['ID']>;
  notes?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['ID']>;
  other_cost?: InputMaybe<Scalars['Float']>;
  replacement_cost?: InputMaybe<Scalars['Float']>;
  service_line_id?: InputMaybe<Scalars['ID']>;
};

export type FleetItemDispositionDetailRow = {
  __typename?: 'FleetItemDispositionDetailRow';
  age?: Maybe<Scalars['Float']>;
  asset_tag?: Maybe<Scalars['String']>;
  budget_name?: Maybe<Scalars['String']>;
  capital_number?: Maybe<Scalars['String']>;
  ce_number?: Maybe<Scalars['String']>;
  costCenter?: Maybe<CostCenter>;
  date_in?: Maybe<Scalars['String']>;
  dispositionReason?: Maybe<DispositionReason>;
  disposition_date?: Maybe<Scalars['String']>;
  disposition_id?: Maybe<Scalars['ID']>;
  disposition_method?: Maybe<DispositionReasonEnum>;
  floor?: Maybe<Floor>;
  id: Scalars['ID'];
  labels: Array<GlobalLabel>;
  location?: Maybe<Scalars['String']>;
  location_room?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  mpn?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  orderHubs: Array<OrderHub>;
  organization?: Maybe<Organization>;
  product_name?: Maybe<Scalars['String']>;
  purchase_date?: Maybe<Scalars['Date']>;
  purchase_price?: Maybe<Scalars['Float']>;
  quarter?: Maybe<FleetItemQuarterEnum>;
  replacement_cost?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['String']>;
  serviceLine?: Maybe<ServiceLine>;
  stage?: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  useful_life?: Maybe<Scalars['Float']>;
};

/** A paginated list of FleetItemDispositionDetailRow items. */
export type FleetItemDispositionDetailRowPaginator = {
  __typename?: 'FleetItemDispositionDetailRowPaginator';
  /** A list of FleetItemDispositionDetailRow items. */
  data: Array<FleetItemDispositionDetailRow>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Creating fleet item */
export type FleetItemInput = {
  /** Asset tag of fleet item */
  asset_tag?: InputMaybe<Scalars['String']>;
  /** Capital number of fleet item */
  capital_number?: InputMaybe<Scalars['String']>;
  /** Ce Number of fleet item */
  ce_number?: InputMaybe<Scalars['String']>;
  /** Cost center Id of fleet item */
  cost_center_id?: InputMaybe<Scalars['ID']>;
  /** Fleet item Id */
  fleet_item_id?: InputMaybe<Scalars['ID']>;
  /** Department Id of fleet item */
  location_dept_id?: InputMaybe<Scalars['Int']>;
  /** Location Id of fleet item */
  location_id: Scalars['ID'];
  /** Room number of fleet item */
  location_room?: InputMaybe<Scalars['String']>;
  /** Facility / Organization Id of fleet item */
  organization_id: Scalars['ID'];
  /** Product Id of fleet item */
  product_id: Scalars['ID'];
  /** Purchase date of fleet item */
  purchase_date: Scalars['Date'];
  /** Purchase price of fleet item */
  purchase_price: Scalars['Float'];
  /** Replacement cost of fleet item */
  replacement_cost?: InputMaybe<Scalars['Float']>;
  /** Serial number of fleet item */
  serial_number?: InputMaybe<Scalars['String']>;
  /** Service line Id of fleet item */
  service_line_id?: InputMaybe<Scalars['ID']>;
};

/** A paginated list of FleetItem items. */
export type FleetItemPaginator = {
  __typename?: 'FleetItemPaginator';
  /** A list of FleetItem items. */
  data: Array<FleetItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum FleetItemQuarterEnum {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export type FleetItemTransfer = {
  __typename?: 'FleetItemTransfer';
  destination: Location;
  fleetItem: FleetItem;
  id: Scalars['ID'];
  location: Location;
  organization: Organization;
  origination: Location;
};

export type FleetItemsDispositionStatsRow = {
  __typename?: 'FleetItemsDispositionStatsRow';
  disposition?: Maybe<DispositionReasonEnum>;
  disposition_id?: Maybe<Scalars['ID']>;
  disposition_items_count?: Maybe<Scalars['Int']>;
  disposition_items_sum?: Maybe<Scalars['Float']>;
  disposition_reason?: Maybe<Scalars['String']>;
};

export type FleetItemsMovement = {
  __typename?: 'FleetItemsMovement';
  contact?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  fleetItem: FleetItem;
  id: Scalars['ID'];
  movementCost?: Maybe<FleetMovementCost>;
  notes?: Maybe<Scalars['String']>;
  replacement_notes?: Maybe<Scalars['String']>;
  shipper?: Maybe<Organization>;
  shipping_number?: Maybe<Scalars['String']>;
  triageType?: Maybe<TriageType>;
  type: FleetItemsMovement;
  user: User;
  vendor?: Maybe<Organization>;
};

export enum FleetItemsOrderByColumnEnum {
  Age = 'AGE',
  AssetTag = 'ASSET_TAG',
  BudgetName = 'BUDGET_NAME',
  CapitalNumber = 'CAPITAL_NUMBER',
  CeNumber = 'CE_NUMBER',
  CostCenterId = 'COST_CENTER_ID',
  DateIn = 'DATE_IN',
  DispositionDate = 'DISPOSITION_DATE',
  FloorId = 'FLOOR_ID',
  Location = 'LOCATION',
  LocationRoom = 'LOCATION_ROOM',
  Manufacturer = 'MANUFACTURER',
  Organization = 'ORGANIZATION',
  ProductName = 'PRODUCT_NAME',
  PurchaseDate = 'PURCHASE_DATE',
  PurchasePrice = 'PURCHASE_PRICE',
  Reason = 'REASON',
  ReplacementCost = 'REPLACEMENT_COST',
  Score = 'SCORE',
  SerialNumber = 'SERIAL_NUMBER',
  ServiceLineId = 'SERVICE_LINE_ID',
  Tags = 'TAGS',
  UsefulLife = 'USEFUL_LIFE'
}

export type FleetItemsOrderByInput = {
  column: FleetItemsOrderByColumnEnum;
  order?: InputMaybe<SortOrder>;
};

/** Fleet analysis line graph data */
export type FleetLineGraph = {
  __typename?: 'FleetLineGraph';
  /** Count of fleet items in Replace stage. */
  end_of_life_count: Scalars['Int'];
  /** Fleet Item count per score / age. */
  graph: Array<FleetLineGraphDataPoint>;
  /** Life span of the category */
  lifespan: Scalars['Int'];
  /** Total count of fleet items */
  total_count: Scalars['Int'];
};

/** Fleet line graph data point */
export type FleetLineGraphDataPoint = {
  __typename?: 'FleetLineGraphDataPoint';
  /** Count of fleet items per age / score */
  count: Scalars['Int'];
  /** Age / Score */
  parameter: Scalars['Int'];
};

export type FleetManufacturerInput = {
  parent_manufacturer_id: Scalars['ID'];
  selected_manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
};

/** Fleet map location */
export type FleetMapLocation = {
  __typename?: 'FleetMapLocation';
  /** City of location */
  city: Scalars['String'];
  /** Latitude of location */
  latitude: Scalars['String'];
  /** Location Id */
  location_id: Scalars['Int'];
  /** Longitude of location */
  longitude: Scalars['String'];
  /** State of location */
  state: Scalars['String'];
  /** Total assets */
  total_assets: Scalars['Int'];
  /** Sum of replacement cost */
  total_requested?: Maybe<Scalars['Float']>;
  /** Sum of purchase price */
  total_value?: Maybe<Scalars['Float']>;
  /** Zip code */
  zip_code: Scalars['String'];
};

export type FleetMovementCost = {
  __typename?: 'FleetMovementCost';
  category?: Maybe<Category>;
  cost: Scalars['Float'];
  id: Scalars['ID'];
  movementType: FleetMovementType;
  organization: Organization;
};

export type FleetMovementType = {
  __typename?: 'FleetMovementType';
  comment?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  movement_order: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  organization: Organization;
  title?: Maybe<Scalars['String']>;
};

/** Fleet item count for stages Good, Plan and Replace */
export type FleetStageCount = {
  __typename?: 'FleetStageCount';
  /** Fleet Item count for Good stage. */
  good: Scalars['Int'];
  /** Fleet Item count for Plan stage. */
  plan: Scalars['Int'];
  /** Fleet Item count for Replace stage. */
  replace: Scalars['Int'];
};

/** Fleet item sum for  stages Good, Plan and Replace */
export type FleetStageSum = {
  __typename?: 'FleetStageSum';
  /** Sum of fleet Item replacement cost for Good stage. */
  good: Scalars['Float'];
  /** Sum of fleet Item replacement cost for Plan stage. */
  plan: Scalars['Float'];
  /** Sum of fleet Item replacement cost for Replace stage. */
  replace: Scalars['Float'];
};

export type FleetTagInput = {
  fleet_item: Scalars['String'];
  tags: Array<Scalars['ID']>;
};

export type FleetType = {
  __typename?: 'FleetType';
  categories?: Maybe<Array<Maybe<Category>>>;
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum FleetTypeEnum {
  Infrastructure = 'INFRASTRUCTURE',
  Itequipment = 'ITEQUIPMENT',
  Medical = 'MEDICAL',
  NonFleet = 'NON_FLEET'
}

export type FleetTypeOptionType = {
  __typename?: 'FleetTypeOptionType';
  key?: Maybe<FleetTypeEnum>;
  title: Scalars['String'];
};

/** A paginated list of FleetTypeOptionType items. */
export type FleetTypeOptionTypePaginator = {
  __typename?: 'FleetTypeOptionTypePaginator';
  /** A list of FleetTypeOptionType items. */
  data: Array<FleetTypeOptionType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FleetUtilization = {
  __typename?: 'FleetUtilization';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
};

export type Floor = {
  __typename?: 'Floor';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  location: Location;
  name?: Maybe<Scalars['String']>;
};

export type ForecastBarGraph = {
  __typename?: 'ForecastBarGraph';
  avgScore: Scalars['Float'];
  data?: Maybe<Array<FleetBarGraphDataPoint>>;
  year: Scalars['Int'];
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
  referer: ApplicationRefererEnum;
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type GeneralDataHealth = {
  __typename?: 'GeneralDataHealth';
  /** Count of all assets that are present in customer tables. */
  all_assets: Scalars['Int'];
  /** Count of assets in budget with issues. */
  assets_count_with_issues_in_budget: Scalars['Int'];
  /** Sum of assets cost in budget with issues. */
  assets_sum_with_issues_in_budget: Scalars['Float'];
  /** Count of all assets present in customer table that are missing either purchase date or purchase price or both. */
  missing_data_all_assets: Scalars['Int'];
  /** Count of assets that are not yet onboarded. */
  non_boarded_assets: Scalars['Int'];
  /** Count of active onboarded assets. */
  onboarded_active_assets: Scalars['Int'];
  /** Count of assets that are onboarded. */
  onboarded_assets: Scalars['Int'];
};

export type GlobalLabel = {
  __typename?: 'GlobalLabel';
  code: Scalars['String'];
  display_color: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A paginated list of GlobalLabel items. */
export type GlobalLabelPaginator = {
  __typename?: 'GlobalLabelPaginator';
  /** A list of GlobalLabel items. */
  data: Array<GlobalLabel>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type GoogleChorus = {
  __typename?: 'GoogleChorus';
  device_id: Scalars['String'];
  id: Scalars['ID'];
};

export type GoogleChorusDetails = {
  __typename?: 'GoogleChorusDetails';
  battery?: Maybe<Scalars['Int']>;
  device_id: Scalars['String'];
  error_code?: Maybe<Scalars['Int']>;
  error_message?: Maybe<Scalars['String']>;
  facility: Scalars['String'];
  last_check_in?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Int']>;
  longitude?: Maybe<Scalars['Int']>;
};

export type GoogleChorusFleetItem = {
  __typename?: 'GoogleChorusFleetItem';
  fleetItem?: Maybe<FleetItem>;
  fleet_item_id: Scalars['ID'];
  googleChorus: GoogleChorus;
  google_chorus_id: Scalars['ID'];
  id: Scalars['ID'];
};

export type GoogleChorusInput = {
  device_id: Scalars['String'];
  end_date?: InputMaybe<Scalars['Date']>;
  fleet_item_id: Scalars['ID'];
  start_date?: InputMaybe<Scalars['Date']>;
};

export type InternalRedeployment = {
  __typename?: 'InternalRedeployment';
  id: Scalars['ID'];
  item?: Maybe<Item>;
  reviewer?: Maybe<User>;
  reviewer_comments?: Maybe<Scalars['String']>;
  status: InternalRedeploymentStatusEnum;
  type?: Maybe<InternalRedeploymentType>;
};

export enum InternalRedeploymentStatusEnum {
  Approved = 'APPROVED',
  Available = 'AVAILABLE',
  Denied = 'DENIED',
  Finalized = 'FINALIZED',
  Inactive = 'INACTIVE',
  Requested = 'REQUESTED'
}

export type InternalRedeploymentType = {
  __typename?: 'InternalRedeploymentType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

export type Item = {
  __typename?: 'Item';
  active: Scalars['Boolean'];
  approved_by?: Maybe<Scalars['String']>;
  asset_tag?: Maybe<Scalars['String']>;
  auctionLot?: Maybe<AuctionLot>;
  batch_id?: Maybe<Scalars['ID']>;
  canonical_url?: Maybe<Scalars['String']>;
  capital_number?: Maybe<Scalars['String']>;
  ce_number?: Maybe<Scalars['String']>;
  condition: ConditionEnum;
  costCenter?: Maybe<CostCenter>;
  created_at?: Maybe<Scalars['DateTime']>;
  crm_user_id?: Maybe<Scalars['ID']>;
  customer: Customer;
  documents?: Maybe<Array<Maybe<ItemDocument>>>;
  fda_seller_approved: Scalars['Boolean'];
  floor_price?: Maybe<Scalars['Float']>;
  freight?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<ItemImage>>>;
  location?: Maybe<Location>;
  location_historical?: Maybe<Scalars['String']>;
  lot?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  on_hand?: Maybe<Scalars['Boolean']>;
  product?: Maybe<Product>;
  project_id?: Maybe<Scalars['ID']>;
  qtytype_qty: Scalars['Int'];
  quantity: Scalars['Int'];
  quantityType: QuantityType;
  rx_only?: Maybe<Scalars['Boolean']>;
  sell_price?: Maybe<Scalars['Float']>;
  seller?: Maybe<User>;
  source?: Maybe<Scalars['String']>;
  status: ItemStatusEnum;
  unlimited_quantity: Scalars['Boolean'];
  user: User;
  var_pricing?: Maybe<Scalars['Boolean']>;
  vendor_item_id?: Maybe<Scalars['ID']>;
  warranty?: Maybe<Scalars['String']>;
};

export type ItemDocument = {
  __typename?: 'ItemDocument';
  id: Scalars['ID'];
  item?: Maybe<Item>;
  name: Scalars['String'];
  path: Scalars['String'];
};

export type ItemDocumentsRequest = {
  document: Scalars['String'];
  name: Scalars['String'];
};

export type ItemFilters = {
  active?: InputMaybe<Scalars['Boolean']>;
  conditions?: InputMaybe<Array<ConditionEnum>>;
  created_from?: InputMaybe<Scalars['Date']>;
  created_to?: InputMaybe<Scalars['Date']>;
  hasCategories?: InputMaybe<Array<Scalars['Int']>>;
  hasManufacturers?: InputMaybe<Array<Scalars['Int']>>;
  inStock?: InputMaybe<Scalars['Boolean']>;
  sellerId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  status?: InputMaybe<Array<ItemStatusEnum>>;
  title?: InputMaybe<Scalars['String']>;
  withImages?: InputMaybe<Scalars['Boolean']>;
};

export type ItemImage = {
  __typename?: 'ItemImage';
  id: Scalars['ID'];
  item: Item;
  name: Scalars['String'];
  path: Scalars['String'];
};

export type ItemImagesRequest = {
  image: Scalars['String'];
  name: Scalars['String'];
};

/** A paginated list of Item items. */
export type ItemPaginator = {
  __typename?: 'ItemPaginator';
  /** A list of Item items. */
  data: Array<Item>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ItemQuantityRequest = {
  qty_per_unit_of_measure: Scalars['Int'];
  quantity: Scalars['Int'];
  unit_of_measure: Scalars['ID'];
  unlimited_quantity: Scalars['Boolean'];
};

export type ItemRequest = {
  condition: ConditionEnum;
  documents?: InputMaybe<Array<InputMaybe<ItemDocumentsRequest>>>;
  expiration_data?: InputMaybe<Scalars['Date']>;
  freight_shipping?: InputMaybe<Scalars['Boolean']>;
  images?: InputMaybe<Array<InputMaybe<ItemImagesRequest>>>;
  lot_number?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  product_id: Scalars['ID'];
  quantity: ItemQuantityRequest;
  rx_only: Scalars['Boolean'];
  sell_price?: InputMaybe<Scalars['Float']>;
  warranty?: InputMaybe<Scalars['String']>;
};

export type ItemSoldDate = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
};

export enum ItemStatusEnum {
  Affiliate = 'AFFILIATE',
  Global = 'GLOBAL',
  InternalRedeployment = 'INTERNAL_REDEPLOYMENT'
}

export enum ItemsOrderByColumnEnum {
  Condition = 'CONDITION',
  Manufacturer = 'MANUFACTURER',
  ProductDescription = 'PRODUCT_DESCRIPTION',
  Quantity = 'QUANTITY',
  SellPrice = 'SELL_PRICE'
}

export type ItemsOrderByInput = {
  column: ItemsOrderByColumnEnum;
  order?: InputMaybe<OrderByDirectionEnum>;
};

export type JobFunction = {
  __typename?: 'JobFunction';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A paginated list of JobFunction items. */
export type JobFunctionPaginator = {
  __typename?: 'JobFunctionPaginator';
  /** A list of JobFunction items. */
  data: Array<JobFunction>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Legend = {
  __typename?: 'Legend';
  count: Scalars['Float'];
  my_oem?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type LifespanType = {
  __typename?: 'LifespanType';
  aha_lifespan?: Maybe<Scalars['Int']>;
  asset_count: Scalars['Int'];
  ecri_lifespan?: Maybe<Scalars['Int']>;
  has_children: Scalars['Boolean'];
  id: Scalars['ID'];
  lifespan?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  parent_category_id?: Maybe<Scalars['ID']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Address>;
  id: Scalars['ID'];
  irType?: Maybe<InternalRedeploymentType>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationStation?: Maybe<OrganizationStation>;
  region?: Maybe<Region>;
};

export type LocationDept = {
  __typename?: 'LocationDept';
  id: Scalars['ID'];
  location: Location;
  location_id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LocationDeptInput = {
  location_id: Scalars['ID'];
  name: Scalars['String'];
};

/** A paginated list of LocationDept items. */
export type LocationDeptPaginator = {
  __typename?: 'LocationDeptPaginator';
  /** A list of LocationDept items. */
  data: Array<LocationDept>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type LocationManagementInput = {
  addr1: Scalars['String'];
  addr2?: InputMaybe<Scalars['String']>;
  attention_to?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  name: Scalars['String'];
  organization_id: Scalars['ID'];
  state_or_province?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['Int']>;
};

export type LocationManagementType = {
  __typename?: 'LocationManagementType';
  addr1?: Maybe<Scalars['String']>;
  addr2?: Maybe<Scalars['String']>;
  attention_to?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  organization_id: Scalars['ID'];
  state?: Maybe<State>;
  state_or_province?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['Int']>;
};

/** A paginated list of LocationManagementType items. */
export type LocationManagementTypePaginator = {
  __typename?: 'LocationManagementTypePaginator';
  /** A list of LocationManagementType items. */
  data: Array<LocationManagementType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of Location items. */
export type LocationPaginator = {
  __typename?: 'LocationPaginator';
  /** A list of Location items. */
  data: Array<Location>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type LoginInput = {
  password: Scalars['String'];
  referer: ApplicationRefererEnum;
  username: Scalars['String'];
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type Manufacturer = {
  __typename?: 'Manufacturer';
  children?: Maybe<Array<Maybe<Manufacturer>>>;
  company_information: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  logo: Scalars['String'];
  name: Scalars['String'];
  oemManufacturers?: Maybe<OemManufacturer>;
  parent?: Maybe<Manufacturer>;
  products?: Maybe<Array<Maybe<Product>>>;
  revenues?: Maybe<Array<Maybe<ManufacturerRevenue>>>;
  synonyms?: Maybe<Array<Maybe<ManufacturerSynonym>>>;
};

/** Manufacturers and its fleet count */
export type ManufacturerFleetCount = {
  __typename?: 'ManufacturerFleetCount';
  deselected_good?: Maybe<Scalars['Int']>;
  deselected_plan?: Maybe<Scalars['Int']>;
  deselected_replace?: Maybe<Scalars['Int']>;
  selected_good?: Maybe<Scalars['Int']>;
  selected_plan?: Maybe<Scalars['Int']>;
  selected_replace?: Maybe<Scalars['Int']>;
  subsidiary_name?: Maybe<Scalars['String']>;
  total_assets_count?: Maybe<Scalars['Int']>;
  total_replacement_cost?: Maybe<Scalars['Float']>;
};

export type ManufacturerOptionType = {
  __typename?: 'ManufacturerOptionType';
  childrens: Array<ManufacturerOptionType>;
  key: Scalars['ID'];
  title: Scalars['String'];
};

/** A paginated list of ManufacturerOptionType items. */
export type ManufacturerOptionTypePaginator = {
  __typename?: 'ManufacturerOptionTypePaginator';
  /** A list of ManufacturerOptionType items. */
  data: Array<ManufacturerOptionType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of Manufacturer items. */
export type ManufacturerPaginator = {
  __typename?: 'ManufacturerPaginator';
  /** A list of Manufacturer items. */
  data: Array<Manufacturer>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ManufacturerRevenue = {
  __typename?: 'ManufacturerRevenue';
  id: Scalars['ID'];
  manufacturer: Manufacturer;
  revenue_type: RevenueType;
  value: Scalars['Int'];
  year: Scalars['Int'];
};

export type ManufacturerSynonym = {
  __typename?: 'ManufacturerSynonym';
  id: Scalars['ID'];
  manufacturer: Manufacturer;
  name: Scalars['String'];
};

export type ManufacturersCount = {
  __typename?: 'ManufacturersCount';
  asset_age?: Maybe<Scalars['Int']>;
  manufacturer_asset_count?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ManufacturersFilter = {
  category_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  cost_center_id?: InputMaybe<Scalars['ID']>;
  department_id?: InputMaybe<Scalars['ID']>;
  facility_id?: InputMaybe<Scalars['ID']>;
  fleet_type_id?: InputMaybe<Scalars['ID']>;
  location_id?: InputMaybe<Scalars['ID']>;
  manufacturer_id?: InputMaybe<Scalars['ID']>;
  mpn?: InputMaybe<Scalars['String']>;
  region_id?: InputMaybe<Scalars['ID']>;
  service_line_id?: InputMaybe<Scalars['ID']>;
};

export type Marketplace = {
  __typename?: 'Marketplace';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MasqueradeLoginInput = {
  masquerade_key: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MasqueradeLoginResponse = {
  __typename?: 'MasqueradeLoginResponse';
  firstLogin: Scalars['Boolean'];
  graphql_access_token: Scalars['String'];
  user: User;
  user_permissions: UserPermissionsPayload;
};

export type MaxMinRange = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type MemberApproval = {
  __typename?: 'MemberApproval';
  member_sort_order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type MemberApprovalGroup = {
  __typename?: 'MemberApprovalGroup';
  field_id?: Maybe<Scalars['ID']>;
  field_name?: Maybe<Scalars['String']>;
  field_type?: Maybe<ApprovalWorkflowFieldTypeEnum>;
  group_id?: Maybe<Scalars['ID']>;
  group_name?: Maybe<Scalars['String']>;
  group_sort_order?: Maybe<Scalars['Int']>;
  members?: Maybe<Array<Maybe<MemberApproval>>>;
  review_status?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<ApprovalGroupTypeEnum>;
};

export type ModelBudget = {
  __typename?: 'ModelBudget';
  budgetGroup: BudgetGroup;
  budget_group_id?: Maybe<Scalars['ID']>;
  budget_name: Scalars['String'];
  budget_value: Scalars['Float'];
  createdBy: User;
  currentVersion?: Maybe<BudgetVersion>;
  editedBy?: Maybe<User>;
  end_date: Scalars['Date'];
  fit_to_budget: Scalars['Boolean'];
  fleetCount: Scalars['Int'];
  fleetItemsCount: Scalars['Int'];
  id: Scalars['ID'];
  newContingencyCost: Scalars['Float'];
  new_capital_cost: Scalars['Float'];
  new_capital_spending_cost: Scalars['Float'];
  new_spending_paid_cost: Scalars['Float'];
  organization: Organization;
  planned_spend: Scalars['Float'];
  quarter_range?: Maybe<QuarterRangeType>;
  redItemsCount: Scalars['Int'];
  red_amount: Scalars['Float'];
  red_count: Scalars['Int'];
  replacementContingencyCost: Scalars['Float'];
  replacement_cost: Scalars['Float'];
  replacement_request_cost: Scalars['Float'];
  replacement_request_count: Scalars['Int'];
  replacement_request_equipment_cost: Scalars['Float'];
  replacement_request_spending_cost: Scalars['Float'];
  replacement_spending_paid_cost: Scalars['Float'];
  requestHubsCount: Scalars['Int'];
  request_product_count: Scalars['Int'];
  request_replacement_count: Scalars['Int'];
  settings?: Maybe<Array<Maybe<BudgetSetting>>>;
  split_type?: Maybe<BudgetSplitTypeEnum>;
  start_date: Scalars['Date'];
  status: BudgetStatusEnum;
  version_id?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<Maybe<BudgetVersion>>>;
  yellow_count: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateBudgetGroup?: Maybe<Scalars['Boolean']>;
  addOrRemoveAutomaticLabel?: Maybe<Scalars['String']>;
  addOrderHubComment?: Maybe<OrderHubComment>;
  addRequestHubComment?: Maybe<OrderHubComment>;
  auctionLotAskQuestion?: Maybe<Scalars['Boolean']>;
  bulkTagUpdate?: Maybe<Scalars['String']>;
  cacheFilters: TokenResponseType;
  cartAddItem?: Maybe<Cart>;
  cartAddOrUpdateItem?: Maybe<Cart>;
  cartClear?: Maybe<Cart>;
  cartDeleteItem?: Maybe<CartItem>;
  cartUpdateItem?: Maybe<CartItem>;
  clearSubscription: Scalars['Boolean'];
  contactUs?: Maybe<Scalars['Boolean']>;
  costCenterCreate: CostCenter;
  costCenterUpdate: CostCenter;
  createBid?: Maybe<Bid>;
  createBudget: BudgetType;
  createBudgetGroup: BudgetGroup;
  createBudgetSetting: BudgetSetting;
  /** Create default workflow */
  createDefaultWorkflow: ApprovalWorkflow;
  createItem?: Maybe<Item>;
  createOrderHub: OrderHub;
  createOrderHubStatus: OrderHubStatus;
  /** Create question */
  createQuestion: Question;
  createRequestHub: RequestHub;
  createUserAddress: UserAddress;
  /** Create new workflow */
  createWorkflow: ApprovalWorkflow;
  /** Create new workflow group */
  createWorkflowGroup: ApprovalWorkflowGroup;
  deleteBudget?: Maybe<Scalars['Boolean']>;
  deleteBudgetGroup?: Maybe<BudgetGroup>;
  deleteBudgetSetting?: Maybe<BudgetSetting>;
  deleteItem?: Maybe<Item>;
  deleteOrderHubComment: Scalars['Boolean'];
  deleteOrderHubStatus: OrderHubStatus;
  /** Delete question */
  deleteQuestion: Scalars['Boolean'];
  deleteRequestHub: RequestHub;
  deleteRequestHubComment: Scalars['Boolean'];
  deleteRequestHubNote: Scalars['Boolean'];
  deleteUserAddress?: Maybe<UserAddress>;
  /** Delete workflow */
  deleteWorkflow: Scalars['Boolean'];
  /** Delete workflow group */
  deleteWorkflowGroup: Scalars['Boolean'];
  departmentCreate: LocationDept;
  departmentUpdate: LocationDept;
  disposeOrderHubFleetItems?: Maybe<Scalars['Boolean']>;
  excludeProduct?: Maybe<ExcludedProductInfoType>;
  facilityCreate: Organization;
  facilityUpdate: Organization;
  fileUpload: TempFile;
  fleetTagUpdate?: Maybe<Tag>;
  forgotPassword: ForgotPasswordResponse;
  getMasqueradeUser: MasqueradeLoginResponse;
  locationCreate?: Maybe<LocationManagementType>;
  locationUpdate?: Maybe<LocationManagementType>;
  lockOrUnlockBucketBySplitType?: Maybe<BudgetType>;
  login: AuthPayload;
  logout: LogoutResponse;
  markActionableNotificationsAsRead?: Maybe<Scalars['Boolean']>;
  multipleUpload: Array<TempFile>;
  orderHubSetStatus: OrderHub;
  organizationThresholdUpdate: Organization;
  placeOrder?: Maybe<Order>;
  preValidateOrderHubName: Scalars['String'];
  refreshToken: RefreshTokenPayload;
  regionCreate?: Maybe<Region>;
  regionUpdate?: Maybe<Region>;
  register: RegisterResponse;
  removeProductFromExclusion: ExcludeItemResponseStatusEnum;
  reportIssue?: Maybe<Scalars['Boolean']>;
  requestProduct: RequestProduct;
  requestWidget?: Maybe<Scalars['Boolean']>;
  resetLifespan?: Maybe<Scalars['Boolean']>;
  resetTwoFactorForUser: Scalars['Boolean'];
  restartWorkflowForQuestion: Scalars['Boolean'];
  saveFleetItem: FleetItem;
  saveGoogleChorusDetails: GoogleChorusFleetItem;
  sendPasswordEmail?: Maybe<UpdatePasswordResponse>;
  serviceLineCreate: ServiceLine;
  serviceLineUpdate: ServiceLine;
  setTwoFactorQRCode: Scalars['String'];
  subscribeToCategories?: Maybe<User>;
  tagCreate?: Maybe<Tag>;
  tagDelete?: Maybe<Tag>;
  tagUpdate?: Maybe<Tag>;
  twoFactorLogin: AuthPayload;
  twoFactorOrganizationAdminUpdate: Scalars['Boolean'];
  twoFactorUpdate: Scalars['Boolean'];
  updateApprovalWorkflowApprovals: Scalars['Boolean'];
  /** Update priorities */
  updateApprovalWorkflowPriorities: Scalars['Boolean'];
  updateBudget: BudgetType;
  updateBudgetGroup: BudgetGroup;
  /** Update purchase date or purchase price of fleet item and asset. */
  updateDataHealthParameters: AssetIssue;
  /** Update default workflow */
  updateDefaultWorkflow: ApprovalWorkflow;
  updateDisposedFleetItem: DetailedFleetItemType;
  updateFleetItemDetailed: DetailedFleetItemType;
  updateForgottenPassword: ForgotPasswordResponse;
  updateItem?: Maybe<Item>;
  updateLifespan?: Maybe<Scalars['Boolean']>;
  updateMyWidgets: Array<Widget>;
  updateOrderHub: OrderHub;
  updateOrderHubComment?: Maybe<OrderHubComment>;
  updateOrderHubStatus: OrderHubStatus;
  updatePassword: UpdatePasswordResponse;
  updateProfile: User;
  /** Update question */
  updateQuestion: Question;
  /** Update question control */
  updateQuestionControl: Question;
  updateQuestionPriorities: Scalars['Boolean'];
  updateRequestHub: RequestHub;
  updateRequestHubComment?: Maybe<OrderHubComment>;
  updateRequestHubControl?: Maybe<Array<RequestHubControlList>>;
  updateRequestHubNotes: Scalars['Boolean'];
  updateUserAddress: UserAddress;
  /** Update workflow */
  updateWorkflow: ApprovalWorkflow;
  updateWorkflowApprovalsByAdmin?: Maybe<ApprovalWorkflow>;
  /** Update workflow group */
  updateWorkflowGroup: ApprovalWorkflow;
  userCreate: UserManagementType;
  userDelete: UserManagementType;
  userTable?: Maybe<CustomTable>;
  userUpdate: UserManagementType;
  verifyEmail?: Maybe<VerifyEmailResponse>;
  workflowApproves: Scalars['Boolean'];
};


export type MutationActivateBudgetGroupArgs = {
  id: Scalars['ID'];
};


export type MutationAddOrRemoveAutomaticLabelArgs = {
  id: Scalars['ID'];
  selected: Scalars['Boolean'];
};


export type MutationAddOrderHubCommentArgs = {
  comment: Scalars['String'];
  order_hub_id: Scalars['ID'];
};


export type MutationAddRequestHubCommentArgs = {
  comment: Scalars['String'];
  request_hub_id: Scalars['ID'];
};


export type MutationAuctionLotAskQuestionArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<AuctionLotAskQuestionInput>;
};


export type MutationBulkTagUpdateArgs = {
  input: BulkTagInput;
};


export type MutationCacheFiltersArgs = {
  params: Scalars['JSON'];
};


export type MutationCartAddItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<CartAddItemInput>;
};


export type MutationCartAddOrUpdateItemArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<CartAddOrUpdateItemInput>;
};


export type MutationCartClearArgs = {
  id: Scalars['ID'];
};


export type MutationCartDeleteItemArgs = {
  id: Scalars['ID'];
};


export type MutationCartUpdateItemArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<CartItemUpdateInput>;
};


export type MutationClearSubscriptionArgs = {
  channelNames: Array<Scalars['String']>;
};


export type MutationContactUsArgs = {
  input?: InputMaybe<ContactUsInput>;
};


export type MutationCostCenterCreateArgs = {
  input: CostCenterInput;
};


export type MutationCostCenterUpdateArgs = {
  id: Scalars['ID'];
  input: CostCenterInput;
};


export type MutationCreateBidArgs = {
  input?: InputMaybe<CreateBid>;
};


export type MutationCreateBudgetArgs = {
  input?: InputMaybe<CreateBudgetInput>;
  type?: BudgetTypeEnum;
};


export type MutationCreateBudgetGroupArgs = {
  input?: InputMaybe<BudgetGroupCreateInput>;
};


export type MutationCreateBudgetSettingArgs = {
  input?: InputMaybe<BudgetSettingInput>;
};


export type MutationCreateDefaultWorkflowArgs = {
  input?: InputMaybe<DefaultApprovalWorkflowInput>;
};


export type MutationCreateItemArgs = {
  input?: InputMaybe<ItemRequest>;
};


export type MutationCreateOrderHubArgs = {
  input: OrderAssemblyInput;
};


export type MutationCreateOrderHubStatusArgs = {
  input: OrderHubStatusCreateInput;
};


export type MutationCreateQuestionArgs = {
  input: QuestionInput;
};


export type MutationCreateRequestHubArgs = {
  input: RequestHubItemInput;
};


export type MutationCreateUserAddressArgs = {
  input: CreateUserAddressInput;
};


export type MutationCreateWorkflowArgs = {
  input: ApprovalWorkflowInput;
};


export type MutationCreateWorkflowGroupArgs = {
  input: ApprovalWorkflowGroupInput;
};


export type MutationDeleteBudgetArgs = {
  id: Scalars['ID'];
  type?: BudgetTypeEnum;
};


export type MutationDeleteBudgetGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBudgetSettingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrderHubCommentArgs = {
  comment_id: Scalars['ID'];
  order_hub_id: Scalars['ID'];
};


export type MutationDeleteOrderHubStatusArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteQuestionArgs = {
  input: DeleteQuestionInput;
};


export type MutationDeleteRequestHubArgs = {
  delete_anyway?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type MutationDeleteRequestHubCommentArgs = {
  comment_id: Scalars['ID'];
  request_hub_id: Scalars['ID'];
};


export type MutationDeleteRequestHubNoteArgs = {
  id: Scalars['ID'];
  request_hub_id: Scalars['ID'];
};


export type MutationDeleteUserAddressArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkflowArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkflowGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDepartmentCreateArgs = {
  input: LocationDeptInput;
};


export type MutationDepartmentUpdateArgs = {
  id: Scalars['ID'];
  input: LocationDeptInput;
};


export type MutationDisposeOrderHubFleetItemsArgs = {
  id: Scalars['ID'];
};


export type MutationExcludeProductArgs = {
  product_id: Scalars['ID'];
};


export type MutationFacilityCreateArgs = {
  input: FacilityInput;
};


export type MutationFacilityUpdateArgs = {
  id: Scalars['ID'];
  input: FacilityInput;
};


export type MutationFileUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationFleetTagUpdateArgs = {
  input: FleetTagInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationGetMasqueradeUserArgs = {
  input?: InputMaybe<MasqueradeLoginInput>;
};


export type MutationLocationCreateArgs = {
  input: LocationManagementInput;
};


export type MutationLocationUpdateArgs = {
  id: Scalars['ID'];
  input: LocationManagementInput;
};


export type MutationLockOrUnlockBucketBySplitTypeArgs = {
  id: Scalars['ID'];
  locked?: InputMaybe<Scalars['Boolean']>;
  splitTypeId: Scalars['Int'];
  type: BudgetTypeEnum;
};


export type MutationLoginArgs = {
  input?: InputMaybe<LoginInput>;
};


export type MutationMarkActionableNotificationsAsReadArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationMultipleUploadArgs = {
  files: Array<Scalars['Upload']>;
};


export type MutationOrderHubSetStatusArgs = {
  id: Scalars['ID'];
  status_id: Scalars['ID'];
};


export type MutationOrganizationThresholdUpdateArgs = {
  capital_threshold: Scalars['Int'];
  id: Scalars['ID'];
};


export type MutationPlaceOrderArgs = {
  input?: InputMaybe<PlaceOrderInput>;
};


export type MutationPreValidateOrderHubNameArgs = {
  input: OrderHubPreValidate;
};


export type MutationRefreshTokenArgs = {
  input?: InputMaybe<RefreshTokenInput>;
};


export type MutationRegionCreateArgs = {
  input: RegionInput;
};


export type MutationRegionUpdateArgs = {
  id: Scalars['ID'];
  input: RegionInput;
};


export type MutationRegisterArgs = {
  input?: InputMaybe<RegisterInput>;
};


export type MutationRemoveProductFromExclusionArgs = {
  excluded_product_id: Scalars['ID'];
};


export type MutationReportIssueArgs = {
  suggestion?: InputMaybe<Scalars['String']>;
};


export type MutationRequestProductArgs = {
  input: RequestProductInput;
};


export type MutationRequestWidgetArgs = {
  input: RequestWidgetInput;
};


export type MutationResetTwoFactorForUserArgs = {
  user_id: Scalars['String'];
};


export type MutationRestartWorkflowForQuestionArgs = {
  id: Scalars['ID'];
};


export type MutationSaveFleetItemArgs = {
  input: FleetItemInput;
};


export type MutationSaveGoogleChorusDetailsArgs = {
  input: GoogleChorusInput;
};


export type MutationSendPasswordEmailArgs = {
  id: Scalars['ID'];
};


export type MutationServiceLineCreateArgs = {
  input: ServiceLineInput;
};


export type MutationServiceLineUpdateArgs = {
  id: Scalars['ID'];
  input: ServiceLineInput;
};


export type MutationSetTwoFactorQrCodeArgs = {
  method: TwoFaMethodEnum;
};


export type MutationSubscribeToCategoriesArgs = {
  input?: InputMaybe<SubscribeToCategoriesInput>;
};


export type MutationTagCreateArgs = {
  input: TagInput;
};


export type MutationTagDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationTagUpdateArgs = {
  id: Scalars['ID'];
  input: TagInput;
};


export type MutationTwoFactorLoginArgs = {
  input?: InputMaybe<OtpInput>;
};


export type MutationTwoFactorOrganizationAdminUpdateArgs = {
  input?: InputMaybe<TwoFactorOrganizationAdminUpdateInput>;
};


export type MutationTwoFactorUpdateArgs = {
  input?: InputMaybe<TwoFactorUpdateInput>;
};


export type MutationUpdateApprovalWorkflowApprovalsArgs = {
  id: Scalars['ID'];
  input: UpdateApprovalWorkflowApprovals;
};


export type MutationUpdateApprovalWorkflowPrioritiesArgs = {
  input: Array<ApprovalWorkflowPriorityInput>;
  type: WorkFlowTypeEnum;
};


export type MutationUpdateBudgetArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<UpdateBudgetInput>;
  type?: BudgetTypeEnum;
};


export type MutationUpdateBudgetGroupArgs = {
  input?: InputMaybe<BudgetGroupUpdateInput>;
};


export type MutationUpdateDataHealthParametersArgs = {
  input: UpdateDataHealthParameterInput;
};


export type MutationUpdateDefaultWorkflowArgs = {
  input?: InputMaybe<DefaultApprovalWorkflowInput>;
};


export type MutationUpdateDisposedFleetItemArgs = {
  id: Scalars['ID'];
  input: FleetItemDetailsInput;
};


export type MutationUpdateFleetItemDetailedArgs = {
  id: Scalars['ID'];
  input: FleetItemDetailsInput;
};


export type MutationUpdateForgottenPasswordArgs = {
  input?: InputMaybe<NewPasswordWithCodeInput>;
};


export type MutationUpdateItemArgs = {
  input?: InputMaybe<UpdateItemRequest>;
};


export type MutationUpdateLifespanArgs = {
  categoryLifespan: Array<CategoryLifespanInput>;
};


export type MutationUpdateMyWidgetsArgs = {
  input: Array<MyWidgetInput>;
};


export type MutationUpdateOrderHubArgs = {
  id: Scalars['ID'];
  input: UpdateOrderAssemblyInput;
};


export type MutationUpdateOrderHubCommentArgs = {
  comment: Scalars['String'];
  comment_id: Scalars['ID'];
  order_hub_id: Scalars['ID'];
};


export type MutationUpdateOrderHubStatusArgs = {
  id: Scalars['ID'];
  input: OrderHubStatusUpdateInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePassword;
};


export type MutationUpdateProfileArgs = {
  input: ProfileInput;
};


export type MutationUpdateQuestionArgs = {
  input: QuestionInput;
};


export type MutationUpdateQuestionControlArgs = {
  edit_restarts_workflow: Scalars['Boolean'];
  id: Scalars['ID'];
  required: Scalars['Boolean'];
  show: Scalars['Boolean'];
};


export type MutationUpdateQuestionPrioritiesArgs = {
  input: Array<QuestionPriorityInput>;
};


export type MutationUpdateRequestHubArgs = {
  id: Scalars['ID'];
  input: UpdateRequestHubItemInput;
};


export type MutationUpdateRequestHubCommentArgs = {
  comment: Scalars['String'];
  comment_id: Scalars['ID'];
  request_hub_id: Scalars['ID'];
};


export type MutationUpdateRequestHubControlArgs = {
  input: RequestHubControlInput;
};


export type MutationUpdateRequestHubNotesArgs = {
  input: Notes;
  request_hub_id: Scalars['ID'];
};


export type MutationUpdateUserAddressArgs = {
  id: Scalars['ID'];
  input: UpdateUserAddressInput;
};


export type MutationUpdateWorkflowArgs = {
  input: ApprovalWorkflowInput;
};


export type MutationUpdateWorkflowApprovalsByAdminArgs = {
  id: Scalars['ID'];
  input: UpdateApprovalsByAdmin;
};


export type MutationUpdateWorkflowGroupArgs = {
  id: Scalars['ID'];
  input: ApprovalWorkflowGroupInput;
};


export type MutationUserCreateArgs = {
  input: UserManagementInput;
};


export type MutationUserDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationUserTableArgs = {
  input: UserTableInput;
};


export type MutationUserUpdateArgs = {
  id: Scalars['ID'];
  input: UserManagementInput;
};


export type MutationVerifyEmailArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type MutationWorkflowApprovesArgs = {
  id: Scalars['ID'];
  input: WorkflowApprovesInput;
};

export type MyWidgetInput = {
  name: Scalars['String'];
  position: PositionInput;
  size: WidgetSizeInput;
};

export type NewPasswordWithCodeInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};

export type NewRequestItem = {
  __typename?: 'NewRequestItem';
  actual_cost?: Maybe<Scalars['Float']>;
  budget_id?: Maybe<Scalars['ID']>;
  budget_name?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['ID']>;
  category_name?: Maybe<Scalars['String']>;
  cost_center?: Maybe<Scalars['String']>;
  equipment_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  model_budget_id?: Maybe<Scalars['ID']>;
  model_budget_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['ID']>;
  priority?: Maybe<Scalars['String']>;
  roi?: Maybe<Scalars['Float']>;
  selected?: Maybe<Scalars['Boolean']>;
  service_line?: Maybe<Scalars['String']>;
  service_line_id?: Maybe<Scalars['ID']>;
  total_cost?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type NewRequestItemInput = {
  model_budget_id: Scalars['ID'];
  orderBy?: InputMaybe<OrderBy>;
  searchString?: InputMaybe<Scalars['String']>;
  selected: Scalars['Boolean'];
  sortBy?: InputMaybe<Scalars['String']>;
};

/** A paginated list of NewRequestItem items. */
export type NewRequestItemPaginator = {
  __typename?: 'NewRequestItemPaginator';
  /** A list of NewRequestItem items. */
  data: Array<NewRequestItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type NewRequestedFiltersInput = {
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  facility_ids?: InputMaybe<Array<Scalars['ID']>>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  select_option?: AssetSelectionSelectOptionsEnum;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export enum NewRequestedOrderByEnum {
  Category = 'CATEGORY',
  CostCenter = 'COST_CENTER',
  EquipmentCost = 'EQUIPMENT_COST',
  Facility = 'FACILITY',
  Location = 'LOCATION',
  Manufacturer = 'MANUFACTURER',
  Priority = 'PRIORITY',
  Request = 'REQUEST',
  Requester = 'REQUESTER',
  ServiceLine = 'SERVICE_LINE',
  TotalCost = 'TOTAL_COST'
}

export type NewRequestedOrderByInput = {
  column?: InputMaybe<NewRequestedOrderByEnum>;
  order?: InputMaybe<SortOrder>;
};

export type NewRequestedType = {
  __typename?: 'NewRequestedType';
  category?: Maybe<Scalars['String']>;
  cost_center?: Maybe<Scalars['String']>;
  equipment_cost?: Maybe<Scalars['Float']>;
  facility?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  manufacturer?: Maybe<Scalars['String']>;
  orderHub?: Maybe<OrderHub>;
  orderHubCount: Scalars['Int'];
  priority?: Maybe<Scalars['String']>;
  request: Scalars['String'];
  requester: Scalars['String'];
  selected: Scalars['Boolean'];
  service_line?: Maybe<Scalars['String']>;
  spending_new_cost?: Maybe<Scalars['Float']>;
  spending_replacement_cost?: Maybe<Scalars['Float']>;
  total_cost?: Maybe<Scalars['Float']>;
};

/** A paginated list of NewRequestedType items. */
export type NewRequestedTypePaginator = {
  __typename?: 'NewRequestedTypePaginator';
  /** A list of NewRequestedType items. */
  data: Array<NewRequestedType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Notes = {
  notes?: InputMaybe<Array<RequestHubNote>>;
};

export type Notifiable = {
  __typename?: 'Notifiable';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  unique_identifier?: Maybe<Scalars['String']>;
};

export type NotifiableUsers = {
  __typename?: 'NotifiableUsers';
  id: Scalars['ID'];
  user?: Maybe<User>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type OemFilterInput = {
  category_id?: InputMaybe<Scalars['ID']>;
  facility_ids?: InputMaybe<Array<Scalars['ID']>>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  my_asset?: InputMaybe<Scalars['Boolean']>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  score?: InputMaybe<Scalars['Boolean']>;
  stage_of_life?: InputMaybe<Array<StageOfLifeEnum>>;
};

export type OemTotalAssets = {
  __typename?: 'OEMTotalAssets';
  mine_count: Scalars['Int'];
  mine_sum: Scalars['Float'];
  total_count: Scalars['Int'];
  total_sum: Scalars['Float'];
};

export type OtpInput = {
  method: Scalars['String'];
  otp: Scalars['String'];
  username: Scalars['String'];
};

export type OemAreaChartData = {
  __typename?: 'OemAreaChartData';
  data?: Maybe<Array<Maybe<ManufacturersCount>>>;
  legend?: Maybe<Array<Maybe<Legend>>>;
  life_span?: Maybe<Scalars['Int']>;
  manufacturers?: Maybe<Array<Maybe<Manufacturer>>>;
};

export type OemAreaChartList = {
  __typename?: 'OemAreaChartList';
  budget?: Maybe<CapitalCalendarBudget>;
  ce_number?: Maybe<Scalars['String']>;
  future_years?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  location_name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  remaining_age?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
};

/** A paginated list of OemAreaChartList items. */
export type OemAreaChartListPaginator = {
  __typename?: 'OemAreaChartListPaginator';
  /** A list of OemAreaChartList items. */
  data: Array<OemAreaChartList>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type OemBargraphCategoryData = {
  __typename?: 'OemBargraphCategoryData';
  install_base: Scalars['Float'];
  legend?: Maybe<Array<Maybe<Legend>>>;
  total_asset: Scalars['Int'];
  total_asset_for_replacement: Scalars['Int'];
  total_my_asset: Scalars['Int'];
  total_my_asset_for_replacement: Scalars['Int'];
  total_replacement_cost: Scalars['Float'];
};

export type OemManufactrer = {
  __typename?: 'OemManufactrer';
  count: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  total?: Maybe<Scalars['Float']>;
};

export type OemManufacturer = {
  __typename?: 'OemManufacturer';
  manufacturer_id: Scalars['ID'];
  oem_id: Scalars['ID'];
};

export type OemOrganization = {
  __typename?: 'OemOrganization';
  oem_id: Scalars['ID'];
  organization: Scalars['String'];
  organization_id: Scalars['ID'];
};

export type OemRedAssetLineGraph = {
  __typename?: 'OemRedAssetLineGraph';
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  count?: Maybe<Array<Maybe<Count>>>;
};

export type OemRedAssetList = {
  __typename?: 'OemRedAssetList';
  budget_id?: Maybe<Scalars['Int']>;
  category_id?: Maybe<Scalars['Int']>;
  category_name?: Maybe<Scalars['String']>;
  ce_number?: Maybe<Scalars['String']>;
  future_years?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  location_name?: Maybe<Scalars['String']>;
  manufacture?: Maybe<Scalars['String']>;
  manufacture_id?: Maybe<Scalars['Int']>;
  mpn?: Maybe<Scalars['String']>;
  oem_manufacture_id?: Maybe<Scalars['Int']>;
  product_description?: Maybe<Scalars['String']>;
  remaining_age?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
};

/** A paginated list of OemRedAssetList items. */
export type OemRedAssetListPaginator = {
  __typename?: 'OemRedAssetListPaginator';
  /** A list of OemRedAssetList items. */
  data: Array<OemRedAssetList>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type OemRedAssetPieGraph = {
  __typename?: 'OemRedAssetPieGraph';
  category_id: Scalars['ID'];
  category_name: Scalars['String'];
  count: Scalars['Int'];
  manufacturers?: Maybe<Array<OemManufactrer>>;
  total: Scalars['Float'];
};

export type Order = {
  __typename?: 'Order';
  approver?: Maybe<User>;
  billingAddress?: Maybe<Address>;
  created_at?: Maybe<Scalars['DateTime']>;
  customer: User;
  fda_buyer_approved: Scalars['Boolean'];
  fees?: Maybe<Array<Maybe<Fee>>>;
  historical_order_id: Scalars['ID'];
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<OrderItem>>>;
  order_subtotal?: Maybe<Scalars['Float']>;
  paymentSource?: Maybe<PaymentSource>;
  referral_id?: Maybe<Scalars['ID']>;
  referral_type_id?: Maybe<Scalars['ID']>;
  shipping?: Maybe<Scalars['Float']>;
  shippingAddress?: Maybe<Address>;
  status: OrderStatus;
  taxes?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type OrderAssemblyAssetsFiltersInput = {
  budget_id: Scalars['ID'];
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type OrderAssemblyAssetsInfo = {
  __typename?: 'OrderAssemblyAssetsInfo';
  ce_number?: Maybe<Scalars['String']>;
  costCenter?: Maybe<CostCenter>;
  east_cost?: Maybe<Scalars['Float']>;
  facility?: Maybe<Organization>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  product: OrderAssemblyProductInfo;
  serviceLine?: Maybe<ServiceLine>;
};

/** A paginated list of OrderAssemblyAssetsInfo items. */
export type OrderAssemblyAssetsInfoPaginator = {
  __typename?: 'OrderAssemblyAssetsInfoPaginator';
  /** A list of OrderAssemblyAssetsInfo items. */
  data: Array<OrderAssemblyAssetsInfo>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type OrderAssemblyAssetsOrderByInput = {
  column?: InputMaybe<OrderAssemblyOrderEnum>;
  order?: InputMaybe<SortOrder>;
};

export type OrderAssemblyCustomProductInfo = {
  __typename?: 'OrderAssemblyCustomProductInfo';
  budget?: Maybe<CapitalCalendarBudget>;
  east_cost?: Maybe<Scalars['Float']>;
  manufacturer?: Maybe<Manufacturer>;
  new_catalog?: Maybe<Scalars['String']>;
  new_manufacturer?: Maybe<Scalars['String']>;
  new_mpn?: Maybe<Scalars['String']>;
  new_product?: Maybe<Scalars['String']>;
  owner_id: Scalars['ID'];
  product_description?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  requestHub?: Maybe<RequestHub>;
};

export type OrderAssemblyInput = {
  budget_id: Scalars['ID'];
  cap_req_number?: InputMaybe<Scalars['String']>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  files?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  po_number?: InputMaybe<Scalars['String']>;
  products: Array<ProductInput>;
  supplier?: InputMaybe<Scalars['String']>;
};

export enum OrderAssemblyOrderEnum {
  CostCenter = 'COST_CENTER',
  EastCost = 'EAST_COST',
  Facility = 'FACILITY',
  Location = 'LOCATION',
  Name = 'NAME',
  ServiceLine = 'SERVICE_LINE',
  ToBePurchased = 'TO_BE_PURCHASED'
}

export type OrderAssemblyProductInfo = {
  __typename?: 'OrderAssemblyProductInfo';
  id: Scalars['ID'];
  manufacturer: Manufacturer;
  mpn: Scalars['String'];
  product_description: Scalars['String'];
  product_details?: Maybe<Scalars['String']>;
  vendors: Array<Vendor>;
};

export type OrderAssemblyProductListInfo = {
  __typename?: 'OrderAssemblyProductListInfo';
  budget: CapitalCalendarBudget;
  east_cost?: Maybe<Scalars['Float']>;
  fleetItem?: Maybe<FleetItem>;
  id: Scalars['ID'];
  manufacturer: Manufacturer;
  mpn: Scalars['String'];
  owner_id: Scalars['ID'];
  product_description: Scalars['String'];
  product_details?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  requestHub?: Maybe<RequestHub>;
  vendors: Array<Vendor>;
};

export type OrderAssemblyProducts = {
  __typename?: 'OrderAssemblyProducts';
  newProducts?: Maybe<Array<OrderAssemblyCustomProductInfo>>;
  products?: Maybe<Array<OrderAssemblyProductListInfo>>;
};

export type OrderAssemblyRequestFiltersInput = {
  budget_id: Scalars['ID'];
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type OrderAssemblyRequestInfo = {
  __typename?: 'OrderAssemblyRequestInfo';
  costCenter?: Maybe<CostCenter>;
  facility?: Maybe<Organization>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  name: Scalars['String'];
  products: Array<OrderAssemblyRequestProducts>;
  serviceLine?: Maybe<ServiceLine>;
};

/** A paginated list of OrderAssemblyRequestInfo items. */
export type OrderAssemblyRequestInfoPaginator = {
  __typename?: 'OrderAssemblyRequestInfoPaginator';
  /** A list of OrderAssemblyRequestInfo items. */
  data: Array<OrderAssemblyRequestInfo>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type OrderAssemblyRequestProducts = {
  __typename?: 'OrderAssemblyRequestProducts';
  east_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  manufacturer?: Maybe<Manufacturer>;
  mpn?: Maybe<Scalars['String']>;
  product_description?: Maybe<Scalars['String']>;
  product_details?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  vendors?: Maybe<Array<Vendor>>;
};

export type OrderAssemblyRequestsOrderByInput = {
  column?: InputMaybe<OrderAssemblyOrderEnum>;
  order?: InputMaybe<SortOrder>;
};

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export enum OrderByDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

export type OrderDocumentType = {
  __typename?: 'OrderDocumentType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type OrderFilters = {
  created_from?: InputMaybe<Scalars['Date']>;
  created_to?: InputMaybe<Scalars['Date']>;
  search?: InputMaybe<Scalars['String']>;
};

export type OrderHub = {
  __typename?: 'OrderHub';
  assetsCount: Scalars['Int'];
  assignedFleetItems: Array<FleetItem>;
  budget?: Maybe<CapitalCalendarBudget>;
  budgetGroup?: Maybe<BudgetGroup>;
  cap_req_number?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  comments: Array<OrderHubComment>;
  costCenters: Array<CostCenter>;
  created_at: Scalars['DateTime'];
  dispositionReason?: Maybe<DispositionReason>;
  facility?: Maybe<Organization>;
  files: Array<File>;
  final_price: Scalars['Float'];
  fleetItems: Array<FleetItem>;
  id: Scalars['ID'];
  is_paid?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  name: Scalars['String'];
  new_capital: Scalars['Boolean'];
  new_capital_spend?: Maybe<Scalars['Float']>;
  orderHubStatus?: Maybe<OrderHubStatus>;
  order_hub_status?: Maybe<OrderHubStatusEnum>;
  order_hub_types: Array<OrderHubTypeEnum>;
  organization?: Maybe<Organization>;
  po_number?: Maybe<Scalars['String']>;
  quoted_price: Scalars['Float'];
  relatedItems?: Maybe<Array<Maybe<OrderHubRelation>>>;
  replacement: Scalars['Boolean'];
  replacement_spend?: Maybe<Scalars['Float']>;
  requestHub?: Maybe<RequestHub>;
  requestor?: Maybe<Scalars['String']>;
  serviceLine?: Maybe<ServiceLine>;
  supplier?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  unplanned_justification?: Maybe<Scalars['String']>;
  unplanned_spend?: Maybe<Scalars['Float']>;
  vendor?: Maybe<Scalars['String']>;
};

export type OrderHubComment = {
  __typename?: 'OrderHubComment';
  comment: Scalars['String'];
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  updated_at: Scalars['DateTime'];
  user: User;
};

export type OrderHubCreateInput = {
  budget_id: Scalars['ID'];
  cap_req_number?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['ID']>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  disposition_id?: InputMaybe<Scalars['ID']>;
  files?: InputMaybe<Array<Scalars['ID']>>;
  final_price?: InputMaybe<Scalars['Float']>;
  fleet_item_ids?: InputMaybe<Array<Scalars['ID']>>;
  location_id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  new_capital_spend?: InputMaybe<Scalars['Float']>;
  order_hub_types: Array<OrderHubTypeEnum>;
  organization_id?: InputMaybe<Scalars['ID']>;
  po_number?: InputMaybe<Scalars['String']>;
  quoted_price?: InputMaybe<Scalars['Float']>;
  replacement_spend?: InputMaybe<Scalars['Float']>;
  request_hub_id?: InputMaybe<Scalars['ID']>;
  requestor?: InputMaybe<Scalars['String']>;
  service_line_id?: InputMaybe<Scalars['ID']>;
  supplier?: InputMaybe<Scalars['String']>;
  unplanned_justification?: InputMaybe<Scalars['String']>;
  unplanned_spend?: InputMaybe<Scalars['ID']>;
};

export type OrderHubFilesChangeInput = {
  attach?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  detach?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/** Order hub list filter */
export type OrderHubFilterInput = {
  /** Budget Id */
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Costcenter Ids */
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Location Ids */
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Location Ids */
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  /** Facility Ids */
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Search based on input */
  search?: InputMaybe<Scalars['String']>;
  /** Service line Ids */
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Order hub type */
  spend_types?: InputMaybe<Array<OrderHubTypeEnum>>;
  /** Order us status */
  status?: InputMaybe<OrderHubStatusEnum>;
};

export type OrderHubFleetItemIdAndCeNumber = {
  __typename?: 'OrderHubFleetItemIdAndCeNumber';
  ce_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  product?: Maybe<Scalars['String']>;
  productInfo?: Maybe<Product>;
};

export type OrderHubFleetItemsIdByCeNumberInput = {
  budget_id?: InputMaybe<Scalars['ID']>;
  category_id?: InputMaybe<Scalars['ID']>;
  ce_number?: InputMaybe<Scalars['String']>;
  cost_center_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  organization_id?: InputMaybe<Scalars['ID']>;
  service_line_id?: InputMaybe<Scalars['ID']>;
};

/** Order hub columns */
export enum OrderHubOrderByColumnEnum {
  CostCenter = 'COST_CENTER',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Requestor = 'REQUESTOR',
  ServiceLine = 'SERVICE_LINE',
  Total = 'TOTAL'
}

/** Order hub order by input */
export type OrderHubOrderByInput = {
  /** Column to be sorted */
  column: OrderHubOrderByColumnEnum;
  /** Order of column to be sorted */
  order?: InputMaybe<SortOrder>;
};

/** A paginated list of OrderHub items. */
export type OrderHubPaginator = {
  __typename?: 'OrderHubPaginator';
  /** A list of OrderHub items. */
  data: Array<OrderHub>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type OrderHubPreValidate = {
  budget_id: Scalars['ID'];
  name: Scalars['String'];
};

export type OrderHubRelation = {
  __typename?: 'OrderHubRelation';
  id: Scalars['ID'];
  overrideRelations?: Maybe<Array<Maybe<OrderHubRelationOverride>>>;
  related?: Maybe<UnionOrderHubRelation>;
};

export type OrderHubRelationOverride = {
  __typename?: 'OrderHubRelationOverride';
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  overrideable?: Maybe<UnionOrderHubRelationOverride>;
  relation_id: Scalars['ID'];
};

export type OrderHubStatus = {
  __typename?: 'OrderHubStatus';
  code: Scalars['String'];
  colour: Scalars['String'];
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notifiableUsers?: Maybe<Array<NotifiableUsers>>;
  organization?: Maybe<Organization>;
  sort_order?: Maybe<Scalars['Int']>;
};

export type OrderHubStatusCreateInput = {
  code: Scalars['String'];
  colour: Scalars['String'];
  instructions?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export enum OrderHubStatusEnum {
  OnHold = 'ON_HOLD',
  OrderComplete = 'ORDER_COMPLETE',
  OrderPlaced = 'ORDER_PLACED',
  ReadyToOrder = 'READY_TO_ORDER',
  WaitingForApproval = 'WAITING_FOR_APPROVAL'
}

/** A paginated list of OrderHubStatus items. */
export type OrderHubStatusPaginator = {
  __typename?: 'OrderHubStatusPaginator';
  /** A list of OrderHubStatus items. */
  data: Array<OrderHubStatus>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type OrderHubStatusUpdateInput = {
  colour: Scalars['String'];
  instructions?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  sort_order?: InputMaybe<Scalars['Int']>;
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export enum OrderHubTypeEnum {
  New = 'NEW',
  Replacement = 'REPLACEMENT',
  Unplanned = 'UNPLANNED'
}

export type OrderHubUpdateInput = {
  cap_req_number?: InputMaybe<Scalars['String']>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  disposition_id?: InputMaybe<Scalars['ID']>;
  files?: InputMaybe<UpdateRelatedItemsInput>;
  final_price?: InputMaybe<Scalars['Float']>;
  fleetItems?: InputMaybe<UpdateRelatedItemsInput>;
  location_id?: InputMaybe<Scalars['ID']>;
  po_number?: InputMaybe<Scalars['String']>;
  quoted_price?: InputMaybe<Scalars['Float']>;
  request_hub_id?: InputMaybe<Scalars['ID']>;
  requestor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderHubStatusEnum>;
  status_id?: InputMaybe<Scalars['ID']>;
  supplier?: InputMaybe<Scalars['String']>;
  unplanned_justification?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  historical_order_id: Scalars['ID'];
  id: Scalars['ID'];
  item?: Maybe<Item>;
  order?: Maybe<Order>;
  quantity: Scalars['String'];
  referralType?: Maybe<ReferralType>;
  referral_id?: Maybe<Scalars['ID']>;
  resell?: Maybe<Scalars['Boolean']>;
  sell_price: Scalars['Float'];
  shipment_id?: Maybe<Scalars['ID']>;
  status: OrderItemStatusEnum;
  vendor_invoice?: Maybe<Scalars['String']>;
};


export type OrderItemItemArgs = {
  trashed?: InputMaybe<Trashed>;
};

export enum OrderItemStatusEnum {
  Accepted = 'ACCEPTED',
  InCart = 'IN_CART',
  Refund = 'REFUND',
  Reimbursed = 'REIMBURSED',
  Shipped = 'SHIPPED',
  Sold = 'SOLD'
}

/** A paginated list of Order items. */
export type OrderPaginator = {
  __typename?: 'OrderPaginator';
  /** A list of Order items. */
  data: Array<Order>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  address?: Maybe<Address>;
  affiliates?: Maybe<Array<Maybe<Affiliate>>>;
  capital_threshold?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  locations?: Maybe<Array<Maybe<Location>>>;
  name: Scalars['String'];
  nonprofit?: Maybe<Scalars['Boolean']>;
  organizationAddress?: Maybe<Array<Maybe<OrganizationAddress>>>;
  parent?: Maybe<Organization>;
  parent_id?: Maybe<Scalars['ID']>;
  permissionGroups?: Maybe<Array<Maybe<PermissionGroup>>>;
  phone?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  regionName?: Maybe<RegionName>;
  region_id?: Maybe<Scalars['ID']>;
  show_google_chorus?: Maybe<Scalars['Boolean']>;
  type?: Maybe<OrganizationType>;
};

export type OrganizationAddress = {
  __typename?: 'OrganizationAddress';
  active: Scalars['Boolean'];
  address: Address;
  id: Scalars['ID'];
  is_primary: Scalars['Boolean'];
  organization: Organization;
};

export type OrganizationCategory = {
  __typename?: 'OrganizationCategory';
  category: Category;
  organization: Organization;
};

export type OrganizationListedStatisticGraphData = {
  __typename?: 'OrganizationListedStatisticGraphData';
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  total_amount?: Maybe<Scalars['Float']>;
};

/** A paginated list of Organization items. */
export type OrganizationPaginator = {
  __typename?: 'OrganizationPaginator';
  /** A list of Organization items. */
  data: Array<Organization>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type OrganizationPurchasedStatisticGraphData = {
  __typename?: 'OrganizationPurchasedStatisticGraphData';
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  total_amount?: Maybe<Scalars['Float']>;
};

export type OrganizationSoldStatisticGraphData = {
  __typename?: 'OrganizationSoldStatisticGraphData';
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  total_amount?: Maybe<Scalars['Float']>;
};

export type OrganizationStation = {
  __typename?: 'OrganizationStation';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Permission>>>;
  terms_id: Scalars['ID'];
};

/** A paginated list of OrganizationType items. */
export type OrganizationTypePaginator = {
  __typename?: 'OrganizationTypePaginator';
  /** A list of OrganizationType items. */
  data: Array<OrganizationType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type PageContentType = {
  __typename?: 'PageContentType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type PaymentSource = {
  __typename?: 'PaymentSource';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** A paginated list of PaymentSource items. */
export type PaymentSourcePaginator = {
  __typename?: 'PaymentSourcePaginator';
  /** A list of PaymentSource items. */
  data: Array<PaymentSource>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Permission = {
  __typename?: 'Permission';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Permission>>>;
  type?: Maybe<Scalars['String']>;
};

export type PlaceOrderInput = {
  items?: InputMaybe<Array<PlaceOrderItemsInput>>;
  payment_address_id: Scalars['ID'];
  payment_nonce?: InputMaybe<Scalars['String']>;
  payment_source_id: Scalars['ID'];
  po_number?: InputMaybe<Scalars['String']>;
  shipping_address_id: Scalars['ID'];
};

export type PlaceOrderItemsInput = {
  id: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
};

export type PositionInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type PositionType = {
  __typename?: 'PositionType';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type PreBudgetRequestHubStats = {
  __typename?: 'PreBudgetRequestHubStats';
  sum_of_request_hub_amount: Scalars['Float'];
  total_request_hubs: Scalars['Int'];
};

export type Priority = {
  __typename?: 'Priority';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  alias?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  depth?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lifespan?: Maybe<Scalars['Int']>;
  manufacturer: Manufacturer;
  mpn: Scalars['String'];
  product_description: Scalars['String'];
  product_details?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  rx_only: Scalars['Boolean'];
  unspsc?: Maybe<Scalars['String']>;
  user: User;
  value?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
};

export type ProductCategoryAttribute = {
  __typename?: 'ProductCategoryAttribute';
  categoryAttribute: CategoryAttribute;
  id: Scalars['ID'];
  product: Product;
  value: Scalars['String'];
};

export type ProductInput = {
  cost: Scalars['Float'];
  fleet_item_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  owner_id: Scalars['ID'];
  request_hub_id?: InputMaybe<Scalars['ID']>;
};

/** A paginated list of Product items. */
export type ProductPaginator = {
  __typename?: 'ProductPaginator';
  /** A list of Product items. */
  data: Array<Product>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ProfileInput = {
  address: AddressInput;
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  mobile?: InputMaybe<Scalars['String']>;
  office?: InputMaybe<Scalars['String']>;
};

export type ProjectsCompleted = {
  __typename?: 'ProjectsCompleted';
  completed?: Maybe<Scalars['Date']>;
  facility?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order: Scalars['String'];
  project: Scalars['String'];
  request_hub_id: Scalars['ID'];
  service_line: Scalars['String'];
  total: Scalars['Float'];
};

/** A paginated list of ProjectsCompleted items. */
export type ProjectsCompletedPaginator = {
  __typename?: 'ProjectsCompletedPaginator';
  /** A list of ProjectsCompleted items. */
  data: Array<ProjectsCompleted>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ProjectsInProgress = {
  __typename?: 'ProjectsInProgress';
  facility?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order: Scalars['String'];
  project: Scalars['String'];
  request_hub_id: Scalars['ID'];
  service_line: Scalars['String'];
  status: OrderHubStatusEnum;
  total: Scalars['Float'];
};

/** A paginated list of ProjectsInProgress items. */
export type ProjectsInProgressPaginator = {
  __typename?: 'ProjectsInProgressPaginator';
  /** A list of ProjectsInProgress items. */
  data: Array<ProjectsInProgress>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type QuantityType = {
  __typename?: 'QuantityType';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A paginated list of QuantityType items. */
export type QuantityTypePaginator = {
  __typename?: 'QuantityTypePaginator';
  /** A list of QuantityType items. */
  data: Array<QuantityType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type QuarterRangeType = {
  __typename?: 'QuarterRangeType';
  from?: Maybe<FleetItemQuarterEnum>;
  to?: Maybe<FleetItemQuarterEnum>;
};

export type Query = {
  __typename?: 'Query';
  activeBudgetNewCapitalStats: BudgetNewCapitalStats;
  activeBudgetReplacementStats: BudgetReplacementStats;
  activeBudgetTotalsStats: BudgetTotalsStats;
  address?: Maybe<Address>;
  approvalGroup: ApprovalWorkflowGroup;
  approvalGroups?: Maybe<ApprovalWorkflowGroupPaginator>;
  approvalNotificationCount: Scalars['Int'];
  approvalNotifications?: Maybe<ApprovalNotificationPaginator>;
  approvalStatuses: Array<ApprovalNotificationStatusEnum>;
  approvedRequests?: Maybe<Array<RequestHub>>;
  approversFilter?: Maybe<ApprovalWorkflowGroupPaginator>;
  /** Get the details of asset issue */
  assetIssue?: Maybe<AssetIssue>;
  /** Paginated list of asset issues with filtering by poduct , mpn, serial number, asset tag and items present in the budget */
  assetIssues?: Maybe<AssetIssuePaginator>;
  assetsByCategory: Array<AssetsStatsType>;
  assetsByFacility: Array<AssetsStatsType>;
  assetsByServiceLine: Array<AssetsStatsType>;
  assetsIn: Scalars['Int'];
  assetsMovementStat?: Maybe<Scalars['Int']>;
  assetsOut: Scalars['Int'];
  assetsReplaced?: Maybe<AssetsReplacedPaginator>;
  assetsReplacedList?: Maybe<FleetItemPaginator>;
  assetsToBeReplaced?: Maybe<ToBeReplacedPaginator>;
  auction?: Maybe<Auction>;
  auctionLot?: Maybe<AuctionLot>;
  auctionLots?: Maybe<Array<Maybe<AuctionLot>>>;
  auctions?: Maybe<AuctionPaginator>;
  automaticLabelsList?: Maybe<Array<Maybe<AutomaticLabel>>>;
  averageAgeByCategory: Array<AverageAgeByCategory>;
  averageAgeByFleet: Array<AverageAgeByFleet>;
  budgetComparison: Array<Maybe<BudgetComparison>>;
  budgetFilter?: Maybe<SelectOptionTypePaginator>;
  budgetGroup?: Maybe<BudgetGroup>;
  budgetGroups?: Maybe<BudgetGroupPaginator>;
  budgetItem: BudgetType;
  budgetList?: Maybe<Array<BudgetList>>;
  budgetPieSummary: Array<BudgetSummaryPie>;
  budgetReplacementItems?: Maybe<AssetReplacementTypePaginator>;
  budgetReplacementItemsStoreToFile: Scalars['String'];
  budgetSplitSummary: Array<BudgetSummary>;
  budgetWidget: Array<BudgetGroup>;
  capitalBudgetFilter?: Maybe<SelectOptionTypePaginator>;
  capitalCalendarBudget?: Maybe<CapitalCalendarBudget>;
  capitalCalendarBudgets?: Maybe<CapitalCalendarBudgetPaginator>;
  cart?: Maybe<Cart>;
  categories?: Maybe<CategoryPaginator>;
  category?: Maybe<Category>;
  categoryFilter?: Maybe<CategoryTreeOptionTypePaginator>;
  costCenter?: Maybe<CostCenter>;
  costCenterFilter?: Maybe<SelectOptionTypePaginator>;
  costCenterList?: Maybe<CostCenterPaginator>;
  costCenterView?: Maybe<CostCenter>;
  costCenters?: Maybe<CostCenterPaginator>;
  customTable?: Maybe<CustomTable>;
  customTables?: Maybe<Array<CustomTable>>;
  departmentList?: Maybe<LocationDeptPaginator>;
  departmentView?: Maybe<LocationDept>;
  departmentsSelector?: Maybe<SelectOptionTypePaginator>;
  dispositionReasons?: Maybe<DispositionReasonPaginator>;
  downloadVendorContract?: Maybe<Scalars['String']>;
  /** Paginated list of excluded assets that are not yet onboarded */
  excludedAssets?: Maybe<ExcludedAssetPaginator>;
  excludedProducts?: Maybe<ExcludedProductInfoTypePaginator>;
  facilityList?: Maybe<OrganizationPaginator>;
  facilityView?: Maybe<Organization>;
  fee?: Maybe<Fee>;
  fees?: Maybe<FeePaginator>;
  /** List of categories statistics that includes count of fleet items in stages(Good, Plan and Replace) also sum of replacement cost of fleet items in stages(Good, Plan and Replace) */
  fleetCategoriesBarGraphs: Array<FleetBarGraphDataPoint>;
  /** List of counts of fleet items per age / score of the category */
  fleetCategoryLineGraph?: Maybe<FleetLineGraph>;
  /** List of departments statistics that includes count of fleet items in stages(Good, Plan and Replace) also sum of replacement cost of fleet items in stages(Good, Plan and Replace) */
  fleetDepartments: Array<EntityFleetStats>;
  /** List of facilities statistics that includes count of fleet items in stages(Good, Plan and Replace) also sum of replacement cost of fleet items in stages(Good, Plan and Replace) */
  fleetFacilities: Array<EntityFleetStats>;
  fleetItem?: Maybe<FleetItem>;
  fleetItemDetailed: DetailedFleetItemType;
  fleetItems?: Maybe<FleetItemPaginator>;
  fleetItemsDispositionList?: Maybe<FleetItemDispositionDetailRowPaginator>;
  fleetItemsDispositionStats: Array<FleetItemsDispositionStatsRow>;
  fleetItemsList?: Maybe<FleetItemDetailRowPaginator>;
  fleetItemsListByDisposition?: Maybe<FleetItemDispositionDetailRowPaginator>;
  fleetItemsListByDispositionStoreToFile: Scalars['String'];
  /** List of locations statistics that includes count of fleet items in stages(Good, Plan and Replace) also sum of replacement cost of fleet items in stages(Good, Plan and Replace) */
  fleetLocations: Array<EntityFleetStats>;
  /** List of manufacturers with fleet item count associated with it. */
  fleetManufacturers: Array<EntityFleetCount>;
  /** Fleet map locations */
  fleetMapLocations: Array<FleetMapLocation>;
  /** List of products with fleet item count associated with it. */
  fleetProducts: Array<EntityFleetCount>;
  /** Fleet total */
  fleetTotalStats?: Maybe<AssetsTotalStatsType>;
  fleetTypeFilter?: Maybe<FleetTypeOptionTypePaginator>;
  forecastBarGraph?: Maybe<Array<ForecastBarGraph>>;
  forecastStats?: Maybe<AssetsTotalStatsType>;
  generalBudgetList?: Maybe<CapitalCalendarBudgetPaginator>;
  /** General data health statatics */
  generalDataHealth: GeneralDataHealth;
  getBargraphCategoryData: OemBargraphCategoryData;
  getCachedFilters: TokenResponseType;
  getChildManufacturers?: Maybe<Array<ChildManufacturer>>;
  getChorusDeviceDetails?: Maybe<GoogleChorusDetails>;
  getMaintenanceTasks?: Maybe<Array<StrategicOpportunity>>;
  /** Count of assets associated with manufacturers. */
  getManufacturerFleets: ManufacturerFleetCount;
  getOEMTotalAssets: OemTotalAssets;
  getOemAreaChartData: OemAreaChartData;
  getOemAreaChartList?: Maybe<OemAreaChartListPaginator>;
  getOemBargraphCategoryList?: Maybe<OemRedAssetListPaginator>;
  getOemLocationList?: Maybe<LocationManagementTypePaginator>;
  getOemOrganizationList?: Maybe<OrganizationPaginator>;
  getOemRedAssetList?: Maybe<OemRedAssetListPaginator>;
  getOffensiveOpportunities?: Maybe<Array<StrategicOpportunity>>;
  getQRCode?: Maybe<Scalars['String']>;
  getSubsidiariesBargraphData: Array<ManufacturerFleetCount>;
  /** Show workflow */
  getWorkflow?: Maybe<ApprovalWorkflow>;
  getWorkflowFields: Array<ApprovalWorkflowReviewField>;
  item?: Maybe<Item>;
  items?: Maybe<ItemPaginator>;
  jobfunction?: Maybe<JobFunction>;
  jobfunctions?: Maybe<JobFunctionPaginator>;
  labelList?: Maybe<GlobalLabelPaginator>;
  labelsMerged?: Maybe<TagPaginator>;
  lifespan: Array<LifespanType>;
  listOfRequestHubWhereQuestionIsUsed?: Maybe<RequestHubPaginator>;
  listOfWorkflows?: Maybe<ApprovalWorkflowPaginator>;
  location?: Maybe<Location>;
  locationDeptFilter?: Maybe<SelectOptionTypePaginator>;
  locationFilter?: Maybe<SelectOptionTypePaginator>;
  locationList?: Maybe<LocationManagementTypePaginator>;
  locationView?: Maybe<LocationManagementType>;
  locations?: Maybe<LocationPaginator>;
  manufacturer?: Maybe<Manufacturer>;
  manufacturerFilter?: Maybe<ManufacturerOptionTypePaginator>;
  manufacturers?: Maybe<ManufacturerPaginator>;
  me?: Maybe<User>;
  myOrgCcmLitePermissions: Array<CcmLitePermission>;
  myWidgets: Array<Widget>;
  newRequestItems?: Maybe<NewRequestItemPaginator>;
  newRequestedItems?: Maybe<NewRequestedTypePaginator>;
  newRequestedItemsStoreToFile: Scalars['String'];
  oemCategoriesBarGraphs: Array<FleetBarGraphDataPoint>;
  oemRedAssetLineGraph: OemRedAssetLineGraph;
  oemRedAssetPieGraph: Array<OemRedAssetPieGraph>;
  order?: Maybe<Order>;
  orderAssemblyAssets?: Maybe<OrderAssemblyAssetsInfoPaginator>;
  orderAssemblyAssetsStoreToFile: Scalars['String'];
  orderAssemblyProducts: OrderAssemblyProducts;
  orderAssemblyRequests?: Maybe<OrderAssemblyRequestInfoPaginator>;
  orderAssemblyRequestsStoreToFile: Scalars['String'];
  orderHub: OrderHub;
  orderHubFleetItemsIdByCeNumber: Array<OrderHubFleetItemIdAndCeNumber>;
  orderHubList?: Maybe<OrderHubPaginator>;
  orderHubListStoreToFile: Scalars['String'];
  orderHubRequestorList?: Maybe<Array<OrderHub>>;
  orderHubStatus: OrderHubStatus;
  orderHubStatuses?: Maybe<OrderHubStatusPaginator>;
  orderHubSupplierList?: Maybe<OrderHubPaginator>;
  orders?: Maybe<OrderPaginator>;
  organization?: Maybe<Organization>;
  organizationCategoryComparison: Array<Maybe<CategoriesByOrganization>>;
  organizationFilter?: Maybe<SelectOptionTypePaginator>;
  organizationPermissions: Array<Permission>;
  organizationType?: Maybe<OrganizationType>;
  organizationTypes?: Maybe<OrganizationTypePaginator>;
  organizations?: Maybe<OrganizationPaginator>;
  ownAuctions?: Maybe<AuctionPaginator>;
  paymentSource?: Maybe<PaymentSource>;
  paymentSources?: Maybe<PaymentSourcePaginator>;
  permittedCategoriesTree: Array<CategoryTreeType>;
  permittedServiceLines?: Maybe<ServiceLinePaginator>;
  preBudgetRequestHubStats: PreBudgetRequestHubStats;
  product?: Maybe<Product>;
  productFilter?: Maybe<SelectOptionTypePaginator>;
  products?: Maybe<ProductPaginator>;
  productsToExclude?: Maybe<ProductPaginator>;
  projectsCompleted?: Maybe<ProjectsCompletedPaginator>;
  projectsInProgress?: Maybe<ProjectsInProgressPaginator>;
  quantityTypes?: Maybe<QuantityTypePaginator>;
  /** Get question details */
  question?: Maybe<Question>;
  /** Question type list */
  questionTypes?: Maybe<Array<QuestionType>>;
  /** Questions list */
  questions?: Maybe<QuestionPaginator>;
  regionFilter?: Maybe<SelectOptionTypePaginator>;
  regionList?: Maybe<RegionPaginator>;
  regionView?: Maybe<Region>;
  replacementReason?: Maybe<ReplacementReason>;
  replacementReasons?: Maybe<ReplacementReasonPaginator>;
  requestHub?: Maybe<RequestHub>;
  requestHubApprovals: Array<SelectOptionType>;
  requestHubControlList?: Maybe<Array<RequestHubControlList>>;
  requestHubList?: Maybe<RequestHubPaginator>;
  requestHubListStoreToFile: Scalars['String'];
  requestHubNotes?: Maybe<Array<RequestHubNotes>>;
  requestHubPriorities: Array<SelectOptionType>;
  requestHubProducts?: Maybe<Array<RequestHubProduct>>;
  requestHubSelector?: Maybe<SelectOptionTypePaginator>;
  riskByCategory: Array<RiskByCategoryType>;
  riskByManufacturer: Array<RiskByManufacturerType>;
  riskByProduct: Array<RiskByProduct>;
  role?: Maybe<Role>;
  roles?: Maybe<RolePaginator>;
  searchFleetItem?: Maybe<SelectOptionTypePaginator>;
  serviceLine?: Maybe<ServiceLine>;
  serviceLineFilter?: Maybe<SelectOptionTypePaginator>;
  serviceLineList?: Maybe<ServiceLinePaginator>;
  serviceLineView?: Maybe<ServiceLine>;
  serviceLines?: Maybe<ServiceLinePaginator>;
  spendTypeFilter?: Maybe<SpendTypeOptionTypePaginator>;
  splitEntity: Array<SplitEntityType>;
  splitType: BudgetSplitTypeEnum;
  splitTypeBudgetStats: Array<SplitTypeStats>;
  states: Array<State>;
  tag?: Maybe<Tag>;
  tagList?: Maybe<TagPaginator>;
  tagView?: Maybe<Tag>;
  tags?: Maybe<TagPaginator>;
  toBeReplacedGroups: Array<ToBeReplacedGroup>;
  toBeReplacedItems: Array<ToBeReplacedItem>;
  toBeReplacedProducts: Array<ToBeReplacedProduct>;
  totalOrders: TotalOrder;
  totalRequest: TotalRequest;
  userAddress?: Maybe<UserAddress>;
  userAddresses?: Maybe<UserAddressPaginator>;
  userList?: Maybe<UserManagementTypePaginator>;
  userStatistics: SellerStatistics;
  userView?: Maybe<UserManagementType>;
  vendorPreferences: Array<CategoryVendor>;
  winLots?: Maybe<AuctionLotPaginator>;
  workflowApproversWithStatus?: Maybe<Array<WorkflowApproversWithStatus>>;
};


export type QueryActiveBudgetNewCapitalStatsArgs = {
  budget_id: Scalars['ID'];
  filters?: InputMaybe<BudgetStatsFilter>;
};


export type QueryActiveBudgetReplacementStatsArgs = {
  budget_id: Scalars['ID'];
  filters?: InputMaybe<BudgetStatsFilter>;
};


export type QueryActiveBudgetTotalsStatsArgs = {
  budget_id: Scalars['ID'];
  filters?: InputMaybe<BudgetStatsFilter>;
};


export type QueryAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryApprovalGroupArgs = {
  id: Scalars['ID'];
};


export type QueryApprovalGroupsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryApprovalNotificationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryApprovedRequestsArgs = {
  budget_id: Scalars['ID'];
  budget_type: BudgetTypeEnum;
  id: Scalars['Int'];
  type: BudgetSplitTypeEnum;
};


export type QueryApproversFilterArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  usage: FilterUsageEnum;
};


export type QueryAssetIssueArgs = {
  id: Scalars['ID'];
};


export type QueryAssetIssuesArgs = {
  filters?: InputMaybe<DataHeathCheckFilterInput>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetsByCategoryArgs = {
  category_id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetsByFacilityArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetsByServiceLineArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetsInArgs = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
};


export type QueryAssetsMovementStatArgs = {
  filters: FleetFilterInput;
};


export type QueryAssetsOutArgs = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
};


export type QueryAssetsReplacedArgs = {
  budget_id: Scalars['ID'];
  filters?: InputMaybe<BudgetStatsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AssetsReplacedOrderInput>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryAssetsReplacedListArgs = {
  budget_id: Scalars['Int'];
  filter?: InputMaybe<AssetsReplacedFilters>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AssetsReplacedOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetsToBeReplacedArgs = {
  budget_id: Scalars['ID'];
  filters?: InputMaybe<BudgetStatsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AssetsToBeReplacedOrderInput>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryAuctionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAuctionLotArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAuctionLotsArgs = {
  auction_id: Scalars['ID'];
  ids: Array<Scalars['ID']>;
};


export type QueryAuctionsArgs = {
  filters?: InputMaybe<AuctionsFilters>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAverageAgeByCategoryArgs = {
  category_id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryAverageAgeByFleetArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryBudgetComparisonArgs = {
  id: Scalars['ID'];
  type: BudgetSplitTypeEnum;
};


export type QueryBudgetFilterArgs = {
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryBudgetGroupArgs = {
  id: Scalars['ID'];
};


export type QueryBudgetGroupsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  type: BudgetGroupTypeEnum;
};


export type QueryBudgetItemArgs = {
  id: Scalars['ID'];
  type?: BudgetTypeEnum;
};


export type QueryBudgetListArgs = {
  budget_id: Scalars['ID'];
  budget_type: BudgetTypeEnum;
  total_budget_amount: Scalars['Int'];
  type: BudgetSplitTypeEnum;
};


export type QueryBudgetPieSummaryArgs = {
  id: Scalars['ID'];
  split_type: BudgetSplitTypeEnum;
  type?: BudgetTypeEnum;
};


export type QueryBudgetReplacementItemsArgs = {
  budget_id: Scalars['ID'];
  filters?: InputMaybe<ReplacementFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReplacementOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
  type?: BudgetTypeEnum;
};


export type QueryBudgetReplacementItemsStoreToFileArgs = {
  budget_id: Scalars['ID'];
  filters?: InputMaybe<ReplacementFiltersInput>;
  orderBy?: InputMaybe<ReplacementOrderByInput>;
  type?: BudgetTypeEnum;
};


export type QueryBudgetSplitSummaryArgs = {
  id: Scalars['ID'];
  type?: BudgetTypeEnum;
};


export type QueryBudgetWidgetArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryCapitalBudgetFilterArgs = {
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  spend_types?: InputMaybe<Array<OrderHubTypeEnum>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryCapitalCalendarBudgetArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCapitalCalendarBudgetsArgs = {
  budget_name?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<BudgetStatusEnum>>;
};


export type QueryCartArgs = {
  type?: InputMaybe<CartTypeEnum>;
};


export type QueryCategoriesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  fleetTypeId?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  hasParent?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<QueryCategoriesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  showInMarketplace?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCategoryFilterArgs = {
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryCostCenterArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCostCenterFilterArgs = {
  budget_id?: InputMaybe<Scalars['ID']>;
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  spend_types?: InputMaybe<Array<OrderHubTypeEnum>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryCostCenterListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryCostCenterViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCostCentersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomTableArgs = {
  code: Scalars['String'];
};


export type QueryCustomTablesArgs = {
  filters?: InputMaybe<TableFiltersInput>;
};


export type QueryDepartmentListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryDepartmentViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDepartmentsSelectorArgs = {
  first?: InputMaybe<Scalars['Int']>;
  location_id: Scalars['ID'];
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryDispositionReasonsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryDownloadVendorContractArgs = {
  contract: Scalars['String'];
  original_name: Scalars['String'];
};


export type QueryExcludedAssetsArgs = {
  filters?: InputMaybe<DataHeathCheckFilterInput>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExcludedProductsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryFacilityListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryFacilityViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFeeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFeesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryFleetCategoriesBarGraphsArgs = {
  filters: FleetFilterInput;
};


export type QueryFleetCategoryLineGraphArgs = {
  filters: FleetFilterInput;
};


export type QueryFleetDepartmentsArgs = {
  filters: FleetFilterInput;
};


export type QueryFleetFacilitiesArgs = {
  filters: FleetFilterInput;
};


export type QueryFleetItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFleetItemDetailedArgs = {
  id: Scalars['ID'];
};


export type QueryFleetItemsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryFleetItemsDispositionListArgs = {
  filters?: InputMaybe<BudgetDispositionFleetFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FleetItemsOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryFleetItemsDispositionStatsArgs = {
  filters: FleetFilterInput;
};


export type QueryFleetItemsListArgs = {
  filters: FleetFilterInput;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FleetItemsOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryFleetItemsListByDispositionArgs = {
  filters: FleetFilterInput;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FleetItemsOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryFleetItemsListByDispositionStoreToFileArgs = {
  filters: FleetFilterInput;
  orderBy?: InputMaybe<FleetItemsOrderByInput>;
};


export type QueryFleetLocationsArgs = {
  filters: FleetFilterInput;
};


export type QueryFleetManufacturersArgs = {
  filters: FleetFilterInput;
};


export type QueryFleetMapLocationsArgs = {
  filters: FleetFilterInput;
};


export type QueryFleetProductsArgs = {
  filters: FleetFilterInput;
};


export type QueryFleetTotalStatsArgs = {
  filters: FleetFilterInput;
};


export type QueryFleetTypeFilterArgs = {
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryForecastBarGraphArgs = {
  filters: FleetFilterInput;
};


export type QueryForecastStatsArgs = {
  filters: FleetFilterInput;
};


export type QueryGeneralBudgetListArgs = {
  byStatus?: InputMaybe<BudgetStatsEnum>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBargraphCategoryDataArgs = {
  category_id: Scalars['Int'];
  filters: OemFilterInput;
};


export type QueryGetCachedFiltersArgs = {
  token: Scalars['String'];
};


export type QueryGetChildManufacturersArgs = {
  input: FleetManufacturerInput;
};


export type QueryGetChorusDeviceDetailsArgs = {
  input: GoogleChorusInput;
};


export type QueryGetMaintenanceTasksArgs = {
  filters: OemFilterInput;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryGetManufacturerFleetsArgs = {
  filters: FleetFilterInput;
  input: FleetManufacturerInput;
};


export type QueryGetOemTotalAssetsArgs = {
  filters: OemFilterInput;
};


export type QueryGetOemAreaChartDataArgs = {
  category_id: Scalars['Int'];
  filters: OemFilterInput;
};


export type QueryGetOemAreaChartListArgs = {
  asset_age_range: AssetAgeRange;
  category_id: Scalars['Int'];
  filters?: InputMaybe<OemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetOemBargraphCategoryListArgs = {
  category_id: Scalars['Int'];
  filters?: InputMaybe<OemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetOemLocationListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetOemOrganizationListArgs = {
  facility?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetOemRedAssetListArgs = {
  filters?: InputMaybe<OemFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  year: Scalars['Int'];
};


export type QueryGetOffensiveOpportunitiesArgs = {
  filters: OemFilterInput;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryGetQrCodeArgs = {
  method: TwoFaMethodEnum;
};


export type QueryGetSubsidiariesBargraphDataArgs = {
  filters: FleetFilterInput;
  input: FleetManufacturerInput;
};


export type QueryGetWorkflowArgs = {
  id: Scalars['ID'];
};


export type QueryItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryItemsArgs = {
  filters?: InputMaybe<ItemFilters>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ItemsOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryJobfunctionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryJobfunctionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryLabelListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryLabelsMergedArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryLifespanArgs = {
  category_id?: InputMaybe<Scalars['ID']>;
};


export type QueryListOfRequestHubWhereQuestionIsUsedArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  question_id: Scalars['ID'];
  withSubQuestions?: InputMaybe<Scalars['Boolean']>;
};


export type QueryListOfWorkflowsArgs = {
  filters: ApprovalWorkflowFilterInput;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  type: WorkFlowTypeEnum;
};


export type QueryLocationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLocationDeptFilterArgs = {
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryLocationFilterArgs = {
  budget_id?: InputMaybe<Scalars['ID']>;
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  spend_types?: InputMaybe<Array<OrderHubTypeEnum>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryLocationListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  organization_id?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryLocationViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLocationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryManufacturerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryManufacturerFilterArgs = {
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  topLevelOnly?: Scalars['Boolean'];
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryManufacturersArgs = {
  filter?: InputMaybe<ManufacturersFilter>;
  filters?: InputMaybe<ScopedFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  onlyWithActiveItems?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryNewRequestItemsArgs = {
  filters?: InputMaybe<RequestHubItemFilters>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<NewRequestItemInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryNewRequestedItemsArgs = {
  budget_id: Scalars['ID'];
  filters?: InputMaybe<NewRequestedFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NewRequestedOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
  type?: BudgetTypeEnum;
};


export type QueryNewRequestedItemsStoreToFileArgs = {
  budget_id: Scalars['ID'];
  filters?: InputMaybe<ReplacementFiltersInput>;
  orderBy?: InputMaybe<NewRequestedOrderByInput>;
  type?: BudgetTypeEnum;
};


export type QueryOemCategoriesBarGraphsArgs = {
  filters: OemFilterInput;
};


export type QueryOemRedAssetLineGraphArgs = {
  filters: OemFilterInput;
};


export type QueryOemRedAssetPieGraphArgs = {
  filters: OemFilterInput;
};


export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOrderAssemblyAssetsArgs = {
  filters?: InputMaybe<OrderAssemblyAssetsFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderAssemblyAssetsOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryOrderAssemblyAssetsStoreToFileArgs = {
  filters?: InputMaybe<OrderAssemblyAssetsFiltersInput>;
  orderBy?: InputMaybe<OrderAssemblyAssetsOrderByInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryOrderAssemblyProductsArgs = {
  budget_id: Scalars['ID'];
  fleet_item_ids: Array<Scalars['ID']>;
  request_hub_ids: Array<Scalars['ID']>;
};


export type QueryOrderAssemblyRequestsArgs = {
  filters?: InputMaybe<OrderAssemblyRequestFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderAssemblyRequestsOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  spending?: InputMaybe<SpendingFilter>;
};


export type QueryOrderAssemblyRequestsStoreToFileArgs = {
  filters?: InputMaybe<OrderAssemblyRequestFiltersInput>;
  orderBy?: InputMaybe<OrderAssemblyRequestsOrderByInput>;
  search?: InputMaybe<Scalars['String']>;
  spending?: InputMaybe<SpendingFilter>;
};


export type QueryOrderHubArgs = {
  id: Scalars['ID'];
};


export type QueryOrderHubFleetItemsIdByCeNumberArgs = {
  filters?: InputMaybe<OrderHubFleetItemsIdByCeNumberInput>;
};


export type QueryOrderHubListArgs = {
  filters?: InputMaybe<OrderHubFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderHubOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryOrderHubListStoreToFileArgs = {
  filters?: InputMaybe<OrderHubFilterInput>;
  orderBy?: InputMaybe<OrderHubOrderByInput>;
};


export type QueryOrderHubStatusArgs = {
  id: Scalars['ID'];
};


export type QueryOrderHubStatusesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<QueryOrderHubStatusesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryOrderHubSupplierListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryOrdersArgs = {
  filters?: InputMaybe<OrderFilters>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOrganizationFilterArgs = {
  budget_id?: InputMaybe<Scalars['ID']>;
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  spend_types?: InputMaybe<Array<OrderHubTypeEnum>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryOrganizationTypeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOrganizationTypesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganizationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  mine?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryOwnAuctionsArgs = {
  filters?: InputMaybe<AuctionsFilters>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPaymentSourceArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPaymentSourcesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPermittedCategoriesTreeArgs = {
  fleet_type?: InputMaybe<FleetTypeEnum>;
  order?: InputMaybe<SortOrder>;
};


export type QueryPermittedServiceLinesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryProductFilterArgs = {
  budget_id?: InputMaybe<Scalars['ID']>;
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryProductsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  has_fleet?: InputMaybe<Scalars['Boolean']>;
  manufacturer_id?: InputMaybe<Scalars['ID']>;
  mpn?: InputMaybe<Scalars['String']>;
  mpn_search?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryProductsToExcludeArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryProjectsCompletedArgs = {
  budget_id: Scalars['ID'];
  filters?: InputMaybe<BudgetStatsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProjectsCompletedOrderInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryProjectsInProgressArgs = {
  budget_id: Scalars['ID'];
  filters?: InputMaybe<BudgetStatsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProjectsInProgressOrderInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryQuantityTypesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryQuestionArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionsArgs = {
  entity_type: EntityTypeEnum;
  field_types?: Array<QuestionTypeEnum>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  trashed?: InputMaybe<Trashed>;
};


export type QueryRegionFilterArgs = {
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  spend_types?: InputMaybe<Array<OrderHubTypeEnum>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryRegionListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryRegionViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryReplacementReasonArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryReplacementReasonsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRequestHubArgs = {
  id: Scalars['ID'];
};


export type QueryRequestHubListArgs = {
  filters?: InputMaybe<RequestHubsFilters>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RequestHubsOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRequestHubListStoreToFileArgs = {
  filters?: InputMaybe<RequestHubsFilters>;
};


export type QueryRequestHubNotesArgs = {
  request_hub_id: Scalars['ID'];
};


export type QueryRequestHubProductsArgs = {
  id: Scalars['ID'];
};


export type QueryRequestHubSelectorArgs = {
  budget_id: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryRiskByCategoryArgs = {
  input?: InputMaybe<RiskByCategoryInput>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryRiskByManufacturerArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryRiskByProductArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRolesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchFleetItemArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
};


export type QueryServiceLineArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryServiceLineFilterArgs = {
  budget_id?: InputMaybe<Scalars['ID']>;
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  department_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  order?: InputMaybe<SortOrder>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  spend_types?: InputMaybe<Array<OrderHubTypeEnum>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QueryServiceLineListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryServiceLineViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryServiceLinesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySpendTypeFilterArgs = {
  budget_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  region_ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  usage?: InputMaybe<FilterUsageEnum>;
};


export type QuerySplitEntityArgs = {
  budget_id: Scalars['ID'];
};


export type QuerySplitTypeBudgetStatsArgs = {
  budget_id: Scalars['ID'];
};


export type QueryStatesArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type QueryTagArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTagListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TagTypeEnum>;
};


export type QueryTagViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTagsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryToBeReplacedGroupsArgs = {
  budget_id: Scalars['ID'];
  group_by?: AssetsToBeReplacedGroupByEnum;
};


export type QueryToBeReplacedItemsArgs = {
  budget_id: Scalars['ID'];
  product_id: Scalars['ID'];
};


export type QueryToBeReplacedProductsArgs = {
  budget_id: Scalars['ID'];
  group_by?: AssetsToBeReplacedGroupByEnum;
  group_id: Scalars['ID'];
};


export type QueryUserAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUserAddressesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryUserListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryUserStatisticsArgs = {
  filters?: InputMaybe<SellerStatisticsInput>;
};


export type QueryUserViewArgs = {
  id: Scalars['ID'];
};


export type QueryVendorPreferencesArgs = {
  category_id?: InputMaybe<Scalars['ID']>;
};


export type QueryWinLotsArgs = {
  filters?: InputMaybe<AuctionLotFilters>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryWorkflowApproversWithStatusArgs = {
  id: Scalars['ID'];
};

/** Allowed column names for Query.categories.orderBy. */
export enum QueryCategoriesOrderByColumn {
  Name = 'NAME'
}

/** Order by clause for Query.categories.orderBy. */
export type QueryCategoriesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryCategoriesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.orderHubStatuses.orderBy. */
export enum QueryOrderHubStatusesOrderByColumn {
  Id = 'ID',
  SortOrder = 'SORT_ORDER'
}

/** Order by clause for Query.orderHubStatuses.orderBy. */
export type QueryOrderHubStatusesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryOrderHubStatusesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type Question = {
  __typename?: 'Question';
  edit_restarts_workflow: Scalars['Boolean'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Question>;
  priority?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
  show?: Maybe<Scalars['Boolean']>;
  subQuestions?: Maybe<Array<Question>>;
  type?: Maybe<QuestionTypeEnum>;
};

export type QuestionInput = {
  edit_restarts_workflow?: InputMaybe<Scalars['Boolean']>;
  entity_type: EntityTypeEnum;
  field_type: QuestionTypeEnum;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  required?: InputMaybe<Scalars['Boolean']>;
  restart_workflow?: InputMaybe<Scalars['Boolean']>;
  show?: InputMaybe<Scalars['Boolean']>;
  sub_questions?: InputMaybe<Array<QuestionInput>>;
};

/** A paginated list of Question items. */
export type QuestionPaginator = {
  __typename?: 'QuestionPaginator';
  /** A list of Question items. */
  data: Array<Question>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type QuestionPriorityInput = {
  question_id: Scalars['ID'];
  serial_number: Scalars['Int'];
};

export type QuestionType = {
  __typename?: 'QuestionType';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum QuestionTypeEnum {
  Currency = 'CURRENCY',
  Date = 'DATE',
  Dropdown = 'DROPDOWN',
  File = 'FILE',
  LongText = 'LONG_TEXT',
  Numeric = 'NUMERIC',
  Option = 'OPTION',
  ShortText = 'SHORT_TEXT'
}

export type ReferralType = {
  __typename?: 'ReferralType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type RefreshTokenInput = {
  refresh_token?: InputMaybe<Scalars['String']>;
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  access_token: Scalars['String'];
  expires_in: Scalars['Int'];
  refresh_token: Scalars['String'];
  token_type: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  regionName: RegionName;
};

export type RegionInput = {
  name: Scalars['String'];
};

export type RegionName = {
  __typename?: 'RegionName';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organization: Organization;
};

/** A paginated list of Region items. */
export type RegionPaginator = {
  __typename?: 'RegionPaginator';
  /** A list of Region items. */
  data: Array<Region>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type RegisterInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  mobile?: InputMaybe<Scalars['String']>;
  organization: Scalars['String'];
  organization_type_id: Scalars['Int'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  seller: Scalars['Boolean'];
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  status: RegisterStatuses;
};

export enum RegisterStatuses {
  AlreadyVerified = 'ALREADY_VERIFIED',
  MustVerifyEmail = 'MUST_VERIFY_EMAIL',
  Success = 'SUCCESS'
}

export type RemoveInput = {
  filters?: InputMaybe<Array<FiltersInput>>;
  type?: InputMaybe<AddTypes>;
  values?: InputMaybe<Array<Scalars['ID']>>;
};

export type ReplacementFiltersInput = {
  category_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  fleet_type?: InputMaybe<FleetTypeEnum>;
  location_ids?: InputMaybe<Array<Scalars['ID']>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
  red_items_only?: Scalars['Boolean'];
  replacement_cost_range?: InputMaybe<MaxMinRange>;
  search?: InputMaybe<Scalars['String']>;
  select_option?: AssetSelectionSelectOptionsEnum;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  tag_handler_ids?: InputMaybe<TagHandlerInput>;
};

export type ReplacementItem = {
  __typename?: 'ReplacementItem';
  age?: Maybe<Scalars['Int']>;
  average_score?: Maybe<Scalars['Int']>;
  budget_id?: Maybe<Scalars['ID']>;
  budget_name?: Maybe<Scalars['String']>;
  budget_split_type?: Maybe<BudgetSplitTypeEnum>;
  budget_status?: Maybe<BudgetStatusEnum>;
  category?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['ID']>;
  ce_number?: Maybe<Scalars['String']>;
  construction_cost?: Maybe<Scalars['Float']>;
  cost_center?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  department?: Maybe<Scalars['String']>;
  disposed_notes?: Maybe<Scalars['String']>;
  disposition_date?: Maybe<Scalars['Date']>;
  disposition_id?: Maybe<Scalars['ID']>;
  facility?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  it_cost?: Maybe<Scalars['Float']>;
  item_order_status?: Maybe<Scalars['String']>;
  items_count?: Maybe<Scalars['Int']>;
  labels?: Maybe<Array<Tag>>;
  location?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model_budget_id?: Maybe<Scalars['ID']>;
  mpn?: Maybe<Scalars['String']>;
  order_exists?: Maybe<Scalars['Boolean']>;
  order_id?: Maybe<Scalars['ID']>;
  order_name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['ID']>;
  other_cost?: Maybe<Scalars['Float']>;
  parent_category?: Maybe<Scalars['String']>;
  parent_category_id?: Maybe<Scalars['ID']>;
  product?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['ID']>;
  product_name?: Maybe<Scalars['String']>;
  purchase_date?: Maybe<Scalars['Date']>;
  purchase_price?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reason_for_disposition?: Maybe<Scalars['String']>;
  replacement_cost?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Int']>;
  selected?: Maybe<Scalars['Boolean']>;
  serial_number?: Maybe<Scalars['String']>;
  service_line?: Maybe<Scalars['String']>;
  service_line_id?: Maybe<Scalars['ID']>;
  stage?: Maybe<Scalars['String']>;
  sum?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Tag>>;
  useful_life?: Maybe<Scalars['String']>;
};

export enum ReplacementOrderByEnum {
  Age = 'AGE',
  AssetTag = 'ASSET_TAG',
  BudgetName = 'BUDGET_NAME',
  CapitalNumber = 'CAPITAL_NUMBER',
  CeNumber = 'CE_NUMBER',
  CostCenterId = 'COST_CENTER_ID',
  FloorId = 'FLOOR_ID',
  Location = 'LOCATION',
  LocationRoom = 'LOCATION_ROOM',
  Manufacturer = 'MANUFACTURER',
  Organization = 'ORGANIZATION',
  ProductName = 'PRODUCT_NAME',
  PurchaseDate = 'PURCHASE_DATE',
  PurchasePrice = 'PURCHASE_PRICE',
  Quarter = 'QUARTER',
  ReplacementCost = 'REPLACEMENT_COST',
  Score = 'SCORE',
  SerialNumber = 'SERIAL_NUMBER',
  ServiceLineId = 'SERVICE_LINE_ID',
  Tags = 'TAGS',
  UsefulLife = 'USEFUL_LIFE'
}

export type ReplacementOrderByInput = {
  column?: InputMaybe<ReplacementOrderByEnum>;
  order?: InputMaybe<SortOrder>;
};

export type ReplacementReason = {
  __typename?: 'ReplacementReason';
  code: Scalars['String'];
  id: Scalars['ID'];
  reason: Scalars['String'];
};

/** A paginated list of ReplacementReason items. */
export type ReplacementReasonPaginator = {
  __typename?: 'ReplacementReasonPaginator';
  /** A list of ReplacementReason items. */
  data: Array<ReplacementReason>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type RequestHub = {
  __typename?: 'RequestHub';
  actual_cost?: Maybe<Scalars['Float']>;
  answers?: Maybe<Array<Answer>>;
  approvalWorkflowObject?: Maybe<ApprovalWorkflowObject>;
  approval_groups?: Maybe<Array<MemberApprovalGroup>>;
  approvals: Array<ApprovalOption>;
  budget?: Maybe<CapitalCalendarBudget>;
  budget_id?: Maybe<Scalars['ID']>;
  can_edit?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Category>;
  category_id?: Maybe<Scalars['ID']>;
  comments: Array<RequestHubComment>;
  construction_cost?: Maybe<Scalars['Float']>;
  costCenter?: Maybe<CostCenter>;
  cost_center_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  equipment_cost?: Maybe<Scalars['Float']>;
  facility?: Maybe<Organization>;
  facility_id?: Maybe<Scalars['ID']>;
  files: Array<File>;
  fleetItemsCount?: Maybe<Scalars['Int']>;
  form_request?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  it_cost?: Maybe<Scalars['Float']>;
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  notifications: Array<ApprovalNotification>;
  order?: Maybe<OrderHub>;
  organization_id: Scalars['ID'];
  other_cost?: Maybe<Scalars['Float']>;
  owningServiceLine?: Maybe<ServiceLine>;
  owning_service_line_id?: Maybe<Scalars['ID']>;
  priority?: Maybe<Priority>;
  priority_id: Scalars['ID'];
  questions: Array<Question>;
  requestHubProductReplaceItems?: Maybe<Array<RequestHubProductReplaceItem>>;
  requestHubProducts?: Maybe<Array<RequestHubProduct>>;
  reviewing_fields?: Maybe<ReviewingFields>;
  roi?: Maybe<Scalars['Float']>;
  serviceLine?: Maybe<ServiceLine>;
  service_line_id?: Maybe<Scalars['ID']>;
  service_line_permission?: Maybe<Scalars['String']>;
  spent?: Maybe<Scalars['Int']>;
  start_month: Scalars['Int'];
  start_year: Scalars['Int'];
  subCategory?: Maybe<Category>;
  sub_category_id?: Maybe<Scalars['ID']>;
  total_cost?: Maybe<Scalars['Float']>;
  unique_identifier?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['ID']>;
};

export enum RequestHubAdditionalFilterInput {
  MeAsOwner = 'ME_AS_OWNER',
  NeedMyApproval = 'NEED_MY_APPROVAL'
}

export type RequestHubComment = {
  __typename?: 'RequestHubComment';
  comment: Scalars['String'];
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  requestHub: RequestHub;
  updated_at: Scalars['DateTime'];
  user: User;
};

export type RequestHubItemFilters = {
  budget?: InputMaybe<Scalars['ID']>;
  budget_id?: InputMaybe<Scalars['ID']>;
  category_id?: InputMaybe<Scalars['ID']>;
  cost_center_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  model_budget?: InputMaybe<Scalars['ID']>;
  model_budget_id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['ID']>;
  service_line_id?: InputMaybe<Scalars['ID']>;
};

export type RequestHubItemInput = {
  approval_option_ids: Array<Scalars['ID']>;
  budget_id?: InputMaybe<Scalars['ID']>;
  category_id?: InputMaybe<Scalars['ID']>;
  construction_cost?: InputMaybe<Scalars['Float']>;
  cost_center_id?: InputMaybe<Scalars['ID']>;
  description: Scalars['String'];
  equipment_cost?: InputMaybe<Scalars['Float']>;
  facility_id?: InputMaybe<Scalars['ID']>;
  files: Array<Scalars['ID']>;
  it_cost?: InputMaybe<Scalars['Float']>;
  location_id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  notes?: InputMaybe<Array<RequestHubNote>>;
  other_cost?: InputMaybe<Scalars['Float']>;
  owning_service_line_id?: InputMaybe<Scalars['ID']>;
  priority_id: Scalars['ID'];
  products?: InputMaybe<Array<RequestHubProductInput>>;
  question_answers?: InputMaybe<Array<RequestHubQuestionAnswer>>;
  roi?: InputMaybe<Scalars['Float']>;
  service_line_id?: InputMaybe<Scalars['ID']>;
  start_month: Scalars['Int'];
  start_year: Scalars['Int'];
  sub_category_id?: InputMaybe<Scalars['ID']>;
};

export type RequestHubNote = {
  id?: InputMaybe<Scalars['ID']>;
  note: Scalars['String'];
  parent_id?: InputMaybe<Scalars['ID']>;
  reply?: InputMaybe<Array<RequestHubNote>>;
};

export type RequestHubNotes = {
  __typename?: 'RequestHubNotes';
  id: Scalars['ID'];
  notes: Scalars['String'];
  parent_id?: Maybe<Scalars['ID']>;
  reply?: Maybe<Array<RequestHubNotes>>;
};

/** A paginated list of RequestHub items. */
export type RequestHubPaginator = {
  __typename?: 'RequestHubPaginator';
  /** A list of RequestHub items. */
  data: Array<RequestHub>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type RequestHubProduct = {
  __typename?: 'RequestHubProduct';
  contingency?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  manufacturer?: Maybe<Manufacturer>;
  name: Scalars['String'];
  new_catalog?: Maybe<Scalars['String']>;
  new_manufacturer?: Maybe<Scalars['String']>;
  new_mpn?: Maybe<Scalars['String']>;
  new_product?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  quantity?: Maybe<Scalars['Int']>;
  replaceItems?: Maybe<Array<RequestHubProductReplaceItem>>;
  replacementCount?: Maybe<Scalars['Int']>;
  requestHub?: Maybe<RequestHub>;
  spending_new_cost?: Maybe<Scalars['Float']>;
  spending_new_count?: Maybe<Scalars['Int']>;
  spending_replacement_cost?: Maybe<Scalars['Float']>;
  spending_replacement_count?: Maybe<Scalars['Int']>;
};

export type RequestHubProductInput = {
  contingency?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  manufacturer_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  new_catalog?: InputMaybe<Scalars['String']>;
  new_manufacturer?: InputMaybe<Scalars['String']>;
  new_mpn?: InputMaybe<Scalars['String']>;
  new_product?: InputMaybe<Scalars['String']>;
  price: Scalars['Float'];
  product_id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  replacement_items?: InputMaybe<Array<Scalars['ID']>>;
};

export type RequestHubProductReplaceItem = {
  __typename?: 'RequestHubProductReplaceItem';
  fleetItem?: Maybe<FleetItem>;
  id: Scalars['ID'];
  requestHub?: Maybe<RequestHub>;
  requestHubProduct?: Maybe<RequestHubProduct>;
};

export type RequestHubQuestionAnswer = {
  answer?: InputMaybe<Scalars['String']>;
  question_id: Scalars['ID'];
  sub_questions?: InputMaybe<Array<RequestHubQuestionAnswer>>;
};

export enum RequestHubSpendingEnum {
  NewCapital = 'NEW_CAPITAL',
  Replacement = 'REPLACEMENT'
}

export type RequestHubsFilters = {
  additional_filter?: InputMaybe<RequestHubAdditionalFilterInput>;
  approval_status?: InputMaybe<ApprovalWorkFlowObjectStateEnum>;
  approval_workflow_group_ids?: InputMaybe<Array<Scalars['ID']>>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  custom_question_answers?: InputMaybe<Array<AnswerInput>>;
  manufacturer_ids?: InputMaybe<Array<Scalars['ID']>>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  region_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  searchString?: InputMaybe<Scalars['String']>;
  service_line_ids?: InputMaybe<Array<Scalars['ID']>>;
  spending_type?: InputMaybe<SpendingFilter>;
};

export enum RequestHubsOrderByColumnEnum {
  Category = 'category',
  CostCenter = 'cost_center',
  EquipmentCost = 'equipment_cost',
  Facility = 'facility',
  Name = 'name',
  Priority = 'priority',
  ServiceLine = 'service_line',
  UniqueIdentifier = 'unique_identifier'
}

export type RequestHubsOrderByInput = {
  column: RequestHubsOrderByColumnEnum;
  order?: InputMaybe<SortOrder>;
};

export type RequestProduct = {
  __typename?: 'RequestProduct';
  id: Scalars['ID'];
  manufacturer_name: Scalars['String'];
  mpn: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  product_name: Scalars['String'];
  requested_by?: Maybe<User>;
  status: RequestedProductEnum;
};

export type RequestProductInput = {
  description?: InputMaybe<Scalars['String']>;
  manufacturer_name: Scalars['String'];
  mpn: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  product_name: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type RequestWidgetInput = {
  about_widget: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  mobile?: InputMaybe<Scalars['String']>;
};

/** Allows to determine from which application a request was made or what application should receive a response */
export enum RequestedProductEnum {
  /** Product requested to be added approved */
  Approved = 'APPROVED',
  /** Product requested to be added rejected */
  Rejected = 'REJECTED',
  /** Product requested to be added */
  Requested = 'REQUESTED'
}

export type RevenueType = {
  __typename?: 'RevenueType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum ReviewingFieldEnum {
  ConstructionCost = 'CONSTRUCTION_COST',
  EquipmentCost = 'EQUIPMENT_COST',
  ItCost = 'IT_COST',
  OtherCost = 'OTHER_COST'
}

export type RiskByCategoryInput = {
  category_id?: InputMaybe<Scalars['ID']>;
  column?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type RiskByCategoryType = {
  __typename?: 'RiskByCategoryType';
  avg_score: Scalars['Float'];
  count: Scalars['Int'];
  has_children?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  total_replacement_cost: Scalars['Float'];
};

export type RiskByManufacturerType = {
  __typename?: 'RiskByManufacturerType';
  avg_score: Scalars['Float'];
  id: Scalars['ID'];
  manufacturer: Scalars['String'];
  red_count: Scalars['Int'];
  red_replacement_cost: Scalars['Float'];
};

export type RiskByProduct = {
  __typename?: 'RiskByProduct';
  avg_score: Scalars['Float'];
  count: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['ID'];
  manufacturer: Scalars['String'];
  manufacturer_id: Scalars['ID'];
  mpn: Scalars['String'];
  name: Scalars['String'];
  red_count: Scalars['Int'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<Role>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
};

/** A paginated list of Role items. */
export type RolePaginator = {
  __typename?: 'RolePaginator';
  /** A list of Role items. */
  data: Array<Role>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** The available SQL operators that are used to filter query results. */
export enum SqlOperator {
  /** Whether a value is within a range of values (`BETWEEN`) */
  Between = 'BETWEEN',
  /** Equal operator (`=`) */
  Eq = 'EQ',
  /** Greater than operator (`>`) */
  Gt = 'GT',
  /** Greater than or equal operator (`>=`) */
  Gte = 'GTE',
  /** Whether a value is within a set of values (`IN`) */
  In = 'IN',
  /** Whether a value is not null (`IS NOT NULL`) */
  IsNotNull = 'IS_NOT_NULL',
  /** Whether a value is null (`IS NULL`) */
  IsNull = 'IS_NULL',
  /** Simple pattern matching (`LIKE`) */
  Like = 'LIKE',
  /** Less than operator (`<`) */
  Lt = 'LT',
  /** Less than or equal operator (`<=`) */
  Lte = 'LTE',
  /** Not equal operator (`!=`) */
  Neq = 'NEQ',
  /** Whether a value is not within a range of values (`NOT BETWEEN`) */
  NotBetween = 'NOT_BETWEEN',
  /** Whether a value is not within a set of values (`NOT IN`) */
  NotIn = 'NOT_IN',
  /** Negation of simple pattern matching (`NOT LIKE`) */
  NotLike = 'NOT_LIKE'
}

export type ScopedFiltersInput = {
  orderByName?: InputMaybe<OrderBy>;
};

export type ScoreData = {
  __typename?: 'ScoreData';
  avg_score_of_assets_replaced: Scalars['Float'];
  new_score: Scalars['Float'];
  starting_point: Scalars['Float'];
};

export type SelectOptionType = {
  __typename?: 'SelectOptionType';
  key: Scalars['ID'];
  title: Scalars['String'];
};

/** A paginated list of SelectOptionType items. */
export type SelectOptionTypePaginator = {
  __typename?: 'SelectOptionTypePaginator';
  /** A list of SelectOptionType items. */
  data: Array<SelectOptionType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type SellerStatistics = {
  __typename?: 'SellerStatistics';
  listedGraphData?: Maybe<Array<Maybe<OrganizationListedStatisticGraphData>>>;
  listedTotalAmount?: Maybe<Scalars['Float']>;
  purchasedGraphData?: Maybe<Array<Maybe<OrganizationPurchasedStatisticGraphData>>>;
  purchasedTotalAmount?: Maybe<Scalars['Float']>;
  soldGraphData?: Maybe<Array<Maybe<OrganizationSoldStatisticGraphData>>>;
  soldTotalAmount?: Maybe<Scalars['Float']>;
};

export type SellerStatisticsInput = {
  date_from?: InputMaybe<Scalars['Date']>;
  date_to?: InputMaybe<Scalars['Date']>;
  organization_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  view_by?: InputMaybe<SellerStatisticsViewEnum>;
};

export enum SellerStatisticsViewEnum {
  ByDay = 'BY_DAY',
  ByMonth = 'BY_MONTH',
  ByQuarter = 'BY_QUARTER',
  ByWeek = 'BY_WEEK'
}

export type SellerType = {
  __typename?: 'SellerType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ServiceLine = {
  __typename?: 'ServiceLine';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  pivot?: Maybe<ServiceLinePivot>;
};

export type ServiceLineInput = {
  name: Scalars['String'];
};

/** A paginated list of ServiceLine items. */
export type ServiceLinePaginator = {
  __typename?: 'ServiceLinePaginator';
  /** A list of ServiceLine items. */
  data: Array<ServiceLine>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ServiceLinePermissionInput = {
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type ServiceLinePivot = {
  __typename?: 'ServiceLinePivot';
  entity_id: Scalars['ID'];
  entity_type?: Maybe<Scalars['String']>;
  service_line_permission?: Maybe<Scalars['String']>;
  user_id: Scalars['ID'];
};

export type ShipmentType = {
  __typename?: 'ShipmentType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type SpendTypeOptionType = {
  __typename?: 'SpendTypeOptionType';
  key: OrderHubTypeEnum;
  title: Scalars['String'];
};

/** A paginated list of SpendTypeOptionType items. */
export type SpendTypeOptionTypePaginator = {
  __typename?: 'SpendTypeOptionTypePaginator';
  /** A list of SpendTypeOptionType items. */
  data: Array<SpendTypeOptionType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum SpendingFilter {
  New = 'NEW',
  Replacement = 'REPLACEMENT'
}

export type SplitEntityType = {
  __typename?: 'SplitEntityType';
  id: Scalars['ID'];
  name: Scalars['String'];
  replacement_budget: Scalars['Float'];
  replacement_capital_spend_sum: Scalars['Float'];
};

export type SplitTypeStats = {
  __typename?: 'SplitTypeStats';
  amount: Scalars['Float'];
  contingency: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
  new_spent: Scalars['Float'];
  new_spent_paid: Scalars['Float'];
  replacement_spent: Scalars['Float'];
  replacement_spent_paid: Scalars['Float'];
};

export type SplitTypesInput = {
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  locked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum StageOfLifeEnum {
  Good = 'GOOD',
  Plan = 'PLAN',
  Replace = 'REPLACE'
}

export type State = {
  __typename?: 'State';
  abbreviation: Scalars['String'];
  name: Scalars['String'];
};

export type StrategicOpportunity = {
  __typename?: 'StrategicOpportunity';
  category_name?: Maybe<Scalars['String']>;
  my_assets_count?: Maybe<Scalars['Int']>;
  replace_assets_count?: Maybe<Scalars['Int']>;
  top_competitor_count?: Maybe<Scalars['Int']>;
  top_competitor_name?: Maybe<Scalars['String']>;
  top_competitor_replace_count?: Maybe<Scalars['Int']>;
  total_assets_count?: Maybe<Scalars['Int']>;
};

export type SubscribeToCategoriesInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  cartUpdated?: Maybe<Cart>;
  newOutbid?: Maybe<Bid>;
};


export type SubscriptionCartUpdatedArgs = {
  cartId?: InputMaybe<Scalars['ID']>;
};

export type TableFiltersInput = {
  table_name: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  display_color: Scalars['String'];
  fleet_items_count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TagHandlerInput = {
  label_ids?: InputMaybe<Array<Scalars['ID']>>;
  order_status_ids?: InputMaybe<Array<Scalars['ID']>>;
  tag_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type TagInput = {
  display_color: Scalars['String'];
  name: Scalars['String'];
  type?: InputMaybe<TagTypeEnum>;
};

/** A paginated list of Tag items. */
export type TagPaginator = {
  __typename?: 'TagPaginator';
  /** A list of Tag items. */
  data: Array<Tag>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum TagTypeEnum {
  CapitalCalendarBudget = 'CAPITAL_CALENDAR_BUDGET',
  RequestHub = 'REQUEST_HUB'
}

export type TempFile = {
  __typename?: 'TempFile';
  created_at: Scalars['DateTime'];
  file_name: Scalars['String'];
  id: Scalars['ID'];
  original_file_name?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  url: Scalars['String'];
  user?: Maybe<User>;
};

export type Theme = {
  __typename?: 'Theme';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type ToBeReplaced = {
  __typename?: 'ToBeReplaced';
  ce_number: Scalars['String'];
  id: Scalars['ID'];
  manufacturer: Scalars['String'];
  organization: Scalars['String'];
  product: Scalars['String'];
  quarter: Scalars['String'];
  service_line: Scalars['String'];
};

export type ToBeReplacedGroup = {
  __typename?: 'ToBeReplacedGroup';
  group_id: Scalars['ID'];
  group_name: Scalars['String'];
  products_count: Scalars['Int'];
};

export type ToBeReplacedItem = {
  __typename?: 'ToBeReplacedItem';
  ce_number?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  product_description?: Maybe<Scalars['String']>;
};

/** A paginated list of ToBeReplaced items. */
export type ToBeReplacedPaginator = {
  __typename?: 'ToBeReplacedPaginator';
  /** A list of ToBeReplaced items. */
  data: Array<ToBeReplaced>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ToBeReplacedProduct = {
  __typename?: 'ToBeReplacedProduct';
  location?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  product_description?: Maybe<Scalars['String']>;
  product_id: Scalars['ID'];
  products_count?: Maybe<Scalars['Int']>;
  service_line?: Maybe<Scalars['String']>;
};

export type TokenResponseType = {
  __typename?: 'TokenResponseType';
  params: Scalars['JSON'];
  token: Scalars['String'];
};

export type TotalOrder = {
  __typename?: 'TotalOrder';
  current_month_order: Scalars['Int'];
  current_month_sum: Scalars['Float'];
  on_hold: Scalars['Int'];
  order_completed: Scalars['Int'];
  order_placed: Scalars['Int'];
  ready_to_order: Scalars['Int'];
  total_order: Scalars['Int'];
  total_order_sum: Scalars['Float'];
  waiting_for_approval: Scalars['Int'];
};

export type TotalRequest = {
  __typename?: 'TotalRequest';
  current_month_request: Scalars['Int'];
  current_month_sum: Scalars['Float'];
  total_request: Scalars['Int'];
  total_request_sum: Scalars['Float'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type TriageType = {
  __typename?: 'TriageType';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  organization_value: Scalars['Int'];
};

export enum TwoFaMethodEnum {
  Google = 'GOOGLE',
  Microsoft = 'MICROSOFT',
  Sms = 'SMS'
}

export type TwoFactorOrganizationAdminUpdateInput = {
  methods: Array<TwoFaMethodEnum>;
  two_factor: Scalars['Boolean'];
};

export type TwoFactorUpdateInput = {
  method: TwoFaMethodEnum;
  mobile?: InputMaybe<Scalars['String']>;
  two_factor?: InputMaybe<Scalars['Boolean']>;
};

export type UnionOrderHubRelation = FleetItem | RequestHub;

export type UnionOrderHubRelationOverride = FleetItem | RequestHubProduct | RequestHubProductReplaceItem;

export type UpdateAddressAddressInput = {
  addr1: Scalars['String'];
  addr2?: InputMaybe<Scalars['String']>;
  attention_to?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country_id: Scalars['ID'];
  postal_code?: InputMaybe<Scalars['String']>;
  state_or_province?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type UpdateApprovalWorkflowApprovals = {
  approval_type?: InputMaybe<Scalars['String']>;
  group_id: Scalars['ID'];
  group_sort_order?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  is_current_approver?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  workflow_object_id: Scalars['ID'];
};

export type UpdateApprovalsByAdmin = {
  id: Scalars['ID'];
  state: ApprovalWorkFlowObjectStateEnum;
};

export type UpdateBudgetInput = {
  amount?: InputMaybe<Scalars['Float']>;
  conditions?: InputMaybe<ConditionsInput>;
  data?: InputMaybe<Array<InputMaybe<SplitTypesInput>>>;
  end_date?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  split_type?: InputMaybe<BudgetSplitTypeEnum>;
  split_type_id?: InputMaybe<Scalars['ID']>;
  split_type_lock?: InputMaybe<Scalars['Boolean']>;
  start_date?: InputMaybe<Scalars['Date']>;
  status: BudgetStatusEnum;
};

export type UpdateDataHealthParameterInput = {
  /** Id of the asset */
  id: Scalars['ID'];
  /** Purchase date to be updated */
  purchaseDate?: InputMaybe<Scalars['String']>;
  /** Purchase price to be updated in fleet item and assets */
  purchasePrice?: InputMaybe<Scalars['Float']>;
};

export type UpdateItemDeleteInput = {
  documents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  images?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UpdateItemRequest = {
  condition: ConditionEnum;
  delete?: InputMaybe<UpdateItemDeleteInput>;
  documents?: InputMaybe<Array<InputMaybe<ItemDocumentsRequest>>>;
  expiration_data?: InputMaybe<Scalars['Date']>;
  freight_shipping?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  images?: InputMaybe<Array<InputMaybe<ItemImagesRequest>>>;
  lot_number?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  product_id: Scalars['ID'];
  quantity: ItemQuantityRequest;
  rx_only: Scalars['Boolean'];
  sell_price?: InputMaybe<Scalars['Float']>;
  warranty?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderAssemblyInput = {
  cap_req_number?: InputMaybe<Scalars['String']>;
  cost_center_ids?: InputMaybe<Array<Scalars['ID']>>;
  files?: InputMaybe<UpdateRelatedItemsInput>;
  is_paid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  po_number?: InputMaybe<Scalars['String']>;
  relations?: InputMaybe<Array<InputMaybe<UpdateOrderAssemblyRelationInput>>>;
  supplier?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderAssemblyRelationInput = {
  cost: Scalars['Float'];
  relation_id: Scalars['ID'];
};

export type UpdatePassword = {
  old_password: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type UpdateRelatedItemsInput = {
  attach?: InputMaybe<Array<Scalars['ID']>>;
  detach?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateReletedItems = {
  cost?: InputMaybe<Scalars['Float']>;
  relation_id: Scalars['ID'];
};

export type UpdateRequestHubItemInput = {
  approval_option_ids?: InputMaybe<Array<Scalars['ID']>>;
  budget_id?: InputMaybe<Scalars['ID']>;
  category_id?: InputMaybe<Scalars['ID']>;
  construction_cost?: InputMaybe<Scalars['Float']>;
  cost_center_id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  equipment_cost?: InputMaybe<Scalars['Float']>;
  facility_id?: InputMaybe<Scalars['ID']>;
  files?: InputMaybe<UpdateRelatedItemsInput>;
  it_cost?: InputMaybe<Scalars['Float']>;
  location_id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  other_cost?: InputMaybe<Scalars['Float']>;
  owning_service_line_id?: InputMaybe<Scalars['ID']>;
  priority_id?: InputMaybe<Scalars['ID']>;
  products?: InputMaybe<Array<RequestHubProductInput>>;
  question_answers?: InputMaybe<Array<RequestHubQuestionAnswer>>;
  roi?: InputMaybe<Scalars['Float']>;
  service_line_id?: InputMaybe<Scalars['ID']>;
  start_month?: InputMaybe<Scalars['Int']>;
  start_year?: InputMaybe<Scalars['Int']>;
  sub_category_id?: InputMaybe<Scalars['ID']>;
};

export type UpdateUserAddressInput = {
  active: Scalars['Boolean'];
  address: UpdateAddressAddressInput;
  is_primary: Scalars['Boolean'];
  type?: InputMaybe<UserAddressTypeEnum>;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean'];
  admin?: Maybe<Scalars['Boolean']>;
  approvalGroups: Array<ApprovalWorkflowGroup>;
  auction_status?: Maybe<AuctionStatusEnum>;
  auctions?: Maybe<Array<Maybe<Auction>>>;
  categoryPermissions: Array<Category>;
  checkAssetManagementAccess?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  features?: Maybe<Array<Maybe<FeaturesEnum>>>;
  first_name: Scalars['String'];
  fleetTypePermissions: Array<Category>;
  id: Scalars['ID'];
  jobFunction?: Maybe<JobFunction>;
  last_login_date: Scalars['String'];
  last_name: Scalars['String'];
  mobile?: Maybe<Scalars['String']>;
  notification_type_id?: Maybe<Scalars['ID']>;
  notificationsCategories?: Maybe<Array<Maybe<Category>>>;
  office?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['ID']>;
  organization_two_factor?: Maybe<Scalars['Boolean']>;
  organization_two_factor_methods?: Maybe<Array<TwoFaMethodEnum>>;
  permissions: Array<Permission>;
  receive_notifications?: Maybe<Scalars['Boolean']>;
  remember_token?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  serviceLinePermissions: Array<ServiceLine>;
  tables: Array<CustomTable>;
  title?: Maybe<Scalars['String']>;
  two_factor?: Maybe<Scalars['Boolean']>;
  two_factor_method?: Maybe<TwoFaMethodEnum>;
  two_factor_setup?: Maybe<Scalars['Boolean']>;
  userAddress?: Maybe<Array<Maybe<UserAddress>>>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  active: Scalars['Boolean'];
  address: Address;
  id: Scalars['ID'];
  is_primary: Scalars['Boolean'];
  type: UserAddressTypeEnum;
  user: User;
};

/** A paginated list of UserAddress items. */
export type UserAddressPaginator = {
  __typename?: 'UserAddressPaginator';
  /** A list of UserAddress items. */
  data: Array<UserAddress>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum UserAddressTypeEnum {
  Billing = 'BILLING',
  New = 'NEW',
  Shipping = 'SHIPPING'
}

export type UserManagementInput = {
  categories: Array<Scalars['ID']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  job_function_id: Scalars['ID'];
  last_name: Scalars['String'];
  mobile?: InputMaybe<Scalars['String']>;
  office?: InputMaybe<Scalars['String']>;
  organization_id: Scalars['ID'];
  permissions: Array<Scalars['ID']>;
  service_lines: Array<ServiceLinePermissionInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserManagementType = {
  __typename?: 'UserManagementType';
  categoryPermissions: Array<Category>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['ID'];
  jobFunction?: Maybe<JobFunction>;
  job_function_id: Scalars['ID'];
  last_name: Scalars['String'];
  mobile?: Maybe<Scalars['String']>;
  office?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organization_id: Scalars['ID'];
  permissions: Array<Permission>;
  serviceLinePermissions: Array<ServiceLine>;
  title?: Maybe<Scalars['String']>;
};

/** A paginated list of UserManagementType items. */
export type UserManagementTypePaginator = {
  __typename?: 'UserManagementTypePaginator';
  /** A list of UserManagementType items. */
  data: Array<UserManagementType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type UserPermissionsPayload = {
  __typename?: 'UserPermissionsPayload';
  hasBudgetAccess: Scalars['Boolean'];
  hasBudgetCreate: Scalars['Boolean'];
  hasBudgetDelete: Scalars['Boolean'];
  hasBudgetItemsCreate: Scalars['Boolean'];
  hasBudgetItemsDelete: Scalars['Boolean'];
  hasFleetAccess: Scalars['Boolean'];
  hasFleetAllowDisposition: Scalars['Boolean'];
  hasFleetAudit: Scalars['Boolean'];
  hasFleetRecertify: Scalars['Boolean'];
  hasFleetShip: Scalars['Boolean'];
  hasOrderhubAccess: Scalars['Boolean'];
  hasRequestHubAccess: Scalars['Boolean'];
};

export type UserTableInput = {
  code: Scalars['String'];
  sort_order: Scalars['JSON'];
};

export type Vendor = {
  __typename?: 'Vendor';
  contracts: Array<CategoryOrganizationVendorContract>;
  created_at: Scalars['DateTime'];
  deleted_at: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type VerifyEmailResponse = {
  __typename?: 'VerifyEmailResponse';
  status?: Maybe<RegisterStatuses>;
};

/** Dynamic WHERE conditions for queries. */
export type WhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<WhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<WhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<WhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<Scalars['String']>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE condition queries. */
export type WhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<WhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

export type Widget = {
  __typename?: 'Widget';
  name: Scalars['String'];
  position: PositionType;
  size: WidgetSizeType;
  user_id: Scalars['ID'];
};

export type WidgetSizeInput = {
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export type WidgetSizeType = {
  __typename?: 'WidgetSizeType';
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export enum WorkFlowTypeEnum {
  Addendum = 'ADDENDUM',
  Normal = 'NORMAL'
}

export type WorkflowApproversWithStatus = {
  __typename?: 'WorkflowApproversWithStatus';
  approval_type?: Maybe<Scalars['String']>;
  group_id: Scalars['ID'];
  group_sort_order?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  is_current_approver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  workflow_object_id: Scalars['ID'];
};

export type WorkflowApprovesInput = {
  answer_value?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<UpdateRelatedItemsInput>;
  id: Scalars['ID'];
  question_id?: InputMaybe<Scalars['ID']>;
  reason?: InputMaybe<Scalars['String']>;
  request_hub_control_id?: InputMaybe<Scalars['ID']>;
  request_hub_control_value?: InputMaybe<Scalars['String']>;
  reviewing_field_type?: InputMaybe<ApprovalWorkflowFieldTypeEnum>;
  state: ApprovalWorkFlowObjectStateEnum;
  sub_questions?: InputMaybe<Array<RequestHubQuestionAnswer>>;
};

export enum AssetsReplacedOrderEnum {
  CeNumber = 'CE_NUMBER',
  Manufacturer = 'MANUFACTURER',
  OrderHub = 'ORDER_HUB',
  Organization = 'ORGANIZATION',
  Product = 'PRODUCT',
  ServiceLine = 'SERVICE_LINE'
}

export type AssetsReplacedOrderInput = {
  column?: InputMaybe<AssetsReplacedOrderEnum>;
  order?: InputMaybe<SortOrder>;
};

export enum AssetsToBeReplacedOrderEnum {
  CeNumber = 'CE_NUMBER',
  Manufacturer = 'MANUFACTURER',
  Organization = 'ORGANIZATION',
  Product = 'PRODUCT',
  Quarter = 'QUARTER',
  ServiceLine = 'SERVICE_LINE'
}

export type AssetsToBeReplacedOrderInput = {
  column?: InputMaybe<AssetsToBeReplacedOrderEnum>;
  order?: InputMaybe<SortOrder>;
};

export type Count = {
  __typename?: 'count';
  top_category_count_1?: Maybe<Scalars['Int']>;
  top_category_count_2?: Maybe<Scalars['Int']>;
  top_category_count_3?: Maybe<Scalars['Int']>;
  year: Scalars['Int'];
};

export type Field = {
  __typename?: 'field';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export enum ProjectsCompletedOrderEnum {
  Completed = 'COMPLETED',
  Facility = 'FACILITY',
  Order = 'ORDER',
  Project = 'PROJECT',
  ServiceLine = 'SERVICE_LINE',
  Total = 'TOTAL'
}

export type ProjectsCompletedOrderInput = {
  column?: InputMaybe<ProjectsCompletedOrderEnum>;
  order?: InputMaybe<SortOrder>;
};

export enum ProjectsInProgressOrderEnum {
  Facility = 'FACILITY',
  Order = 'ORDER',
  Project = 'PROJECT',
  ServiceLine = 'SERVICE_LINE',
  Status = 'STATUS',
  Total = 'TOTAL'
}

export type ProjectsInProgressOrderInput = {
  column?: InputMaybe<ProjectsInProgressOrderEnum>;
  order?: InputMaybe<SortOrder>;
};

export type RequestHubControlInput = {
  edit_restarts_workflow: Scalars['Boolean'];
  id: Scalars['ID'];
  required: Scalars['Boolean'];
  visible: Scalars['Boolean'];
};

export type RequestHubControlList = {
  __typename?: 'requestHubControlList';
  code: Scalars['String'];
  edit_restarts_workflow: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  visible: Scalars['Boolean'];
};

export type ReviewingFields = {
  __typename?: 'reviewingFields';
  field_id?: Maybe<Scalars['ID']>;
  field_name?: Maybe<Scalars['String']>;
  field_type?: Maybe<ApprovalWorkflowFieldTypeEnum>;
};

export type AuctionLotAskQuestionMutationVariables = Exact<{
  id: Scalars['ID'];
  question?: InputMaybe<Scalars['String']>;
}>;


export type AuctionLotAskQuestionMutation = { __typename?: 'Mutation', auctionLotAskQuestion?: boolean | null };

export type CartAddItemMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  itemId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
}>;


export type CartAddItemMutation = { __typename?: 'Mutation', cartAddItem?: { __typename?: 'Cart', id: string, subtotal?: number | null, items?: Array<{ __typename?: 'CartItem', id: string, item_id?: string | null, quantity?: number | null, sell_price?: number | null } | null> | null } | null };

export type CartAddOrUpdateItemMutationVariables = Exact<{
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
}>;


export type CartAddOrUpdateItemMutation = { __typename?: 'Mutation', cartAddOrUpdateItem?: { __typename?: 'Cart', id: string, subtotal?: number | null, items?: Array<{ __typename?: 'CartItem', id: string, item_id?: string | null, quantity?: number | null, sell_price?: number | null } | null> | null } | null };

export type CartClearMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CartClearMutation = { __typename?: 'Mutation', cartClear?: { __typename?: 'Cart', id: string, subtotal?: number | null, items?: Array<{ __typename?: 'CartItem', id: string, item_id?: string | null, quantity?: number | null, sell_price?: number | null } | null> | null } | null };

export type CartDeleteItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CartDeleteItemMutation = { __typename?: 'Mutation', cartDeleteItem?: { __typename?: 'CartItem', id: string } | null };

export type CartUpdateItemMutationVariables = Exact<{
  id: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
}>;


export type CartUpdateItemMutation = { __typename?: 'Mutation', cartUpdateItem?: { __typename?: 'CartItem', id: string, quantity?: number | null, sell_price?: number | null, cart?: { __typename?: 'Cart', subtotal?: number | null, items?: Array<{ __typename?: 'CartItem', id: string, item_id?: string | null, quantity?: number | null, sell_price?: number | null } | null> | null } | null } | null };

export type ContactUsMutationVariables = Exact<{
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  message: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
}>;


export type ContactUsMutation = { __typename?: 'Mutation', contactUs?: boolean | null };

export type CreateBidMutationVariables = Exact<{
  lot_id: Scalars['ID'];
  amount: Scalars['Float'];
}>;


export type CreateBidMutation = { __typename?: 'Mutation', createBid?: { __typename?: 'Bid', id: string } | null };

export type CreateItemMutationVariables = Exact<{
  condition: ConditionEnum;
  freight_shipping?: InputMaybe<Scalars['Boolean']>;
  warranty?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<ItemImagesRequest>> | InputMaybe<ItemImagesRequest>>;
  product_id: Scalars['ID'];
  quantity: ItemQuantityRequest;
  documents?: InputMaybe<Array<InputMaybe<ItemDocumentsRequest>> | InputMaybe<ItemDocumentsRequest>>;
  lot_number?: InputMaybe<Scalars['String']>;
  expiration_data?: InputMaybe<Scalars['Date']>;
  rx_only: Scalars['Boolean'];
  sell_price: Scalars['Float'];
  notes?: InputMaybe<Scalars['String']>;
}>;


export type CreateItemMutation = { __typename?: 'Mutation', createItem?: { __typename?: 'Item', id: string } | null };

export type CreateUserAddressMutationVariables = Exact<{
  input: CreateUserAddressInput;
}>;


export type CreateUserAddressMutation = { __typename?: 'Mutation', createUserAddress: { __typename?: 'UserAddress', id: string, address: { __typename?: 'Address', zip?: number | null } } };

export type DeleteItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteItemMutation = { __typename?: 'Mutation', deleteItem?: { __typename?: 'Item', id: string } | null };

export type DeleteUserAddressMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserAddressMutation = { __typename?: 'Mutation', deleteUserAddress?: { __typename?: 'UserAddress', id: string } | null };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  referer: ApplicationRefererEnum;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'ForgotPasswordResponse', status: string, message?: string | null } };

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
  referer: ApplicationRefererEnum;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', access_token?: string | null, refresh_token?: string | null, expires_in?: number | null, user?: { __typename?: 'User', id: string, first_name: string, last_name: string, email: string, auction_status?: AuctionStatusEnum | null, roles: Array<{ __typename?: 'Role', id: string, name: string, parent?: { __typename?: 'Role', id: string, name: string } | null }>, permissions: Array<{ __typename?: 'Permission', id: string, name: string, description?: string | null }> } | null } };

export type PlaceOrderMutationVariables = Exact<{
  shipping_address_id: Scalars['ID'];
  payment_address_id: Scalars['ID'];
  payment_source_id: Scalars['ID'];
  items?: InputMaybe<Array<PlaceOrderItemsInput> | PlaceOrderItemsInput>;
  payment_nonce?: InputMaybe<Scalars['String']>;
  po_number?: InputMaybe<Scalars['String']>;
}>;


export type PlaceOrderMutation = { __typename?: 'Mutation', placeOrder?: { __typename?: 'Order', id: string, order_subtotal?: number | null, total?: number | null, shipping?: number | null, taxes?: number | null, shippingAddress?: { __typename?: 'Address', id: string, zip?: number | null, attention_to?: string | null, addr1: string, addr2?: string | null, city: string, state_or_province?: string | null, postal_code?: string | null, country?: { __typename?: 'Country', id: string, name: string } | null } | null, billingAddress?: { __typename?: 'Address', id: string, zip?: number | null, attention_to?: string | null, addr1: string, addr2?: string | null, city: string, state_or_province?: string | null, postal_code?: string | null, country?: { __typename?: 'Country', id: string, name: string } | null } | null, paymentSource?: { __typename?: 'PaymentSource', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'OrderItem', id: string, quantity: string, sell_price: number } | null> | null } | null };

export type RefreshTokenMutationVariables = Exact<{
  refresh_token?: InputMaybe<Scalars['String']>;
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'RefreshTokenPayload', access_token: string, refresh_token: string, expires_in: number } };

export type RegisterMutationVariables = Exact<{
  organization_type_id: Scalars['Int'];
  organization: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  mobile?: InputMaybe<Scalars['String']>;
  seller: Scalars['Boolean'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'RegisterResponse', status: RegisterStatuses } };

export type RequestProductMutationVariables = Exact<{
  RequestProductInput: RequestProductInput;
}>;


export type RequestProductMutation = { __typename?: 'Mutation', requestProduct: { __typename?: 'RequestProduct', id: string } };

export type SubscribeToCategoriesMutationVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;


export type SubscribeToCategoriesMutation = { __typename?: 'Mutation', subscribeToCategories?: { __typename?: 'User', id: string } | null };

export type UpdateForgottenPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
}>;


export type UpdateForgottenPasswordMutation = { __typename?: 'Mutation', updateForgottenPassword: { __typename?: 'ForgotPasswordResponse', status: string, message?: string | null } };

export type UpdateItemMutationVariables = Exact<{
  id: Scalars['ID'];
  condition: ConditionEnum;
  freight_shipping?: InputMaybe<Scalars['Boolean']>;
  warranty?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<ItemImagesRequest>> | InputMaybe<ItemImagesRequest>>;
  product_id: Scalars['ID'];
  quantity: ItemQuantityRequest;
  documents?: InputMaybe<Array<InputMaybe<ItemDocumentsRequest>> | InputMaybe<ItemDocumentsRequest>>;
  lot_number?: InputMaybe<Scalars['String']>;
  expiration_data?: InputMaybe<Scalars['Date']>;
  rx_only: Scalars['Boolean'];
  sell_price: Scalars['Float'];
  delete?: InputMaybe<UpdateItemDeleteInput>;
}>;


export type UpdateItemMutation = { __typename?: 'Mutation', updateItem?: { __typename?: 'Item', id: string } | null };

export type UpdatePasswordMutationVariables = Exact<{
  old_password: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: { __typename?: 'UpdatePasswordResponse', status: string, message?: string | null } };

export type UpdateUserAddressMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateUserAddressInput;
}>;


export type UpdateUserAddressMutation = { __typename?: 'Mutation', updateUserAddress: { __typename?: 'UserAddress', id: string } };

export type VerifyEmailMutationVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail?: { __typename?: 'VerifyEmailResponse', status?: RegisterStatuses | null } | null };

export type AddressQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type AddressQuery = { __typename?: 'Query', address?: { __typename?: 'Address', id: string, zip?: number | null, attention_to?: string | null, addr1: string, addr2?: string | null, city: string, state_or_province?: string | null, postal_code?: string | null, country?: { __typename?: 'Country', id: string, name: string, abbreviation: string } | null } | null };

export type AuctionLotQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type AuctionLotQuery = { __typename?: 'Query', auctionLot?: { __typename?: 'AuctionLot', id: string, name: string, description?: string | null, bid_count: number, ownBidsCount: number, bid_increment?: number | null, reserve_amount?: number | null, active: boolean, winning_bid_amount?: number | null, winner?: { __typename?: 'User', id: string, first_name: string, last_name: string, email: string } | null, auction?: { __typename?: 'Auction', end_at?: string | null, begin_at: string } | null, bid?: { __typename?: 'Bid', amount?: number | null, automatic_increment?: boolean | null, customer?: { __typename?: 'User', id: string } | null } | null, items?: Array<{ __typename?: 'Item', id: string, notes?: string | null, unlimited_quantity: boolean, quantity: number, condition: ConditionEnum, images?: Array<{ __typename?: 'ItemImage', id: string, name: string, path: string } | null> | null, documents?: Array<{ __typename?: 'ItemDocument', id: string, name: string, path: string } | null> | null, product?: { __typename?: 'Product', mpn: string, product_description: string, product_details?: string | null, manufacturer: { __typename?: 'Manufacturer', name: string } } | null } | null> | null } | null };

export type AuctionLotsQueryVariables = Exact<{
  auction_id: Scalars['ID'];
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AuctionLotsQuery = { __typename?: 'Query', auctionLots?: Array<{ __typename?: 'AuctionLot', id: string, name: string, description?: string | null, bid_count: number, ownBidsCount: number, bid_increment?: number | null, reserve_amount?: number | null, active: boolean, winning_bid_amount?: number | null, winner?: { __typename?: 'User', id: string, first_name: string, last_name: string, email: string } | null, auction?: { __typename?: 'Auction', end_at?: string | null, begin_at: string } | null, bid?: { __typename?: 'Bid', amount?: number | null, automatic_increment?: boolean | null, customer?: { __typename?: 'User', id: string } | null } | null, items?: Array<{ __typename?: 'Item', id: string, notes?: string | null, unlimited_quantity: boolean, quantity: number, condition: ConditionEnum, images?: Array<{ __typename?: 'ItemImage', id: string, name: string, path: string } | null> | null, documents?: Array<{ __typename?: 'ItemDocument', id: string, name: string, path: string } | null> | null, product?: { __typename?: 'Product', mpn: string, product_description: string, product_details?: string | null, manufacturer: { __typename?: 'Manufacturer', name: string } } | null } | null> | null } | null> | null };

export type AuctionQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type AuctionQuery = { __typename?: 'Query', auction?: { __typename?: 'Auction', id: string, name: string, description?: string | null, begin_at: string, end_at?: string | null, created_at?: string | null, updated_at?: string | null, creator?: { __typename?: 'User', id: string, first_name: string, last_name: string } | null, lots?: Array<{ __typename?: 'AuctionLot', id: string, name: string, description?: string | null, bid_count: number, ownBidsCount: number, bid_increment?: number | null, reserve_amount?: number | null, active: boolean, winning_bid_amount?: number | null, bid?: { __typename?: 'Bid', amount?: number | null, automatic_increment?: boolean | null, customer?: { __typename?: 'User', id: string } | null } | null, winner?: { __typename?: 'User', id: string, first_name: string, last_name: string, email: string } | null, items?: Array<{ __typename?: 'Item', id: string, notes?: string | null, unlimited_quantity: boolean, quantity: number, condition: ConditionEnum, images?: Array<{ __typename?: 'ItemImage', id: string, name: string, path: string } | null> | null, documents?: Array<{ __typename?: 'ItemDocument', id: string, name: string, path: string } | null> | null, product?: { __typename?: 'Product', mpn: string, product_description: string, product_details?: string | null, manufacturer: { __typename?: 'Manufacturer', name: string } } | null } | null> | null } | null> | null } | null };

export type AuctionsQueryVariables = Exact<{
  filters?: InputMaybe<AuctionsFilters>;
  page?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionOrderByInput>;
}>;


export type AuctionsQuery = { __typename?: 'Query', auctions?: { __typename?: 'AuctionPaginator', data: Array<{ __typename?: 'Auction', id: string, name: string, description?: string | null, begin_at: string, end_at?: string | null, created_at?: string | null, updated_at?: string | null, lots?: Array<{ __typename?: 'AuctionLot', id: string, name: string, ownBidsCount: number, description?: string | null, items?: Array<{ __typename?: 'Item', images?: Array<{ __typename?: 'ItemImage', path: string, name: string } | null> | null } | null> | null, winner?: { __typename?: 'User', id: string } | null } | null> | null }>, paginatorInfo: { __typename?: 'PaginatorInfo', total: number, count: number, currentPage: number, hasMorePages: boolean, perPage: number } } | null };

export type CartQueryVariables = Exact<{
  type?: InputMaybe<CartTypeEnum>;
}>;


export type CartQuery = { __typename?: 'Query', cart?: { __typename?: 'Cart', id: string, subtotal?: number | null, total?: number | null, shipping?: number | null, taxes?: number | null, braintree_token?: string | null, items?: Array<{ __typename?: 'CartItem', id: string, item_id?: string | null, quantity?: number | null, sell_price?: number | null, item?: { __typename?: 'Item', quantity: number, unlimited_quantity: boolean, images?: Array<{ __typename?: 'ItemImage', path: string } | null> | null, product?: { __typename?: 'Product', mpn: string, manufacturer: { __typename?: 'Manufacturer', name: string } } | null } | null } | null> | null } | null };

export type CategoriesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  showInMarketplace?: InputMaybe<Scalars['Boolean']>;
  hasParent?: InputMaybe<Scalars['Int']>;
}>;


export type CategoriesQuery = { __typename?: 'Query', categories?: { __typename?: 'CategoryPaginator', data: Array<{ __typename?: 'Category', id: string, name: string, parent?: { __typename?: 'Category', id: string } | null, children: Array<{ __typename?: 'Category', id: string, name: string, parent?: { __typename?: 'Category', id: string } | null, children: Array<{ __typename?: 'Category', id: string, name: string, parent?: { __typename?: 'Category', id: string } | null }> }> }>, paginatorInfo: { __typename?: 'PaginatorInfo', total: number } } | null };

export type CategoryQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type CategoryQuery = { __typename?: 'Query', category?: { __typename?: 'Category', id: string, name: string, children: Array<{ __typename?: 'Category', id: string, name: string }> } | null };

export type ClearSubscriptionMutationVariables = Exact<{
  channelNames: Array<Scalars['String']> | Scalars['String'];
}>;


export type ClearSubscriptionMutation = { __typename?: 'Mutation', clearSubscription: boolean };

export type ItemQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type ItemQuery = { __typename?: 'Query', item?: { __typename?: 'Item', id: string, created_at?: any | null, quantity: number, unlimited_quantity: boolean, sell_price?: number | null, rx_only?: boolean | null, lot?: string | null, freight?: boolean | null, qtytype_qty: number, warranty?: string | null, condition: ConditionEnum, quantityType: { __typename?: 'QuantityType', id: string, name: string }, auctionLot?: { __typename?: 'AuctionLot', id: string } | null, product?: { __typename?: 'Product', id: string, mpn: string, product_details?: string | null, product_description: string, weight?: string | null, height?: string | null, depth?: string | null, width?: string | null, category?: { __typename?: 'Category', id: string, name: string } | null, manufacturer: { __typename?: 'Manufacturer', name: string, description: string, id: string } } | null, documents?: Array<{ __typename?: 'ItemDocument', id: string, name: string, path: string } | null> | null, images?: Array<{ __typename?: 'ItemImage', id: string, name: string, path: string } | null> | null } | null };

export type ItemsQueryVariables = Exact<{
  filters?: InputMaybe<ItemFilters>;
  orderBy?: InputMaybe<ItemsOrderByInput>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type ItemsQuery = { __typename?: 'Query', items?: { __typename?: 'ItemPaginator', data: Array<{ __typename?: 'Item', created_at?: any | null, id: string, quantity: number, sell_price?: number | null, unlimited_quantity: boolean, active: boolean, condition: ConditionEnum, images?: Array<{ __typename?: 'ItemImage', name: string, path: string } | null> | null, quantityType: { __typename?: 'QuantityType', id: string, name: string }, product?: { __typename?: 'Product', product_description: string, mpn: string, manufacturer: { __typename?: 'Manufacturer', name: string } } | null }>, paginatorInfo: { __typename?: 'PaginatorInfo', total: number, currentPage: number, perPage: number, lastPage: number, count: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null } } | null };

export type ManufacturersQueryVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  onlyWithActiveItems?: InputMaybe<Scalars['Boolean']>;
}>;


export type ManufacturersQuery = { __typename?: 'Query', manufacturers?: { __typename?: 'ManufacturerPaginator', data: Array<{ __typename?: 'Manufacturer', id: string, name: string }>, paginatorInfo: { __typename?: 'PaginatorInfo', total: number } } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email: string, first_name: string, last_name: string, notificationsCategories?: Array<{ __typename?: 'Category', id: string, name: string, children: Array<{ __typename?: 'Category', id: string, name: string, children: Array<{ __typename?: 'Category', id: string, name: string, children: Array<{ __typename?: 'Category', id: string, name: string }> }> }> } | null> | null, organization?: { __typename?: 'Organization', name: string } | null, permissions: Array<{ __typename?: 'Permission', id: string, name: string, description?: string | null }>, userAddress?: Array<{ __typename?: 'UserAddress', id: string, is_primary: boolean, address: { __typename?: 'Address', city: string, addr1: string, addr2?: string | null, country?: { __typename?: 'Country', name: string } | null } } | null> | null } | null };

export type OrderQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type OrderQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string, order_subtotal?: number | null, total?: number | null, shipping?: number | null, taxes?: number | null, fees?: Array<{ __typename?: 'Fee', id: string, amount?: number | null, type?: { __typename?: 'FeeType', id: string, name?: string | null } | null } | null> | null, customer: { __typename?: 'User', organization?: { __typename?: 'Organization', name: string } | null }, shippingAddress?: { __typename?: 'Address', id: string, zip?: number | null, attention_to?: string | null, addr1: string, addr2?: string | null, city: string, state_or_province?: string | null, postal_code?: string | null, country?: { __typename?: 'Country', id: string, name: string } | null } | null, billingAddress?: { __typename?: 'Address', id: string, zip?: number | null, attention_to?: string | null, addr1: string, addr2?: string | null, city: string, state_or_province?: string | null, postal_code?: string | null, country?: { __typename?: 'Country', id: string, name: string } | null } | null, paymentSource?: { __typename?: 'PaymentSource', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'OrderItem', id: string, quantity: string, sell_price: number, item?: { __typename?: 'Item', product?: { __typename?: 'Product', mpn: string, product_description: string, manufacturer: { __typename?: 'Manufacturer', name: string } } | null } | null } | null> | null } | null };

export type OrdersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<OrderFilters>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders?: { __typename?: 'OrderPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number, count: number, currentPage: number, hasMorePages: boolean, perPage: number }, data: Array<{ __typename?: 'Order', referral_id?: string | null, referral_type_id?: string | null, order_subtotal?: number | null, created_at?: any | null, id: string, items?: Array<{ __typename?: 'OrderItem', id: string, quantity: string, sell_price: number, vendor_invoice?: string | null, resell?: boolean | null, referral_id?: string | null, shipment_id?: string | null, item?: { __typename?: 'Item', id: string, sell_price?: number | null, status: ItemStatusEnum, quantity: number, unlimited_quantity: boolean, images?: Array<{ __typename?: 'ItemImage', path: string } | null> | null, product?: { __typename?: 'Product', product_description: string, product_details?: string | null, mpn: string, manufacturer: { __typename?: 'Manufacturer', name: string } } | null } | null, referralType?: { __typename?: 'ReferralType', id: string, name?: string | null } | null } | null> | null, status: { __typename?: 'OrderStatus', id: string, name: string, active: boolean }, shippingAddress?: { __typename?: 'Address', id: string, zip?: number | null, attention_to?: string | null, addr1: string, addr2?: string | null, city: string, state_or_province?: string | null, postal_code?: string | null, country?: { __typename?: 'Country', id: string, name: string } | null } | null, billingAddress?: { __typename?: 'Address', id: string, zip?: number | null, attention_to?: string | null, addr1: string, addr2?: string | null, city: string, state_or_province?: string | null, postal_code?: string | null, country?: { __typename?: 'Country', id: string, name: string } | null } | null, paymentSource?: { __typename?: 'PaymentSource', id: string, name?: string | null } | null }> } | null };

export type OrganizationTypesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type OrganizationTypesQuery = { __typename?: 'Query', organizationTypes?: { __typename?: 'OrganizationTypePaginator', data: Array<{ __typename?: 'OrganizationType', id: string, name: string }>, paginatorInfo: { __typename?: 'PaginatorInfo', currentPage: number, lastPage: number } } | null };

export type OrganizationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  mine?: InputMaybe<Scalars['Boolean']>;
}>;


export type OrganizationsQuery = { __typename?: 'Query', organizations?: { __typename?: 'OrganizationPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, total: number }, data: Array<{ __typename?: 'Organization', id: string, name: string, phone?: string | null, parent?: { __typename?: 'Organization', id: string, name: string } | null }> } | null };

export type OwnAuctionsQueryVariables = Exact<{
  filters?: InputMaybe<AuctionsFilters>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type OwnAuctionsQuery = { __typename?: 'Query', ownAuctions?: { __typename?: 'AuctionPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number }, data: Array<{ __typename?: 'Auction', id: string, name: string, description?: string | null, begin_at: string, end_at?: string | null, created_at?: string | null, updated_at?: string | null, lots?: Array<{ __typename?: 'AuctionLot', id: string, name: string, description?: string | null, bid_count: number, ownBidsCount: number, bid_increment?: number | null, reserve_amount?: number | null, active: boolean, winning_bid_amount?: number | null, items?: Array<{ __typename?: 'Item', id: string, product?: { __typename?: 'Product', mpn: string, product_description: string, manufacturer: { __typename?: 'Manufacturer', name: string } } | null } | null> | null } | null> | null }> } | null };

export type PaymentSourcesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type PaymentSourcesQuery = { __typename?: 'Query', paymentSources?: { __typename?: 'PaymentSourcePaginator', paginatorInfo: { __typename?: 'PaginatorInfo', count: number, total: number }, data: Array<{ __typename?: 'PaymentSource', id: string, name?: string | null }> } | null };

export type ProductsQueryVariables = Exact<{
  manufacturer_id?: InputMaybe<Scalars['ID']>;
  mpn?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type ProductsQuery = { __typename?: 'Query', products?: { __typename?: 'ProductPaginator', data: Array<{ __typename?: 'Product', id: string, mpn: string, product_description: string }> } | null };

export type QuantityTypesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
}>;


export type QuantityTypesQuery = { __typename?: 'Query', quantityTypes?: { __typename?: 'QuantityTypePaginator', data: Array<{ __typename?: 'QuantityType', id: string, name: string }> } | null };

export type SearchItemsQueryVariables = Exact<{
  filters?: InputMaybe<ItemFilters>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type SearchItemsQuery = { __typename?: 'Query', items?: { __typename?: 'ItemPaginator', data: Array<{ __typename?: 'Item', id: string, product?: { __typename?: 'Product', product_description: string, mpn: string, manufacturer: { __typename?: 'Manufacturer', name: string } } | null }> } | null };

export type UserAddressesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type UserAddressesQuery = { __typename?: 'Query', userAddresses?: { __typename?: 'UserAddressPaginator', data: Array<{ __typename?: 'UserAddress', id: string, is_primary: boolean, type: UserAddressTypeEnum, address: { __typename?: 'Address', id: string, zip?: number | null, attention_to?: string | null, addr1: string, addr2?: string | null, city: string, state_or_province?: string | null, postal_code?: string | null, country?: { __typename?: 'Country', id: string, name: string, abbreviation: string } | null } }>, paginatorInfo: { __typename?: 'PaginatorInfo', total: number } } | null };

export type UserStatisticsQueryVariables = Exact<{
  organization_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  date_from?: InputMaybe<Scalars['Date']>;
  date_to?: InputMaybe<Scalars['Date']>;
  view_by?: InputMaybe<SellerStatisticsViewEnum>;
}>;


export type UserStatisticsQuery = { __typename?: 'Query', userStatistics: { __typename?: 'SellerStatistics', soldTotalAmount?: number | null, purchasedTotalAmount?: number | null, listedTotalAmount?: number | null, soldGraphData?: Array<{ __typename?: 'OrganizationSoldStatisticGraphData', total_amount?: number | null, count?: number | null, date?: any | null } | null> | null, purchasedGraphData?: Array<{ __typename?: 'OrganizationPurchasedStatisticGraphData', total_amount?: number | null, count?: number | null, date?: any | null } | null> | null, listedGraphData?: Array<{ __typename?: 'OrganizationListedStatisticGraphData', total_amount?: number | null, count?: number | null, date?: any | null } | null> | null } };

export type WinLotsQueryVariables = Exact<{
  filters?: InputMaybe<AuctionLotFilters>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionOrderByInput>;
}>;


export type WinLotsQuery = { __typename?: 'Query', winLots?: { __typename?: 'AuctionLotPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number }, data: Array<{ __typename?: 'AuctionLot', id: string, name: string, description?: string | null, bid_count: number, ownBidsCount: number, bid_increment?: number | null, reserve_amount?: number | null, active: boolean, winning_bid_amount?: number | null, winner?: { __typename?: 'User', id: string, first_name: string, last_name: string } | null, bid?: { __typename?: 'Bid', id: string, amount?: number | null } | null, auction?: { __typename?: 'Auction', id: string, name: string, description?: string | null, begin_at: string, end_at?: string | null, created_at?: string | null, updated_at?: string | null, creator?: { __typename?: 'User', id: string, first_name: string, last_name: string } | null, lots?: Array<{ __typename?: 'AuctionLot', id: string, name: string, description?: string | null, bid_count: number, ownBidsCount: number, bid_increment?: number | null, reserve_amount?: number | null, active: boolean, winning_bid_amount?: number | null, items?: Array<{ __typename?: 'Item', id: string, images?: Array<{ __typename?: 'ItemImage', id: string, name: string, path: string } | null> | null, product?: { __typename?: 'Product', mpn: string, product_description: string, manufacturer: { __typename?: 'Manufacturer', name: string } } | null } | null> | null } | null> | null } | null, items?: Array<{ __typename?: 'Item', unlimited_quantity: boolean, quantity: number, condition: ConditionEnum, id: string, images?: Array<{ __typename?: 'ItemImage', id: string, name: string, path: string } | null> | null, product?: { __typename?: 'Product', mpn: string, product_description: string, manufacturer: { __typename?: 'Manufacturer', name: string } } | null } | null> | null }> } | null };

export type OnCartUpdatedSubscriptionVariables = Exact<{
  cartId?: InputMaybe<Scalars['ID']>;
}>;


export type OnCartUpdatedSubscription = { __typename?: 'Subscription', cartUpdated?: { __typename?: 'Cart', id: string, subtotal?: number | null, items?: Array<{ __typename?: 'CartItem', id: string, item_id?: string | null, quantity?: number | null, sell_price?: number | null, item?: { __typename?: 'Item', quantity: number, unlimited_quantity: boolean, images?: Array<{ __typename?: 'ItemImage', path: string } | null> | null, product?: { __typename?: 'Product', mpn: string, manufacturer: { __typename?: 'Manufacturer', name: string } } | null } | null } | null> | null } | null };

export type OnNewOutbidSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnNewOutbidSubscription = { __typename?: 'Subscription', newOutbid?: { __typename?: 'Bid', id: string, amount?: number | null, automatic_increment?: boolean | null, max_bid_flat?: boolean | null, bid_count?: number | null, customer?: { __typename?: 'User', id: string } | null, lot?: { __typename?: 'AuctionLot', id: string, name: string } | null } | null };


export const AuctionLotAskQuestionDocument = gql`
    mutation auctionLotAskQuestion($id: ID!, $question: String) {
  auctionLotAskQuestion(id: $id, input: {question: $question})
}
    `;
export const CartAddItemDocument = gql`
    mutation cartAddItem($id: ID, $itemId: ID!, $quantity: Int) {
  cartAddItem(id: $id, input: {itemId: $itemId, quantity: $quantity}) {
    id
    subtotal
    items {
      id
      item_id
      quantity
      sell_price
    }
  }
}
    `;
export const CartAddOrUpdateItemDocument = gql`
    mutation cartAddOrUpdateItem($id: ID!, $itemId: ID!, $quantity: Int) {
  cartAddOrUpdateItem(id: $id, input: {itemId: $itemId, quantity: $quantity}) {
    id
    subtotal
    items {
      id
      item_id
      quantity
      sell_price
    }
  }
}
    `;
export const CartClearDocument = gql`
    mutation cartClear($id: ID!) {
  cartClear(id: $id) {
    id
    subtotal
    items {
      id
      item_id
      quantity
      sell_price
    }
  }
}
    `;
export const CartDeleteItemDocument = gql`
    mutation cartDeleteItem($id: ID!) {
  cartDeleteItem(id: $id) {
    id
  }
}
    `;
export const CartUpdateItemDocument = gql`
    mutation cartUpdateItem($id: ID!, $quantity: Int) {
  cartUpdateItem(id: $id, input: {quantity: $quantity}) {
    id
    quantity
    sell_price
    cart {
      subtotal
      items {
        id
        item_id
        quantity
        sell_price
      }
    }
  }
}
    `;
export const ContactUsDocument = gql`
    mutation contactUs($email: String!, $first_name: String!, $last_name: String!, $message: String!, $phone: String) {
  contactUs(
    input: {email: $email, first_name: $first_name, last_name: $last_name, message: $message, phone: $phone}
  )
}
    `;
export const CreateBidDocument = gql`
    mutation createBid($lot_id: ID!, $amount: Float!) {
  createBid(input: {lot_id: $lot_id, amount: $amount}) {
    id
  }
}
    `;
export const CreateItemDocument = gql`
    mutation createItem($condition: ConditionEnum!, $freight_shipping: Boolean, $warranty: String, $images: [ItemImagesRequest], $product_id: ID!, $quantity: ItemQuantityRequest!, $documents: [ItemDocumentsRequest], $lot_number: String, $expiration_data: Date, $rx_only: Boolean!, $sell_price: Float!, $notes: String) {
  createItem(
    input: {condition: $condition, freight_shipping: $freight_shipping, warranty: $warranty, images: $images, product_id: $product_id, quantity: $quantity, documents: $documents, lot_number: $lot_number, expiration_data: $expiration_data, rx_only: $rx_only, sell_price: $sell_price, notes: $notes}
  ) {
    id
  }
}
    `;
export const CreateUserAddressDocument = gql`
    mutation createUserAddress($input: CreateUserAddressInput!) {
  createUserAddress(input: $input) {
    id
    address {
      zip
    }
  }
}
    `;
export const DeleteItemDocument = gql`
    mutation deleteItem($id: ID!) {
  deleteItem(id: $id) {
    id
  }
}
    `;
export const DeleteUserAddressDocument = gql`
    mutation deleteUserAddress($id: ID!) {
  deleteUserAddress(id: $id) {
    id
  }
}
    `;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!, $referer: ApplicationRefererEnum!) {
  forgotPassword(input: {email: $email, referer: $referer}) {
    status
    message
  }
}
    `;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!, $referer: ApplicationRefererEnum!) {
  login(input: {username: $username, password: $password, referer: $referer}) {
    access_token
    refresh_token
    expires_in
    user {
      id
      first_name
      last_name
      email
      auction_status
      roles {
        id
        parent {
          id
          name
        }
        name
      }
      permissions {
        id
        name
        description
      }
    }
  }
}
    `;
export const PlaceOrderDocument = gql`
    mutation placeOrder($shipping_address_id: ID!, $payment_address_id: ID!, $payment_source_id: ID!, $items: [PlaceOrderItemsInput!], $payment_nonce: String, $po_number: String) {
  placeOrder(
    input: {shipping_address_id: $shipping_address_id, payment_address_id: $payment_address_id, payment_source_id: $payment_source_id, items: $items, payment_nonce: $payment_nonce, po_number: $po_number}
  ) {
    id
    order_subtotal
    total
    shipping
    taxes
    shippingAddress {
      id
      zip
      attention_to
      addr1
      addr2
      city
      state_or_province
      postal_code
      country {
        id
        name
      }
    }
    billingAddress {
      id
      zip
      attention_to
      addr1
      addr2
      city
      state_or_province
      postal_code
      country {
        id
        name
      }
    }
    paymentSource {
      id
      name
    }
    items {
      id
      quantity
      sell_price
    }
  }
}
    `;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refresh_token: String) {
  refreshToken(input: {refresh_token: $refresh_token}) {
    access_token
    refresh_token
    expires_in
  }
}
    `;
export const RegisterDocument = gql`
    mutation Register($organization_type_id: Int!, $organization: String!, $email: String!, $first_name: String!, $last_name: String!, $mobile: String, $seller: Boolean!, $password: String!, $password_confirmation: String!) {
  register(
    input: {email: $email, password: $password, password_confirmation: $password_confirmation, organization_type_id: $organization_type_id, organization: $organization, first_name: $first_name, last_name: $last_name, mobile: $mobile, seller: $seller}
  ) {
    status
  }
}
    `;
export const RequestProductDocument = gql`
    mutation requestProduct($RequestProductInput: RequestProductInput!) {
  requestProduct(input: $RequestProductInput) {
    id
  }
}
    `;
export const SubscribeToCategoriesDocument = gql`
    mutation subscribeToCategories($ids: [ID]) {
  subscribeToCategories(input: {ids: $ids}) {
    id
  }
}
    `;
export const UpdateForgottenPasswordDocument = gql`
    mutation updateForgottenPassword($email: String!, $token: String!, $password: String!, $password_confirmation: String!) {
  updateForgottenPassword(
    input: {email: $email, token: $token, password: $password, password_confirmation: $password_confirmation}
  ) {
    status
    message
  }
}
    `;
export const UpdateItemDocument = gql`
    mutation updateItem($id: ID!, $condition: ConditionEnum!, $freight_shipping: Boolean, $warranty: String, $images: [ItemImagesRequest], $product_id: ID!, $quantity: ItemQuantityRequest!, $documents: [ItemDocumentsRequest], $lot_number: String, $expiration_data: Date, $rx_only: Boolean!, $sell_price: Float!, $delete: UpdateItemDeleteInput) {
  updateItem(
    input: {id: $id, condition: $condition, freight_shipping: $freight_shipping, warranty: $warranty, images: $images, product_id: $product_id, quantity: $quantity, documents: $documents, lot_number: $lot_number, expiration_data: $expiration_data, rx_only: $rx_only, sell_price: $sell_price, delete: $delete}
  ) {
    id
  }
}
    `;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($old_password: String!, $password: String!, $password_confirmation: String!) {
  updatePassword(
    input: {old_password: $old_password, password: $password, password_confirmation: $password_confirmation}
  ) {
    status
    message
  }
}
    `;
export const UpdateUserAddressDocument = gql`
    mutation updateUserAddress($id: ID!, $input: UpdateUserAddressInput!) {
  updateUserAddress(id: $id, input: $input) {
    id
  }
}
    `;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($code: String) {
  verifyEmail(code: $code) {
    status
  }
}
    `;
export const AddressDocument = gql`
    query address($id: ID) {
  address(id: $id) {
    id
    zip
    attention_to
    addr1
    addr2
    city
    state_or_province
    postal_code
    country {
      id
      name
      abbreviation
    }
  }
}
    `;
export const AuctionLotDocument = gql`
    query auctionLot($id: ID) {
  auctionLot(id: $id) {
    id
    name
    description
    bid_count
    ownBidsCount
    bid_increment
    reserve_amount
    active
    winning_bid_amount
    winner {
      id
      first_name
      last_name
      email
    }
    auction {
      end_at
      begin_at
    }
    bid {
      amount
      customer {
        id
      }
      automatic_increment
    }
    items {
      id
      notes
      unlimited_quantity
      quantity
      condition
      images {
        id
        name
        path
      }
      documents {
        id
        name
        path
      }
      product {
        manufacturer {
          name
        }
        mpn
        product_description
        product_details
      }
    }
  }
}
    `;
export const AuctionLotsDocument = gql`
    query auctionLots($auction_id: ID!, $ids: [ID!]!) {
  auctionLots(auction_id: $auction_id, ids: $ids) {
    id
    id
    name
    description
    bid_count
    ownBidsCount
    bid_increment
    reserve_amount
    active
    winning_bid_amount
    winner {
      id
      first_name
      last_name
      email
    }
    auction {
      end_at
      begin_at
    }
    bid {
      amount
      customer {
        id
      }
      automatic_increment
    }
    items {
      id
      notes
      unlimited_quantity
      quantity
      condition
      images {
        id
        name
        path
      }
      documents {
        id
        name
        path
      }
      product {
        manufacturer {
          name
        }
        mpn
        product_description
        product_details
      }
    }
  }
}
    `;
export const AuctionDocument = gql`
    query auction($id: ID) {
  auction(id: $id) {
    id
    name
    description
    creator {
      id
      first_name
      last_name
    }
    begin_at
    end_at
    created_at
    updated_at
    lots {
      id
      name
      description
      bid_count
      ownBidsCount
      bid_increment
      reserve_amount
      bid {
        amount
        customer {
          id
        }
        automatic_increment
      }
      active
      winning_bid_amount
      winner {
        id
        first_name
        last_name
        email
      }
      items {
        id
        notes
        unlimited_quantity
        quantity
        condition
        images {
          id
          name
          path
        }
        documents {
          id
          name
          path
        }
        product {
          manufacturer {
            name
          }
          mpn
          product_description
          product_details
        }
      }
    }
  }
}
    `;
export const AuctionsDocument = gql`
    query auctions($filters: AuctionsFilters, $page: Int, $first: Int = 10000, $orderBy: AuctionOrderByInput = {column: END_AT, direction: DESC}) {
  auctions(filters: $filters, page: $page, first: $first, orderBy: $orderBy) {
    data {
      id
      name
      description
      begin_at
      end_at
      created_at
      updated_at
      lots {
        id
        name
        ownBidsCount
        description
        items {
          images {
            path
            name
          }
        }
        winner {
          id
        }
      }
    }
    paginatorInfo {
      total
      count
      currentPage
      total
      hasMorePages
      perPage
    }
  }
}
    `;
export const CartDocument = gql`
    query cart($type: CartTypeEnum) {
  cart(type: $type) {
    id
    subtotal
    total
    shipping
    taxes
    braintree_token
    items {
      id
      item_id
      quantity
      sell_price
      item {
        quantity
        unlimited_quantity
        images {
          path
        }
        product {
          manufacturer {
            name
          }
          mpn
        }
      }
    }
  }
}
    `;
export const CategoriesDocument = gql`
    query categories($first: Int, $page: Int, $showInMarketplace: Boolean, $hasParent: Int) {
  categories(
    showInMarketplace: $showInMarketplace
    hasParent: $hasParent
    first: $first
    page: $page
  ) {
    data {
      id
      name
      parent {
        id
      }
      children {
        id
        name
        parent {
          id
        }
        children {
          id
          name
          parent {
            id
          }
        }
      }
    }
    paginatorInfo {
      total
    }
  }
}
    `;
export const CategoryDocument = gql`
    query category($id: ID) {
  category(id: $id) {
    id
    name
    children {
      id
      name
    }
  }
}
    `;
export const ClearSubscriptionDocument = gql`
    mutation clearSubscription($channelNames: [String!]!) {
  clearSubscription(channelNames: $channelNames)
}
    `;
export const ItemDocument = gql`
    query item($id: ID) {
  item(id: $id) {
    id
    created_at
    quantity
    unlimited_quantity
    sell_price
    rx_only
    lot
    freight
    qtytype_qty
    quantityType {
      id
      name
    }
    auctionLot {
      id
    }
    warranty
    product {
      id
      mpn
      product_details
      product_description
      weight
      height
      depth
      width
      category {
        id
        name
      }
      manufacturer {
        name
        description
        id
      }
    }
    condition
    documents {
      id
      name
      path
    }
    images {
      id
      name
      path
    }
  }
}
    `;
export const ItemsDocument = gql`
    query items($filters: ItemFilters, $orderBy: ItemsOrderByInput, $first: Int = 10, $page: Int) {
  items(first: $first, page: $page, orderBy: $orderBy, filters: $filters) {
    data {
      created_at
      id
      quantity
      sell_price
      unlimited_quantity
      active
      images {
        name
        path
      }
      quantityType {
        id
        name
      }
      product {
        product_description
        mpn
        manufacturer {
          name
        }
      }
      condition
    }
    paginatorInfo {
      total
      currentPage
      perPage
      lastPage
      count
      firstItem
      hasMorePages
      lastItem
      count
    }
  }
}
    `;
export const ManufacturersDocument = gql`
    query manufacturers($title: String, $first: Int, $page: Int, $onlyWithActiveItems: Boolean) {
  manufacturers(
    title: $title
    first: $first
    page: $page
    onlyWithActiveItems: $onlyWithActiveItems
  ) {
    data {
      id
      name
    }
    paginatorInfo {
      total
    }
  }
}
    `;
export const MeDocument = gql`
    query me {
  me {
    id
    email
    first_name
    last_name
    notificationsCategories {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
          }
        }
      }
    }
    organization {
      name
    }
    permissions {
      id
      name
      description
    }
    userAddress {
      id
      is_primary
      address {
        country {
          name
        }
        city
        addr1
        addr2
      }
    }
  }
}
    `;
export const OrderDocument = gql`
    query order($id: ID) {
  order(id: $id) {
    id
    order_subtotal
    total
    shipping
    taxes
    fees {
      id
      amount
      type {
        id
        name
      }
    }
    customer {
      organization {
        name
      }
    }
    shippingAddress {
      id
      zip
      attention_to
      addr1
      addr2
      city
      state_or_province
      postal_code
      country {
        id
        name
      }
    }
    billingAddress {
      id
      zip
      attention_to
      addr1
      addr2
      city
      state_or_province
      postal_code
      country {
        id
        name
      }
    }
    paymentSource {
      id
      name
    }
    items {
      id
      quantity
      sell_price
      item {
        product {
          mpn
          manufacturer {
            name
          }
          product_description
        }
      }
    }
  }
}
    `;
export const OrdersDocument = gql`
    query orders($first: Int = 100000, $filters: OrderFilters, $page: Int) {
  orders(first: $first, filters: $filters, page: $page) {
    paginatorInfo {
      total
      count
      currentPage
      total
      hasMorePages
      perPage
    }
    data {
      referral_id
      referral_type_id
      order_subtotal
      created_at
      items {
        id
        item {
          id
          sell_price
          status
          quantity
          unlimited_quantity
          images {
            path
          }
          product {
            product_description
            product_details
            manufacturer {
              name
            }
            mpn
          }
        }
        quantity
        sell_price
        vendor_invoice
        resell
        referral_id
        referralType {
          id
          name
        }
        shipment_id
      }
      id
      status {
        id
        name
        active
      }
      shippingAddress {
        id
        zip
        attention_to
        addr1
        addr2
        city
        state_or_province
        postal_code
        country {
          id
          name
        }
      }
      billingAddress {
        id
        zip
        attention_to
        addr1
        addr2
        city
        state_or_province
        postal_code
        country {
          id
          name
        }
      }
      paymentSource {
        id
        name
      }
    }
  }
}
    `;
export const OrganizationTypesDocument = gql`
    query organizationTypes($first: Int, $page: Int) {
  organizationTypes(first: $first, page: $page) {
    data {
      id
      name
    }
    paginatorInfo {
      currentPage
      lastPage
    }
  }
}
    `;
export const OrganizationsDocument = gql`
    query organizations($first: Int = 1000, $page: Int, $mine: Boolean) {
  organizations(first: $first, page: $page, mine: $mine) {
    paginatorInfo {
      count
      total
    }
    data {
      id
      parent {
        id
        name
      }
      name
      phone
    }
  }
}
    `;
export const OwnAuctionsDocument = gql`
    query ownAuctions($filters: AuctionsFilters, $first: Int = 10000, $page: Int) {
  ownAuctions(filters: $filters, first: $first, page: $page) {
    paginatorInfo {
      total
    }
    data {
      id
      name
      description
      begin_at
      end_at
      created_at
      updated_at
      lots {
        id
        name
        description
        bid_count
        ownBidsCount
        bid_increment
        reserve_amount
        active
        winning_bid_amount
        items {
          id
          product {
            manufacturer {
              name
            }
            mpn
            product_description
          }
        }
      }
    }
  }
}
    `;
export const PaymentSourcesDocument = gql`
    query paymentSources($first: Int, $page: Int) {
  paymentSources(first: $first, page: $page) {
    paginatorInfo {
      count
      total
    }
    data {
      id
      name
    }
  }
}
    `;
export const ProductsDocument = gql`
    query products($manufacturer_id: ID, $mpn: String, $first: Int = 4000, $page: Int) {
  products(
    manufacturer_id: $manufacturer_id
    mpn: $mpn
    first: $first
    page: $page
  ) {
    data {
      id
      mpn
      product_description
    }
  }
}
    `;
export const QuantityTypesDocument = gql`
    query quantityTypes($first: Int, $active: Boolean) {
  quantityTypes(first: $first, active: $active) {
    data {
      id
      name
    }
  }
}
    `;
export const SearchItemsDocument = gql`
    query searchItems($filters: ItemFilters, $first: Int = 50) {
  items(first: $first, filters: $filters) {
    data {
      id
      product {
        product_description
        mpn
        manufacturer {
          name
        }
      }
    }
  }
}
    `;
export const UserAddressesDocument = gql`
    query userAddresses($first: Int, $page: Int) {
  userAddresses(first: $first, page: $page) {
    data {
      id
      is_primary
      type
      address {
        id
        zip
        attention_to
        addr1
        addr2
        city
        state_or_province
        postal_code
        country {
          id
          name
          abbreviation
        }
      }
    }
    paginatorInfo {
      total
    }
  }
}
    `;
export const UserStatisticsDocument = gql`
    query userStatistics($organization_ids: [ID], $date_from: Date, $date_to: Date, $view_by: SellerStatisticsViewEnum) {
  userStatistics(
    filters: {organization_ids: $organization_ids, view_by: $view_by, date_from: $date_from, date_to: $date_to}
  ) {
    soldTotalAmount
    soldGraphData {
      total_amount
      count
      date
    }
    purchasedTotalAmount
    purchasedGraphData {
      total_amount
      count
      date
    }
    listedTotalAmount
    listedGraphData {
      total_amount
      count
      date
    }
  }
}
    `;
export const WinLotsDocument = gql`
    query winLots($filters: AuctionLotFilters, $first: Int = 10000, $page: Int, $orderBy: AuctionOrderByInput) {
  winLots(filters: $filters, first: $first, page: $page, orderBy: $orderBy) {
    paginatorInfo {
      total
    }
    data {
      id
      name
      description
      bid_count
      ownBidsCount
      bid_increment
      reserve_amount
      active
      winner {
        id
        first_name
        last_name
      }
      bid {
        id
        amount
      }
      winning_bid_amount
      auction {
        id
        name
        description
        creator {
          id
          first_name
          last_name
        }
        begin_at
        end_at
        created_at
        updated_at
        lots {
          id
          name
          description
          bid_count
          ownBidsCount
          bid_increment
          reserve_amount
          active
          winning_bid_amount
          items {
            images {
              id
              name
              path
            }
            id
            product {
              manufacturer {
                name
              }
              mpn
              product_description
            }
          }
        }
      }
      items {
        unlimited_quantity
        quantity
        condition
        images {
          id
          name
          path
        }
        id
        product {
          manufacturer {
            name
          }
          mpn
          product_description
        }
      }
    }
  }
}
    `;
export const OnCartUpdatedDocument = gql`
    subscription onCartUpdated($cartId: ID) {
  cartUpdated(cartId: $cartId) {
    id
    subtotal
    items {
      id
      item_id
      quantity
      sell_price
      item {
        quantity
        unlimited_quantity
        images {
          path
        }
        product {
          manufacturer {
            name
          }
          mpn
        }
      }
    }
  }
}
    `;
export const OnNewOutbidDocument = gql`
    subscription onNewOutbid {
  newOutbid {
    id
    customer {
      id
    }
    lot {
      id
      name
    }
    amount
    automatic_increment
    max_bid_flat
    bid_count
  }
}
    `;