
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'v-total-records-found',
  props: {
    total: {
      type: Number,
      required: true
    }
  }
});
