import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-358e8e65"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "swiper-carousel swiper-theme" }
const _hoisted_2 = { class: "swiper-wrapper" }
const _hoisted_3 = {
  type: "button",
  role: "presentation",
  class: "swiper-prev"
}
const _hoisted_4 = {
  type: "button",
  role: "presentation",
  class: "swiper-next"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "pvCarousel",
      swiper: "sliderOption",
      onResize: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resizeHandler && _ctx.resizeHandler(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (_ctx.sliderOption?.navigation && !_ctx.sliderOption.isCustomNav)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["swiper-nav", { disabled: _ctx.isNavDisabled }])
          }, [
            _createElementVNode("button", _hoisted_3, [
              _createElementVNode("i", {
                class: _normalizeClass(_ctx.sliderOption.navIcon.prev)
              }, null, 2)
            ]),
            _createElementVNode("button", _hoisted_4, [
              _createElementVNode("i", {
                class: _normalizeClass(_ctx.sliderOption.navIcon.next)
              }, null, 2)
            ])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.sliderOption?.pagination)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["swiper-dots swiper-pagination-bullets", { disabled: _ctx.isDotDisabled }])
          }, null, 2))
        : _createCommentVNode("", true)
    ], 544)
  ]))
}