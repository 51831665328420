
import { ON_CHANGE_TAB } from '@/const/events';
import { itemsCreateStore as store } from '@/store/item-create';
import { Emitter, EventType } from 'mitt';
import { Image } from '@/types/item-create';
import { defineComponent, inject, ref } from 'vue';
import EditImageModal from '../EditImageModal.vue';
import ListComplete from '../ListComplete.vue';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const uniqueId = require('lodash/uniqueId');

export default defineComponent({
  components: { EditImageModal, ListComplete },
  name: 'images-tab',
  setup() {
    const openModal = ref(false);
    const currentFile = ref<File | Record<string, never>>({});

    const emitter: Emitter<Record<EventType, unknown>> | undefined = inject('emitter');
    const onChangeTab = (val: string): void => {
      emitter?.emit(ON_CHANGE_TAB, val);
    };

    const setImage = (e) => {
      // eslint-disable-next-line prefer-destructuring
      currentFile.value = e.target.files[0];
      store.images = [...store.images, { image: e.target.files[0], id: uniqueId(), name: e.target.files[0].name }];
      openModal.value = true;
    };

    const closeModal = () => {
      openModal.value = false;
    };

    const imageUploaded = (image: string) => {
      store.images = [...store.images.slice(0, -1), { ...(store.images.slice(-1)[0] as unknown as Image), image }];
    };

    const removeFile = (id: string) => {
      store.images = [...store.images].filter((file) => file.id !== id);
    };

    return {
      onChangeTab,
      setImage,
      openModal,
      currentFile,
      closeModal,
      imageUploaded,
      store,
      removeFile
    };
  }
});
