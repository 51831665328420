
import { defineComponent, PropType, inject, ref, watch } from 'vue';
import { CREATE_ITEM, INPUT, ON_CHANGE_TAB } from '@/const/events';
import { Emitter, EventType } from 'mitt';
import Information from './Tabs/Information.vue';
import Details from './Tabs/Details.vue';
import WarrantyInformation from './Tabs/WarrantyInformation.vue';
import Specs from './Tabs/Specs.vue';
import Quantity from './Tabs/Quantity.vue';
import Complete from './Tabs/Complete.vue';
import Documents from './Tabs/Documents.vue';
import Images from './Tabs/Images.vue';

export default defineComponent({
  name: 'items-create-tabs',
  emits: [INPUT, CREATE_ITEM],
  components: {
    Information,
    Details,
    WarrantyInformation,
    Specs,
    Quantity,
    Complete,
    Documents,
    Images
  },
  props: {
    tabs: {
      type: Array as PropType<{ title: string; content: string }[]>,
      default: () => []
    },
    tabIndex: {
      type: Number,
      default: 0
    },
    isDisabledCompleteBtn: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const tabIndexVal = ref(props.tabIndex);

    const emitter: Emitter<Record<EventType, unknown>> | undefined = inject('emitter');

    emitter?.on(ON_CHANGE_TAB, (value) => {
      if (value === 'next') {
        tabIndexVal.value += 1;
      } else {
        tabIndexVal.value -= 1;
      }
    });

    const createItem = () => emit(CREATE_ITEM);

    watch(
      () => tabIndexVal.value,
      (newVal) => {
        emit(INPUT, newVal);
      }
    );

    return {
      tabIndexVal,
      createItem
    };
  }
});
