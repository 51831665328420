import { createStore, useStore as baseUseStore, Store } from 'vuex';
import { AuthState } from '@/types/auth';
import { AuctionState } from '@/types/auctions';
import { InjectionKey } from 'vue';
// eslint-disable-next-line import/no-cycle
import auth from './auth';
import auctions from './auctions';

export interface State {
  auth: AuthState;
  auctions: AuctionState;
}

// eslint-disable-next-line symbol-description
export const key: InjectionKey<Store<State>> = Symbol();

export default createStore<State>({
  modules: {
    auth,
    auctions
  }
});

export const useStore = (): Store<State> => baseUseStore(key);
