import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_select_option = _resolveComponent("b-form-select-option")!
  const _component_b_form_select = _resolveComponent("b-form-select")!

  return (_openBlock(), _createBlock(_component_b_form_select, {
    class: _normalizeClass(["v-base-select", { placeholder: !_ctx.internalValue }]),
    modelValue: _ctx.internalValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
    onInput: _ctx.onInput
  }, _createSlots({
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_b_form_select_option, {
          value: item.id,
          key: item.id,
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.name), 1)
          ]),
          _: 2
        }, 1032, ["value", "disabled"]))
      }), 128))
    ]),
    _: 2
  }, [
    (_ctx.placeholder)
      ? {
          name: "first",
          fn: _withCtx(() => [
            _createVNode(_component_b_form_select_option, {
              value: "",
              disabled: "",
              selected: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.placeholder), 1)
              ]),
              _: 1
            })
          ])
        }
      : undefined
  ]), 1032, ["modelValue", "onInput", "class"]))
}