/* eslint-disable camelcase */
// eslint-disable-next-line camelcase
export const setToken = (data: {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  user?: {
    id: string;
    first_name: string;
  };
}): void => {
  localStorage.setItem('han_jwt_token', data.access_token);
  if (data.user) {
    localStorage.setItem('first_name', data.user.first_name);
    localStorage.setItem('id', data.user.id);
  }

  const tokenexpiration: Date = new Date(new Date().getTime() + 1000 * 31536000);
  localStorage.setItem('expires_in', `${tokenexpiration}`);

  localStorage.setItem('refresh_token', data.refresh_token);
};

export const removeToken = (): void => {
  localStorage.removeItem('han_jwt_token');
  localStorage.removeItem('first_name');
  localStorage.removeItem('id');
  localStorage.removeItem('expires_in');
  localStorage.removeItem('refresh_token');
};

export const getRefreshToken = (): string | null => localStorage.getItem('refresh_token');
export const getExpiresIn = (): string | null => localStorage.getItem('expires_in');
export const token = (): string | null => localStorage.getItem('han_jwt_token');
export const userId = (): string | null => localStorage.getItem('id');
export const userFirstName = (): string | null => localStorage.getItem('first_name');

export default {};
