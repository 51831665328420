import { User } from '@/generated/graphql';

export interface AuthState {
  status: {
    loggedIn: boolean;
  };
  user: User | null;
  cartId: string;
  enabledCartUpdated: boolean;
  enabledNewOutbid: boolean;
  enabledCart: boolean;
  totalCount: string;
}

// eslint-disable-next-line no-shadow
export enum LoginActionsEnum {
  LoginSuccess = 'auth/loginSuccess',
  LoginFailure = 'auth/loginFailure',
  Logout = 'auth/logout',
  SetCartId = 'auth/setCartId',
  SetTotalCount = 'auth/setTotalCount'
}

// eslint-disable-next-line no-shadow
export enum UserPermissionEnum {
  MANAGE_INVENTORY = 'Manage Inventory',
  VIEW_GLOBAL_INVENTORY = 'View Global Inventory',
  REVIEW_SELLER = 'Reviewer Seller',
  DEFAULT_REVIEWER_SELLER = 'Default Reviewer Seller',
  ADD_TO_CART = 'Add to Cart',
  REVIEWER_BUYER = 'Reviewer Buyer',
  DEFAULT_REVIEWER_BUYER = 'Default Reviewer Buyer',
  RUN_REPORTS = 'Run Reports',
  AUCTION_STATUS = 'APPROVED',
  PAY_WITH_PO = 'Pay with PO',
  PAY_WITH_CC_OR_PAYPAL = 'Pay With CC or Paypal'
}

// eslint-disable-next-line no-shadow
export enum AuthGetterEnum {
  getUser = 'auth/getUser'
}
