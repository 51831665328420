import CategoriesMenu from '@/components/partials/CategoriesMenu.vue';
import CartPopup from '@/components/partials/shop/CartPopup.vue';
import Filters from '@/components/partials/shop/Filters.vue';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
import HeaderSearch from '@/components/partials/HeaderSearch.vue';
import MainMenu from '@/components/partials/MainMenu.vue';
import MiddleHeader from '@/components/MiddleHeader.vue';
import Products from '@/components/partials/shop/Products.vue';
import ProductListCard from '@/components/partials/shop/ProductListCard.vue';
import ProductGridCard from '@/components/partials/shop/ProductGridCard.vue';
import ProgressBar from '@/components/partials/cart/ProgressBar.vue';
import AddressModal from '@/components/partials/cart/AddressModal.vue';
import TermsAndConditionsModal from '@/components/partials/cart/TermsAndConditionsModal.vue';
import Media from '@/components/partials/product/Media.vue';
import Details from '@/components/partials/product/Details.vue';
import Description from '@/components/partials/product/Description.vue';
import ProfileDashboard from '@/components/partials/profile/ProfileDashboard.vue';
import ProductCard from '@/components/partials/selling/ProductCard.vue';
import ConfirmModal from '@/components/partials/selling/ConfirmModal.vue';
import Tabs from '@/components/partials/items-create/Tabs.vue';
// v-components
import vAccordion from '@/components/vAccordion.vue';
import vAutocomplete from '@/components/vAutocomplete.vue';
import vFormCheckbox from '@/components/vFormCheckbox.vue';
import vFormCheckboxGroup from '@/components/vFormCheckboxGroup.vue';
import vBreadcrumb from '@/components/vBreadcrumb.vue';
import vCard from '@/components/vCard.vue';
import vDatepicker from '@/components/vDatepicker.vue';
import vErrorText from '@/components/vErrorText.vue';
import vInputGroup from '@/components/vInputGroup.vue';
import vInput from '@/components/vInput.vue';
import vFormRadioGroup from '@/components/vFormRadioGroup.vue';
import vInputGroupPrepend from '@/components/vInputGroupPrepend.vue';
import vBaseSelect from '@/components/vBaseSelect.vue';
import vTree from '@/components/vTree.vue';
import vPagination from '@/components/vPagination.vue';
import vPerPage from '@/components/vPerPage.vue';
import vTotalRecordsFound from '@/components/vTotalRecordsFound.vue';
import vCarousel from '@/components/vCarousel.vue';
import vLoading from '@/components/vLoading.vue';
import vQuantityInput from '@/components/vQuantityInput.vue';
import vSearchFilter from '@/components/vFilters/vSearchFilter.vue';
import vSelectFilter from '@/components/vSelectFilter.vue';
import vTabs from '@/components/vTabs.vue';
import vCountdown from '@/components/vCountdown.vue';
import vSlider from '@/components/vSlider.vue';
import vCaptcha from '@/components/vCaptcha.vue';
import vDropdown from '@/components/vDropdown.vue';

export default [
  CategoriesMenu,
  Filters,
  HeaderSearch,
  MainHeader,
  MainFooter,
  MainMenu,
  MiddleHeader,
  vFormCheckbox,
  vFormCheckboxGroup,
  vBreadcrumb,
  vErrorText,
  vFormRadioGroup,
  vInput,
  vInputGroup,
  vInputGroupPrepend,
  vBaseSelect,
  vSearchFilter,
  vTree,
  Products,
  ProductListCard,
  ProductGridCard,
  vPagination,
  vPerPage,
  vTotalRecordsFound,
  vCarousel,
  vLoading,
  CartPopup,
  vQuantityInput,
  ProgressBar,
  AddressModal,
  TermsAndConditionsModal,
  vSelectFilter,
  Media,
  Details,
  Description,
  vTabs,
  vAutocomplete,
  ProfileDashboard,
  vCard,
  vAccordion,
  vDatepicker,
  ProductCard,
  ConfirmModal,
  Tabs,
  vCountdown,
  vSlider,
  vCaptcha,
  vDropdown
];
