import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_tabs = _resolveComponent("b-tabs")!

  return (_openBlock(), _createBlock(_component_b_tabs, {
    class: "tabs-menu mb-7",
    modelValue: _ctx.tabIndexVal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tabIndexVal) = $event))
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, idx) => {
        return (_openBlock(), _createBlock(_component_b_tab, {
          key: idx,
          title: tab?.title
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(tab?.content), {
              onCreateItem: _ctx.createItem,
              isDisabledCompleteBtn: _ctx.isDisabledCompleteBtn
            }, null, 8, ["onCreateItem", "isDisabledCompleteBtn"]))
          ]),
          _: 2
        }, 1032, ["title"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}