
import { computed, defineComponent, PropType, ref } from 'vue';
import Tree from 'vuejs-tree';
import { TreeCategory } from '@/types/tree';
import useSearchedTreeItems from '@/hooks/useSearchedTreeItems';
import { ITEMS_SEARCH } from '@/const/events';

export default defineComponent({
  name: 'v-tree',
  components: {
    Tree
  },
  emits: [ITEMS_SEARCH],
  props: {
    items: {
      type: Array as PropType<TreeCategory[]>,
      default: () => []
    },
    hasInputSearch: {
      type: Boolean,
      default: false
    },
    searchKey: {
      type: String
    }
  },
  setup(props, { emit }) {
    const { searchQuery, searchedTreeItems } = useSearchedTreeItems(props.items);

    // eslint-disable-next-line no-unused-vars
    const tree = ref<null | { getCheckedNodes: (arg0: string) => string[] }>(null);

    const checkedFunction = () => {
      emit(ITEMS_SEARCH, props.searchKey, tree?.value?.getCheckedNodes('id'));
    };

    const customStyles = computed(() => ({
      tree: {
        style: {
          height: 'auto',
          maxHeight: '300px',
          overflowY: 'visible',
          display: 'inline-block',
          textAlign: 'left',
          width: '100%'
        }
      },
      row: {
        style: {
          cursor: 'pointer',
          position: 'relative',
          width: '100%'
        },
        child: {
          class: '',
          style: {
            height: '35px',
            display: 'flex',
            alignItems: 'center',
            // whiteSpace: 'nowrap',
            borderBottom: '1px solid #f3f4f5'
          },
          active: {
            class: 'custom_row_active_class',
            style: {
              height: '35px'
            }
          }
        }
      },
      editNode: {
        class: 'custom_class',
        style: {}
      },
      deleteNode: {
        class: 'custom_class',
        style: {}
      }
    }));

    const customOptions = computed(() => ({
      treeEvents: {
        expanded: {
          state: true,
          fn: null
        },
        collapsed: {
          state: false,
          fn: null
        },
        selected: {
          state: false,
          fn: null
        },
        checked: {
          state: true,
          fn: checkedFunction
        }
      },
      events: {
        expanded: {
          state: true,
          fn: null
        },
        selected: {
          state: false,
          fn: null
        },
        checked: {
          state: true,
          fn: null
        },
        editableName: {
          state: false,
          fn: null,
          calledEvent: null
        }
      },
      addNode: { state: false, fn: null, appearOnHover: false },
      editNode: { state: false, fn: null, appearOnHover: false },
      deleteNode: { state: false, fn: null, appearOnHover: false },
      showTags: true
    }));

    return {
      searchQuery,
      customStyles,
      customOptions,
      searchedTreeItems,
      tree
    };
  }
});
