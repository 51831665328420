
import { defineComponent, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { AuthGetterEnum, LoginActionsEnum } from '@/types/auth';
import { AuctionsActionsEnum } from '@/types/auctions';
import {
  CartDocument,
  CartTypeEnum,
  ClearSubscriptionDocument,
  OnCartUpdatedDocument,
  OnNewOutbidDocument
} from '@/generated/graphql';
import { useQuery, useResult, useSubscription, useMutation } from '@vue/apollo-composable';
import { CART, HOME } from '@/const/routes';
import { OLD_CHANNELS } from '@/const/const';
import { showToast } from '@/helpers/toast-handler';

const element = document.querySelector('body');
if (element) element.classList.add('loaded');

export default defineComponent({
  name: 'main-header',
  setup() {
    const store = useStore();
    const { mutate } = useMutation(ClearSubscriptionDocument);

    const isUserLoggedIn = computed(() => store.state.auth.status.loggedIn);
    const user = computed(() => store.getters[AuthGetterEnum.getUser]);

    const { result, refetch } = useQuery(CartDocument, { type: CartTypeEnum.Items }, () => ({
      enabled: isUserLoggedIn.value
    }));
    const cartId = useResult(result, null, (data) => data.cart.id);
    const totalCount = useResult(result, null, (data) =>
      data.cart.items.reduce((acc, cur) => acc + Number(cur?.quantity), 0)
    );

    const showMobileMenu = (): void => {
      const el = document.querySelector('body');
      if (el) el.classList.add('menu-active');
    };

    const showMobileSearch = (e: { currentTarget: Element }): void => {
      if (e.currentTarget) {
        const headerSearch = e.currentTarget.closest('.header-search');
        if (headerSearch !== null) {
          headerSearch.classList.add('show');
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          headerSearch.querySelector('.header-search-wrapper').classList.add('show');
        }
      }
    };

    const setCartId = (id) => store.dispatch(LoginActionsEnum.SetCartId, id);
    const setTotalCount = (count) => store.dispatch(LoginActionsEnum.SetTotalCount, count);
    const enabledNewOutbid = computed(() => store.state.auth.enabledNewOutbid);
    const enabledCartUpdated = computed(() => store.state.auth.enabledCartUpdated);

    const { result: cartUpdatedResult } = useSubscription(
      OnCartUpdatedDocument,
      () => ({
        cartId: cartId.value
      }),
      () => ({
        enabled: enabledCartUpdated.value
      })
    );

    watch(cartUpdatedResult, (data) => {
      if (data.cartUpdated) {
        refetch({ type: CartTypeEnum.Items });
      }
    });

    const { result: bidCreatedResult } = useSubscription(OnNewOutbidDocument, null, () => ({
      enabled: enabledNewOutbid.value
    }));

    watch(bidCreatedResult, (data) => {
      if (data.newOutbid) {
        store.dispatch(AuctionsActionsEnum.setMaxBid, data?.newOutbid?.amount);
        if (user.value.id !== data?.newOutbid?.customer.id && data?.newOutbid?.bid_count > 0) {
          showToast(`Auction Update: You have been outbid. Lot ${data?.newOutbid?.lot?.name}`, 'warning', 10000);
        }
        if (user.value.id === data?.newOutbid?.customer.id && data?.newOutbid?.automatic_increment) {
          showToast(`Your bid will be incremented automatically`);
        }
      }
    });

    function cleanOldSub() {
      const oldSubs = JSON.parse(localStorage.getItem(OLD_CHANNELS) || '[]');
      if (oldSubs.length > 0) {
        mutate({
          channelNames: oldSubs
        }).then(() => localStorage.removeItem(OLD_CHANNELS));
      }
    }

    onMounted(() => {
      cleanOldSub();
    });

    watch(cartId, (newVal) => {
      setCartId(newVal);
    });
    watch(totalCount, (newVal) => {
      setTotalCount(newVal);
    });

    return {
      showMobileSearch,
      showMobileMenu,
      totalCount,
      CART,
      HOME,
      isUserLoggedIn
    };
  }
});
