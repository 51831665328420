
import { ItemsDocument, OrdersDocument, WinLotsDocument } from '@/generated/graphql';
import { userFirstName, userId } from '@/helpers/token';
import { useQuery, useResult } from '@vue/apollo-composable';
import { computed, defineComponent } from 'vue';
// eslint-disable-next-line import/no-cycle
import usePermissions from '@/hooks/usePermissions';
// eslint-disable-next-line import/no-cycle
import useRoles from '@/hooks/useRoles';
import { UserPermissionEnum } from '@/types/auth';
import DashboardReporting from './DashboardReporting.vue';

export default defineComponent({
  name: 'profile-dashboard',
  components: {
    DashboardReporting
  },
  props: {
    products: Array
  },
  setup() {
    const { result: ordersResult } = useQuery(OrdersDocument);
    const ordersCount = useResult(ordersResult, null, (data) => data.orders.paginatorInfo.total);

    const { result: ownAuctionsResult } = useQuery(WinLotsDocument, {
      filters: {
        winner_id: userId()
      }});
    const wonAuctionsCount = useResult(ownAuctionsResult, null, (data) => data.winLots.paginatorInfo.total);

    const { result: itemsResult, loading } = useQuery(ItemsDocument, {
      first: 10000,
      filters: { sellerId: userId(), inStock: true }
    });
    const itemsCount = useResult(itemsResult, null, (data) => data.items.paginatorInfo.total);

    // user permissions
    const { userCan } = usePermissions();
    const { userHasRole, auctionStatus } = useRoles();

    const accessSellTile = computed(
      () =>
        userCan(UserPermissionEnum.MANAGE_INVENTORY) ||
        userCan(UserPermissionEnum.VIEW_GLOBAL_INVENTORY) ||
        userHasRole(UserPermissionEnum.REVIEW_SELLER) ||
        userHasRole(UserPermissionEnum.DEFAULT_REVIEWER_SELLER)
    );

    const accessPurchaseTile = computed(
      () =>
        userCan(UserPermissionEnum.ADD_TO_CART) ||
        userHasRole(UserPermissionEnum.REVIEWER_BUYER) ||
        userHasRole(UserPermissionEnum.DEFAULT_REVIEWER_BUYER)
    );

    const accessAuctionTile = computed(
      () => auctionStatus.value === UserPermissionEnum.AUCTION_STATUS && userCan(UserPermissionEnum.ADD_TO_CART)
    );

    const viewReports = computed(() => userCan(UserPermissionEnum.RUN_REPORTS));

    return {
      userFirstName,
      wonAuctionsCount,
      ordersCount,
      itemsCount,
      accessSellTile,
      viewReports,
      accessAuctionTile,
      accessPurchaseTile,
      loading
    };
  }
});
