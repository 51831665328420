
import { defineComponent, PropType, watch, ref } from 'vue';
import { ITEMS_SEARCH, SEARCH, ITEM_SELECT, DISPLAY_ITEM } from '@/const/events';
import { Item } from '@/generated/graphql';

export default defineComponent({
  name: 'v-autocomplete',
  emits: [ITEMS_SEARCH, SEARCH, ITEM_SELECT, DISPLAY_ITEM],
  props: {
    loading: {
      type: Boolean
    },
    itemText: {
      type: String
    },
    items: {
      type: Array as PropType<Item[]>
    },
    keywords: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const displayItems = ref<Item[] | []>([]);
    const selected = ref('');

    watch(
      () => props.items,
      (newVal) => {
        if(newVal) {
          displayItems.value = [...newVal];
        }
      }
    );

    const getItems = (data) => {
      emit(SEARCH, data);
    };

    const onSelect = (data) => {
      emit(ITEM_SELECT, data?.id);
      selected.value = '';
    };


    return {
      getItems,
      onSelect,
      displayItems,
      selected
    };
  }
});
