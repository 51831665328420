
import { defineComponent, inject, watch } from 'vue';
import Toggle from '@vueform/toggle';
import { Emitter, EventType } from 'mitt';
import { ON_CHANGE_TAB } from '@/const/events';
import { itemsCreateStore as store } from '@/store/item-create';
import { QuantityTypesDocument } from '@/generated/graphql';
import { useQuery, useResult } from '@vue/apollo-composable';

export default defineComponent({
  name: 'quantity-tab',
  components: { Toggle },
  setup() {
    const emitter: Emitter<Record<EventType, unknown>> | undefined = inject('emitter'); // Inject `emitter`
    const onChangeTab = (val: string): void => {
      emitter?.emit(ON_CHANGE_TAB, val);
    };

    const { result } = useQuery(QuantityTypesDocument, { first: 50, active: true });
    const quantityTypes = useResult(result, null, (data) => data.quantityTypes.data);

    watch(
      () => store.unlimitedQuantity,
      (newVal) => {
        if (newVal) {
          if (store.quantity) {
            store.quantity = null;
          }
          if (store.sellPrice || store.sellPrice === 0) {
            store.sellPrice = null;
          }
        }
      }
    );

    return {
      store,
      onChangeTab,
      quantityTypes
    };
  }
});
