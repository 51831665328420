import { computed, ComputedRef } from 'vue';
import store from '@/store';
import find from 'lodash/find';
import { Maybe, Role } from '@/generated/graphql';

export default function useRoles(): {
  userHasRole: (role: string) => boolean;
  userRoles: ComputedRef<Maybe<Maybe<Role>[]> | undefined>;
  auctionStatus: ComputedRef<string>;
  } {
  const userRoles = computed(() => store.state.auth.user?.roles || []);
  const auctionStatus = computed(() => store.state.auth.user?.auction_status || '');
  const userHasRole = (role) => !!find(store.state.auth.user?.roles, { name: role });

  return {
    userHasRole,
    userRoles,
    auctionStatus
  };
}
