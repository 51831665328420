export const SET_MODAL = 'set-modal';
export const UPDATE = 'update';
export const UPDATE_MODEL_VALUE = 'update:modelValue';
export const ITEMS_SEARCH = 'items-search';
export const UPDATE_VALUE = 'update:value';
export const UPDATED_PAGE = 'updated-page';
export const UPDATED_LIMIT = 'updated-limit';
export const CHANGE_QTY = 'change-qty';
export const ON_SEARCH = 'on-search';
export const ITEM_SELECT = 'item-select';
export const DISPLAY_ITEM = 'display-item';
export const DELETE_ITEM = 'delete-item';
export const INPUT = 'input';
export const ON_CHANGE_TAB = 'on-change-tab';
export const IMAGE_UPLOADED = 'image-uploaded';
export const CREATE_ITEM = 'create-item';
export const TIME_EXPIRED = 'time-expired';
export const BID_SUBMITTED = 'bid-submitted';
export const ADD_TO_CART = 'add-to-cart';
export const UPDATED_CART = 'updated-cart';
export const CLEAR_FILTER = 'clear-filter';
export const ON_HANDLE_CLICK = 'on-handle-click';
export const UPDATE_QTY = 'update-qty';
export const SEARCH = 'search';

export default {};
