import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e25b838"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-card product-default inner-quickview inner-icon" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "text text-center" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, [
        _createVNode(_component_fa, { icon: _ctx.icon }, null, 8, ["icon"]),
        _createTextVNode(_toDisplayString(_ctx.headerTitle), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, { to: _ctx.route }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.count)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(_ctx.count), 1))
              : _createCommentVNode("", true),
            (_ctx.middleIcon)
              ? (_openBlock(), _createBlock(_component_fa, {
                  key: 1,
                  icon: _ctx.middleIcon,
                  class: "mt-3 mb-1"
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true),
            _createElementVNode("p", null, _toDisplayString(_ctx.title), 1),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]),
        _: 3
      }, 8, ["to"])
    ])
  ]))
}