
import { ConditionEnum, ManufacturersDocument, ProductsDocument } from '@/generated/graphql';
import { useQuery, useResult } from '@vue/apollo-composable';
import Toggle from '@vueform/toggle';
import { MANUFACTURER, MPN } from "@/const/const";
import { defineComponent, inject, watch, ref, computed, onMounted } from 'vue';
import { Emitter, EventType } from 'mitt';
import { ON_CHANGE_TAB } from '@/const/events';
import { debounce, uniqueId } from 'lodash';
import { itemsCreateStore as store } from '@/store/item-create';
import { SelectItem } from '@/types/item-create';
import { capitalizeFirstLetter } from '@/helpers/capitalize-first-letter';
import AddProductModal from '../AddProductModal.vue';
import DatePicker from '../DatePicker.vue';
import 'vue3-treeselect/dist/vue3-treeselect.css';
import '@vueform/toggle/themes/default.css';

export default defineComponent({
  components: { DatePicker, Toggle, AddProductModal },
  name: 'information-tab',
  setup() {
    const manufacturerError = ref('');
    const mpnError = ref('');
    const productSearchValue = ref('');
    const openProductModal = ref(false);
    const manufacturerValue = ref('');
    const mpnValue = ref('');

    const conditions = capitalizeFirstLetter(ConditionEnum);

    const emitter: Emitter<Record<EventType, unknown>> | undefined = inject('emitter'); // Inject `emitter`
    const onChangeTab = (val: string): void => {
      emitter?.emit(ON_CHANGE_TAB, val);
    };

    const {
      result: productsResult,
      loading: productsLoading,
      refetch: productsRefetch
    } = useQuery(
      ProductsDocument,
      () => ({ manufacturer_id: store.manufacturer?.id }),
      () => ({
        enabled: !!store.manufacturer?.id
      })
    );
    const mpn = useResult(productsResult, null, (data) =>
      data.products.data.reduce((acc, cur) => {
        acc.push({
          name: cur.mpn,
          id: uniqueId(),
          productName: cur.product_description,
          productId: cur.id
        });
        return acc;
      }, [])
    );
    const productName = computed(() => store.mpn?.productName);

    const {
      result: manufacturersResult,
      loading: manufacturersLoading,
      refetch: maufacturersRefetch
    } = useQuery(ManufacturersDocument, {
      page: 1,
      first: 10000,
      onlyWithActiveItems: false
    });
    const manufacturers = useResult(manufacturersResult, null, (data) =>
      data.manufacturers.data.reduce((acc, cur) => {
        acc.push({
          name: cur.name,
          id: cur.id
        });
        return acc;
      }, [])
    );

    const disabledMpn = computed(() => !(mpn as unknown as Array<{ name: string }>)?.length);

    onMounted(() => {
      if (manufacturers) {
        store.visibleManufacturers = manufacturers.value as SelectItem[];
      }
    });

    const onManufacturersSearch = debounce((value = '') => {
      const vars: {
        page: number;
        first: number;
        onlyWithActiveItems: boolean;
        title?: string;
      } = {
        page: 1,
        first: 10000,
        onlyWithActiveItems: true,
        title: `%${value}%`
      };

      maufacturersRefetch(vars);
      manufacturerValue.value = value;
    }, 500);

    const onMpnSearch = debounce((value = '') => {
      if (value) {
        productSearchValue.value = value;
        const vars = {
          manufacturer_id: store.manufacturer?.id,
          mpn_search: `%${value}%`
        };
        productsRefetch(vars);
        mpnValue.value = value;
      }
    }, 500);

    const setModal = () => {
      openProductModal.value = false;
    };

    const openModal = (key) => {
      if (key === MANUFACTURER) {
        store.manufacturer.name = manufacturerValue.value;
      } else {
        store.mpn.name = mpnValue.value;
      }

      openProductModal.value = true;
    };

    watch(
      () => store.manufacturer?.id,
      (newVal) => {
        productsRefetch({ manufacturer_id: newVal });
        store.mpn = { name: '', id: '', productId: '', productName: '' };
        // if (!newVal) {
        //   manufacturerError.value = 'This field is required';
        // }
        // if (newVal && manufacturerError.value) {
        //   manufacturerError.value = '';
        // }
      }
    );

    return {
      conditions,
      mpn,
      mpnError,
      manufacturerError,
      onChangeTab,
      store,
      manufacturers,
      manufacturersLoading,
      onManufacturersSearch,
      productsLoading,
      disabledMpn,
      onMpnSearch,
      productName,
      productSearchValue,
      openProductModal,
      setModal,
      openModal,
      MPN,
      MANUFACTURER
    };
  }
});
