
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'v-error-text',
  props: {
    id: {
      type: String,
      default: 'v-error-text'
    },
    size: {
      type: String,
      default: '14px'
    },
    variant: {
      type: String,
      default: 'danger'
    },
    error: {
      type: [String, Error, Object]
    }
  }
});
