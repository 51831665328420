import { createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b58505c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-single-qty" }
const _hoisted_2 = { class: "input-group bootstrap-touchspin bootstrap-touchspin-injected" }
const _hoisted_3 = { class: "input-group-btn input-group-prepend" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { for: "qty" }
const _hoisted_6 = ["max", "disabled"]
const _hoisted_7 = { class: "input-group-btn input-group-append" }
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_error_text = _resolveComponent("v-error-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-outline btn-down-icon bootstrap-touchspin-down",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.minusQty && _ctx.minusQty(...args))),
          disabled: (_ctx.currentQty && _ctx.currentQty === 1) || !_ctx.product?.sell_price
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("label", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass(["horizontal-quantity form-control bg-transparent", { 'is-invalid': !_ctx.validQty }]),
          type: "number",
          min: 1,
          max: _ctx.totalAvailable,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentQty) = $event)),
          id: "qty",
          disabled: !_ctx.validQty || !_ctx.product?.sell_price
        }, null, 10, _hoisted_6), [
          [
            _vModelText,
            _ctx.currentQty,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("span", _hoisted_7, [
        _createElementVNode("button", {
          class: "btn btn-outline btn-up-icon bootstrap-touchspin-up",
          type: "button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.plusQty && _ctx.plusQty(...args))),
          disabled: _ctx.currentQty >= _ctx.totalAvailable || !_ctx.product?.sell_price
        }, null, 8, _hoisted_8)
      ])
    ]),
    _createVNode(_component_v_error_text, { error: _ctx.error }, null, 8, ["error"])
  ]))
}