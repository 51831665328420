
import { ITEMS_SEARCH, UPDATE_MODEL_VALUE } from '@/const/events';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'v-search-filter',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    searchKey: {
      type: String
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      searchValue: ''
    };
  },
  methods: {
    onSearch(ev) {
      this.$emit(ITEMS_SEARCH, this.searchKey, ev.target.value);
    },
    keydownHandler(ev) {
      if (ev.which === 13) {
        this.$emit(ITEMS_SEARCH, this.searchKey, ev.target.value);
      }
    },
    onInput(data) {
      this.$emit(UPDATE_MODEL_VALUE, data);
    }
  },
  mounted() {
    if (this.modelValue) this.searchValue = this.modelValue;
  },
  watch: {
    modelValue(newVal) {
      this.searchValue = newVal;
    }
  }
});
