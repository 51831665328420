import { createRouter, createWebHistory, NavigationGuardNext, RouteRecordRaw } from 'vue-router';
import {
  AUCTIONS,
  AUCTION_DETAILS,
  HOME,
  LOGIN,
  LOT_DETAILS,
  PROFILE,
  REGISTER,
  REGISTER_SUCCESS
} from '@/const/routes';
import { token } from '@/helpers/token';
import { UserPermissionEnum } from '@/types/auth';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import { AuctionStatusEnum } from '@/generated/graphql';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/pages/Register.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/register/success',
    name: 'RegisterSuccess',
    component: () => import('@/pages/RegisterSuccess.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/email-verify/:code',
    name: 'VerifyEmail',
    component: () => import('@/pages/VerifyEmail.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/confirm-reset-password',
    name: 'ConfirmResetPassword',
    component: () => import('@/pages/ConfirmResetPassword.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/pages/ForgotPassword.vue'),
    meta: {}
  },
  {
    path: '/guarantee',
    name: 'Guarantee',
    component: () => import('@/pages/Guarantee.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('@/pages/FAQ.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: () => import('@/pages/Contact.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('@/pages/Shop.vue'),
    meta: {
      authorize: []
    },
    children: [
      {
        path: 'grid',
        component: () => import('@/pages/Shop.vue')
      }
    ]
  },
  {
    path: '/shop/:id',
    name: 'Product',
    component: () => import('@/pages/Product.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/pages/Cart.vue'),
    meta: {
      authorize: [
        UserPermissionEnum.ADD_TO_CART,
        UserPermissionEnum.REVIEWER_BUYER,
        UserPermissionEnum.DEFAULT_REVIEWER_BUYER
      ]
    }
  },
  {
    path: '/cart/checkout',
    name: 'Checkout',
    component: () => import('@/pages/Checkout.vue'),
    meta: {
      authorize: [
        UserPermissionEnum.ADD_TO_CART,
        UserPermissionEnum.REVIEWER_BUYER,
        UserPermissionEnum.DEFAULT_REVIEWER_BUYER
      ]
    }
  },
  {
    path: '/cart/order/:id',
    name: 'Order',
    component: () => import('@/pages/Order.vue'),
    meta: {
      authorize: [
        UserPermissionEnum.ADD_TO_CART,
        UserPermissionEnum.REVIEWER_BUYER,
        UserPermissionEnum.DEFAULT_REVIEWER_BUYER
      ]
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/pages/Home.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/profile',
    component: () => import('@/pages/Profile.vue'),
    meta: {
      requiresAuth: true,
      authorize: []
    },
    children: [
      {
        path: '',
        name: 'Profile',
        component: () => import('@/components/partials/profile/ProfileDashboard.vue'),
        meta: {
          requiresAuth: true,
          authorize: []
        }
      },
      {
        path: 'buying',
        name: 'Buying',
        component: () => import('@/pages/Buying.vue'),
        meta: {
          requiresAuth: true,
          authorize: [
            UserPermissionEnum.ADD_TO_CART,
            UserPermissionEnum.REVIEWER_BUYER,
            UserPermissionEnum.DEFAULT_REVIEWER_BUYER
          ]
        }
      },
      {
        path: 'selling',
        name: 'Selling',
        component: () => import('@/pages/Selling.vue'),
        meta: {
          requiresAuth: true,
          authorize: [
            UserPermissionEnum.MANAGE_INVENTORY,
            UserPermissionEnum.VIEW_GLOBAL_INVENTORY,
            UserPermissionEnum.REVIEW_SELLER,
            UserPermissionEnum.DEFAULT_REVIEWER_SELLER
          ]
        }
      },
      {
        path: 'items-create/:id?',
        name: 'ItemsCreate',
        component: () => import('@/pages/ItemsCreate.vue'),
        meta: {
          requiresAuth: true,
          authorize: [
            UserPermissionEnum.MANAGE_INVENTORY,
            UserPermissionEnum.VIEW_GLOBAL_INVENTORY,
            UserPermissionEnum.REVIEW_SELLER,
            UserPermissionEnum.DEFAULT_REVIEWER_SELLER
          ]
        }
      },
      {
        path: 'management',
        name: 'Management',
        component: () => import('@/pages/Management.vue'),
        meta: {
          requiresAuth: true,
          authorize: []
        }
      }
    ]
  },
  {
    path: '/auctions/:status?',
    name: 'Auctions',
    component: () => import('@/pages/Auctions.vue'),
    meta: {
      requiresAuth: true,
      authorize: [UserPermissionEnum.AUCTION_STATUS, UserPermissionEnum.ADD_TO_CART]
    }
  },
  {
    path: '/auction/:id',
    name: 'AuctionDetails',
    component: () => import('@/pages/AuctionDetails.vue'),
    meta: {
      requiresAuth: true,
      authorize: [UserPermissionEnum.AUCTION_STATUS, UserPermissionEnum.ADD_TO_CART]
    }
  },
  {
    path: '/auction-history/:id',
    name: 'AuctionHistory',
    component: () => import('@/pages/AuctionHistory.vue'),
    meta: {
      requiresAuth: true,
      authorize: [UserPermissionEnum.AUCTION_STATUS, UserPermissionEnum.ADD_TO_CART]
    }
  },
  {
    path: '/auctions/:id/lot/:lotId',
    name: 'LotDetails',
    component: () => import('@/pages/LotDetails.vue'),
    meta: {
      requiresAuth: true,
      authorize: [UserPermissionEnum.AUCTION_STATUS, UserPermissionEnum.ADD_TO_CART]
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/layouts/error.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
  // scrollBehavior() {
  //   return { left: 0, top: 0 };
  // }
});

router.beforeEach((to, _from, next): NavigationGuardNext | Promise<NavigationGuardNext> | void => {
  const authenticatedUser = token();
  const { authorize } = to.meta;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const permissions: string[] = [];
  store.state.auth.user?.permissions?.forEach((permission) => permission?.name && permissions.push(permission.name));
  store.state.auth.user?.roles?.forEach((role) => role?.name && permissions.push(role.name));

  if (requiresAuth && !authenticatedUser && to.name !== LOGIN && to.name !== REGISTER) {
    return next({ name: LOGIN });
  }

  if (authenticatedUser && (to.name === LOGIN || to.name === REGISTER || to.name === REGISTER_SUCCESS)) {
    return next({ name: PROFILE });
  }

  if (authenticatedUser && authorize && (authorize as string[]).length) {
    if (permissions.filter((role) => (authorize as string[]).includes(role)).length) {
      return next();
    }
    return next({ name: HOME });
  }

  if (authenticatedUser && (to.name === AUCTIONS || to.name === AUCTION_DETAILS || to.name === LOT_DETAILS)) {
    if (
      store.state.auth.user?.auction_status === AuctionStatusEnum.Approved &&
      permissions.filter((role) => (authorize as string[]).includes(role)).length
    ) {
      return next();
    }
    return next({ name: HOME });
  }
  return next();
});

export default router;
