import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tabs-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_editor = _resolveComponent("vue-editor")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_vue_editor, {
        modelValue: _ctx.store.warranty,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.store.warranty) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeTab('prev'))),
        class: "tabs-actions btn btn-secondary"
      }, "Prev"),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChangeTab('next'))),
        class: "tabs-actions btn btn-secondary"
      }, "Next")
    ])
  ]))
}