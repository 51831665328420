import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    modelValue: _ctx.store.expirationData,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.store.expirationData) = $event)),
    format: _ctx.format,
    minDate: new Date()
  }, null, 8, ["modelValue", "format", "minDate"]))
}