
import { defineComponent } from 'vue';
import { CART, CHECKOUT } from '@/const/routes';

export default defineComponent({
  name: 'cart-popup',
  setup() {
    return {
      CART,
      CHECKOUT
    };
  },
  methods: {
    getImage(images) {
      if (images.length) {
        return images[0].path;
      }
      // eslint-disable-next-line global-require
      return require('@/static/images/generic.jpeg');
    }
  }
});
