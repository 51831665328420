import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_select, {
    onSearch: _ctx.getItems,
    options: _ctx.displayItems,
    label: _ctx.itemText,
    reduce: item => item.id,
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    "onOption:selected": _ctx.onSelect,
    loading: _ctx.loading
  }, {
    spinner: _withCtx(({ loading }) => [
      _withDirectives(_createElementVNode("div", _hoisted_1, "Searching...", 512), [
        [_vShow, loading]
      ])
    ]),
    _: 1
  }, 8, ["onSearch", "options", "label", "reduce", "modelValue", "onOption:selected", "loading"]))
}