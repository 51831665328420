import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34b79c08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_form_input, {
      placeholder: _ctx.placeholder,
      type: "text",
      onBlur: _ctx.onSearch,
      onKeydown: _ctx.keydownHandler,
      modelValue: _ctx.searchValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
      onInput: _ctx.onInput,
      disabled: _ctx.disabled,
      class: _normalizeClass({ disabled: _ctx.disabled })
    }, null, 8, ["placeholder", "onBlur", "onKeydown", "modelValue", "onInput", "disabled", "class"])
  ]))
}