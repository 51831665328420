
import { ON_CHANGE_TAB } from '@/const/events';
import { itemsCreateStore as store } from '@/store/item-create';
import { Emitter, EventType } from 'mitt';
import { defineComponent, inject } from 'vue';
import ListComplete from '../ListComplete.vue';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const uniqueId = require('lodash/uniqueId');

export default defineComponent({
  name: 'documents-tab',
  components: { ListComplete },
  setup() {
    const emitter: Emitter<Record<EventType, unknown>> | undefined = inject('emitter'); // Inject `emitter`
    const onChangeTab = (val: string): void => {
      emitter?.emit(ON_CHANGE_TAB, val);
    };

    const setImage = (e) => {
      const fileToLoad = e.target.files[0];
      const reader = new FileReader();
      // eslint-disable-next-line func-names
      reader.onload = (event) => {
        if (event?.target?.result) {
          // eslint-disable-next-line max-len
          store.documents = [
            ...store.documents,
            { document: event?.target?.result as string, name: e.target.files[0].name, id: uniqueId() as string }
          ];
        }
      };
      reader.readAsDataURL(fileToLoad);
    };

    const removeFile = (id: string) => {
      store.documents = [...store.documents].filter((file) => file.id !== id);
    };

    return {
      onChangeTab,
      setImage,
      removeFile,
      store
    };
  }
});
