
import { CHANGE_QTY, UPDATE_QTY } from '@/const/events';
import { CartItem } from '@/generated/graphql';
import { defineComponent, PropType, ref, watch, computed } from 'vue';

export default defineComponent({
  name: 'v-quantity-input',
  props: {
    qty: Number,
    product: Object as PropType<CartItem>,
    totalAvailable: Number
  },
  emits: [CHANGE_QTY, UPDATE_QTY],
  setup(props, { emit }) {
    const currentQty = ref(props.qty);
    const error = ref('');

    const validQty = computed(
      () => currentQty.value && currentQty.value > 0 && currentQty.value <= (props.totalAvailable as unknown as number)
    );

    watch(
      () => validQty.value,
      (newVal) => {
        if (!newVal && currentQty.value !== 0) {
          error.value = `Available quantity: ${props.totalAvailable}`;
        }
        if (newVal && error.value) {
          error.value = '';
        }
      }
    );

    watch(
      () => currentQty.value,
      (newVal) => {
        if (newVal) {
          emit(UPDATE_QTY, newVal);
          if (newVal && newVal < 1) currentQty.value = 1;
          if (newVal && newVal > 0 && props.totalAvailable && newVal <= props.totalAvailable) {
            emit(CHANGE_QTY, props.product?.id, newVal);
          }
        }
      }
    );

    const plusQty = () => {
      if (currentQty.value || currentQty.value === 0) currentQty.value += 1;
    };

    const minusQty = () => {
      if (currentQty.value && currentQty.value > 1) currentQty.value -= 1;
    };

    return {
      currentQty,
      plusQty,
      minusQty,
      validQty,
      error
    };
  }
});
