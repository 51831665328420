
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'v-tabs',
  methods: {
    tabClicked(e) {
      if (e.target.getAttribute('data-toggle') === 'tab') {
        e.preventDefault();
        // active nav-link
        const { target } = e;
        target.closest('.nav').querySelector('.nav-link.active').classList.remove('active');
        target.classList.add('active');

        // active tab-pane
        const tabPane = document.querySelector(target.getAttribute('href'));

        tabPane.parentNode.querySelector('.tab-pane.show').classList.remove('show');
        tabPane.classList.add('show');

        setTimeout(() => {
          tabPane.parentNode.querySelector('.tab-pane.active').classList.remove('active');
          tabPane.classList.add('active');
        }, 150);
      }
    }
  }
});
