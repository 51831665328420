
import { defineComponent } from 'vue';
import { UPDATE } from '@/const/events';

const defaultOption = {
  loop: false,
  scrollbar: {
    draggable: false
  },
  spaceBetween: 20,
  slidesPerView: 4,
  watchSlidesVisibility: true,
  clickable: true,
  navIcon: {
    prev: 'icon-angle-left',
    next: 'icon-angle-right'
  },
  isCustomNav: false
};
export default defineComponent({
  name: 'v-carousel',
  props: {
    options: Object
  },
  data() {
    return {
      sliderOption: { ...defaultOption, ...this.options },
      isNavDisabled: false,
      isDotDisabled: false
    };
  },
  mounted() {
    /* eslint-disable */
    this.$nextTick(() => {
      if (this.$refs?.pvCarousel) {
        // @ts-ignore
        if (this.$refs?.pvCarousel?.swiper) this.$refs.pvCarousel.swiper.emit(UPDATE);
        this.isNavDisabled = !!(
          (
            // @ts-ignore
            this.$refs?.pvCarousel?.querySelectorAll('.swiper-button-disabled') &&
            // @ts-ignore
            this.$refs?.pvCarousel?.querySelectorAll('.swiper-button-disabled').length > 1
          )
        );
        this.isDotDisabled = !!(
          (
            // @ts-ignore
            this.$refs?.pvCarousel?.querySelectorAll('.swiper-pagination-bullet') &&
            // @ts-ignore
            this.$refs?.pvCarousel?.querySelectorAll('.swiper-pagination-bullet').length < 2
          )
        );
        if (
          // @ts-ignore
          this.sliderOption.navigation &&
          // @ts-ignore
          document.querySelector(this.sliderOption.navigation.nextEl) &&
          document
            // @ts-ignore
            .querySelector(this.sliderOption.navigation.nextEl)
            .parentNode.querySelectorAll('.swiper-button-disabled').length > 1
        ) {
          // @ts-ignore
          document.querySelector(this.sliderOption.navigation.nextEl).parentNode.classList.add('disabled');
        }
      }
    });
    /* eslint-enable */
  },
  methods: {
    /* eslint-disable */
    resizeHandler() {
      this.isNavDisabled =
        // @ts-ignore
        this.$refs?.pvCarousel?.querySelectorAll('.swiper-button-disabled') &&
        // @ts-ignore
        this.$refs?.pvCarousel?.querySelectorAll('.swiper-button-disabled').length > 1;

      this.isDotDisabled =
        // @ts-ignore
        this.$refs?.pvCarousel?.querySelectorAll('.swiper-pagination-bullet') &&
        // @ts-ignore
        this.$refs?.pvCarousel?.querySelectorAll('.swiper-pagination-bullet').length < 2;
    }
    /* eslint-enable */
  }
});
