
import { defineComponent, computed, PropType } from 'vue';
import { findIconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

export default defineComponent({
  name: 'FontAwesomeIcon',

  props: {
    icon: {
      type: String as PropType<IconName>,
      required: true
    },
    type: {
      type: String as PropType<IconPrefix>,
      default: 'fas',
      required: false
    },
    class: String
  },

  setup(props) {
    const definition = computed(() =>
      findIconDefinition({
        prefix: props.type,
        iconName: props.icon
      })
    );

    const width = computed(() => definition.value.icon[0]);
    const height = computed(() => definition.value.icon[1]);
    const svgPath = computed(() => definition.value.icon[4]);
    return { width, height, svgPath };
  }
});
