
import { userFirstName } from '@/helpers/token';
import { defineComponent, Ref, ref, computed } from 'vue';
import { PROFILE, CART, HOME, ITEMS_CREATE, AUCTIONS, SHOP, CONTACT } from '@/const/routes';
import { useStore } from 'vuex';
import usePermissions from '@/hooks/usePermissions';
import useRoles from '@/hooks/useRoles';
import { LoginActionsEnum, UserPermissionEnum } from '@/types/auth';

export default defineComponent({
  name: 'main-menu',
  setup() {
    const store = useStore();
    const mainMenu = ref<Ref | null>(null);

    const isUserLoggedIn = computed(() => store.state.auth.status.loggedIn);
    const totalCount = computed(() => store.state.auth.totalCount);

    const logout = () => {
      store.dispatch(LoginActionsEnum.Logout).then(() => {
        mainMenu.value.dropdown.$forceUpdate();
      });
    };

    const signOut = () => {
      logout();
    };

    const { userCan } = usePermissions();
    const { auctionStatus, userHasRole } = useRoles();

    const accessAuctionTile = computed(
      () => auctionStatus.value === UserPermissionEnum.AUCTION_STATUS && userCan(UserPermissionEnum.ADD_TO_CART)
    );

    const accessSellTile = computed(
      () =>
        userCan(UserPermissionEnum.MANAGE_INVENTORY) ||
        userCan(UserPermissionEnum.VIEW_GLOBAL_INVENTORY) ||
        userHasRole(UserPermissionEnum.REVIEW_SELLER) ||
        userHasRole(UserPermissionEnum.DEFAULT_REVIEWER_SELLER)
    );

    return {
      signOut,
      PROFILE,
      CART,
      HOME,
      ITEMS_CREATE,
      AUCTIONS,
      SHOP,
      CONTACT,
      userFirstName,
      mainMenu,
      totalCount,
      accessAuctionTile,
      accessSellTile,
      isUserLoggedIn
    };
  }
});
