import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["viewBox"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    class: _normalizeClass(_ctx.$props.class),
    viewBox: `0 0 ${_ctx.width} ${_ctx.height}`
  }, [
    _createElementVNode("path", {
      fill: "currentColor",
      d: _ctx.svgPath
    }, null, 8, _hoisted_2)
  ], 10, _hoisted_1))
}