import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_pagination = _resolveComponent("b-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_pagination, {
      "align-content": "center",
      onClick: _ctx.onClick,
      modelValue: _ctx.page,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
      "total-rows": _ctx.totalRows,
      "per-page": _ctx.perPage
    }, null, 8, ["onClick", "modelValue", "total-rows", "per-page"])
  ]))
}