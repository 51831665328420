import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-select-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_select = _resolveComponent("vue-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vue_select, {
      loading: _ctx.loading,
      options: _ctx.selectOptions,
      "close-on-select": "",
      placeholder: _ctx.selectedOption?.name && !_ctx.loading ? _ctx.selectedOption.name : _ctx.placeholder,
      "label-by": "name",
      searchable: "",
      "clear-on-close": "",
      "onSearch:input": _ctx.handleInput,
      class: _normalizeClass({ active: _ctx.selectedOption }),
      "onUpdate:modelValue": _ctx.onUpdate,
      disabled: _ctx.disabled,
      "v-model": _ctx.selectedOption,
      "model-value": _ctx.selectedOption
    }, null, 8, ["loading", "options", "placeholder", "onSearch:input", "class", "onUpdate:modelValue", "disabled", "v-model", "model-value"])
  ]))
}