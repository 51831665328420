import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    modelValue: _ctx.date,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
      _ctx.onDateUpdate
    ],
    format: _ctx.format,
    range: "",
    presetRanges: _ctx.presetRanges
  }, null, 8, ["modelValue", "format", "presetRanges", "onUpdate:modelValue"]))
}