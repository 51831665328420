
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import { CartAddItemDocument, DeleteItemDocument, Item } from '@/generated/graphql';
import { PRODUCT, ITEMS_CREATE } from '@/const/routes';
import { notify } from '@kyvg/vue3-notification';
import { useMutation } from '@vue/apollo-composable';
import { UNLIMITED_QUANTITY } from '@/const/const';
import { useRouter } from 'vue-router';
import { DELETE_ITEM } from '@/const/events';
import { conditionLabel } from '@/helpers/get-condition-labels';

export default defineComponent({
  name: 'product-card',
  emits: [DELETE_ITEM],
  props: {
    product: Object as PropType<Item>,
    adClass: String,
    isActions: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const quantity = ref(1);
    const isShow = ref(false);
    const router = useRouter();

    const cartId = computed(() => store.state.auth.cartId);

    const { mutate } = useMutation(CartAddItemDocument);

    const totalAvailable = computed(() =>
      props.product?.unlimited_quantity ? UNLIMITED_QUANTITY : props.product?.quantity
    );

    const validQty = computed(
      () => quantity.value > 0 && quantity.value <= (totalAvailable.value as unknown as number)
    );

    const { mutate: deleteItemMutate } = useMutation(DeleteItemDocument);

    const removeItem = (id) => {
      deleteItemMutate({ id }).then((res) => {
        if (!res?.errors?.length) {
          isShow.value = false;
          emit(DELETE_ITEM);
        }
      });
    };

    const addToCart = (product) => {
      const params: {quantity: number, id?: string, itemId: string} = {
        quantity: Number(quantity.value), itemId: product.id
      };
      if(cartId.value) params.id = cartId.value;

      if (validQty.value) {
        mutate(params).then((res) => {
          if (!res?.errors?.length) {
            notify({
              text: 'has been added to your cart!',
              data: product
            });
          }
        });
      }
    };

    const openModal = () => {
      isShow.value = true;
    };

    const setModal = () => {
      isShow.value = false;
    };

    return {
      conditionLabel,
      addToCart,
      quantity,
      validQty,
      totalAvailable,
      PRODUCT,
      router,
      removeItem,
      isShow,
      openModal,
      setModal,
      UNLIMITED_QUANTITY,
      ITEMS_CREATE
    };
  }
});
