export const LOGIN = 'Login';
export const REGISTER = 'Register';
export const FORGOT_PASSWORD = 'ForgotPassword';
export const GUARANTEE = 'Guarantee';
export const FAQ = 'FAQ';
export const CONTACT = 'Contact';
export const SHOP = 'Shop';
export const PRODUCT = 'Product';
export const CART = 'Cart';
export const CHECKOUT = 'Checkout';
export const ORDER = 'Order';
export const HOME = 'Home';
export const PROFILE = 'Profile';
export const NOT_FOUND = 'NotFound';
export const ITEMS_CREATE = 'ItemsCreate';
export const SELLING = 'Selling';
export const AUCTIONS = 'Auctions';
export const AUCTION_DETAILS = 'AuctionDetails';
export const LOT_DETAILS = 'LotDetails';
export const BUYING = 'Buying';
export const REGISTER_SUCCESS = 'RegisterSuccess';
export const VERIFY_EMAIL = 'VerifyEmail';
export const AUCTION_HISTORY = 'AuctionHistory';
