
import { CREATE_ITEM, ON_CHANGE_TAB } from '@/const/events';
import { Emitter, EventType } from 'mitt';
import { defineComponent, inject, ref, computed } from 'vue';

export default defineComponent({
  name: 'complete-tab',
  emits: [CREATE_ITEM],
  props: {
    isDisabledCompleteBtn: Boolean
  },
  setup(props, { emit }) {
    const fdaSellerApproved = ref(false);

    const disabled = computed(() => !fdaSellerApproved.value || props.isDisabledCompleteBtn);

    const emitter: Emitter<Record<EventType, unknown>> | undefined = inject('emitter'); // Inject `emitter`
    const onChangeTab = (val: string): void => {
      emitter?.emit(ON_CHANGE_TAB, val);
    };

    const createItem = () => emit(CREATE_ITEM);

    return {
      fdaSellerApproved,
      onChangeTab,
      createItem,
      disabled
    };
  }
});
