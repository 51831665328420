
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'v-breadcrumb',
  data() {
    return {
      items: [
        {
          text: 'Home',
          href: '/',
          active: true
        },
        {
          text: 'Sign In',
          href: '/login'
        },
        {
          text: 'Register',
          href: '/register'
        }
      ]
    };
  }
});
