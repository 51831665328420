import { FetchResult, Operation } from 'apollo-link/lib';
import { ApolloLink, Observable } from 'apollo-link';
import Pusher from 'pusher-js';
import { OLD_CHANNELS, CHANNELS } from '@/const/const';

interface Options {
  pusher: Pusher;
}
// Turn `subscribe` arguments into an observer-like thing, see getObserver
// https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L329-L343
function getObserver(observerOrNext, onError, onComplete) {
  if (typeof observerOrNext === 'function') {
    // Duck-type an observer
    return {
      next: (v) => observerOrNext(v),
      error: (e) => onError && onError(e),
      complete: () => onComplete && onComplete()
    };
  }
  // Make an object that calls to the given object, with safety checks
  return {
    next: (v) => observerOrNext.next && observerOrNext.next(v),
    error: (e) => observerOrNext.error && observerOrNext.error(e),
    complete: () => observerOrNext.complete && observerOrNext.complete()
  };
}
class PusherLink extends ApolloLink {
  constructor(options: Options) {
    super();
    const channels = localStorage.getItem(CHANNELS);
    if (channels !== null) {
      localStorage.setItem(OLD_CHANNELS, channels);
      localStorage.removeItem(CHANNELS);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.pusher = options.pusher;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  request(operation, forward): Observable<FetchResult> | null {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const subscribeObservable = new Observable((_observer) => {
      //
    });

    // Capture the super method
    const prevSubscribe = subscribeObservable.subscribe.bind(subscribeObservable);

    // Override subscribe to return an `unsubscribe` object, see
    // https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L182-L212
    subscribeObservable.subscribe = (observerOrNext, onError, onComplete) => {
      prevSubscribe(observerOrNext, onError, onComplete);

      const observer = getObserver(observerOrNext, onError, onComplete);

      let subscriptionChannel;

      forward(operation).subscribe({
        next: (data) => {
          // If the operation has the subscription channel, it's a subscription
          subscriptionChannel = this.getChannelFromResponse(data, operation);
          // No subscription found in the response, pipe data through
          if (subscriptionChannel == null) {
            observer.next(data);
            observer.complete();

            return;
          }

          const storage = JSON.parse(localStorage.getItem(CHANNELS) || '[]');
          storage.push(subscriptionChannel);
          localStorage.setItem(CHANNELS, JSON.stringify(storage));

          this.subscribeToChannel(subscriptionChannel, observer);
        }
      });

      // Return an object that will unsubscribe_if the query was a subscription
      return {
        closed: false,
        unsubscribe: () => {
          // eslint-disable-next-line no-unused-expressions
          subscriptionChannel && this.unsubscribeFromChannel(subscriptionChannel);
        }
      };
    };

    return subscribeObservable as Observable<FetchResult>;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  subscribeToChannel(subscriptionChannel, observer) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.pusher.subscribe(subscriptionChannel).bind('lighthouse-subscription', (payload) => {
      if (!payload.more) {
        this.unsubscribeFromChannel(subscriptionChannel);

        observer.complete();
      }

      const { result } = payload;

      if (result) {
        observer.next(result);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  unsubscribeFromChannel(subscriptionChannel: FetchResult): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.pusher.unsubscribe(subscriptionChannel);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line class-methods-use-this
  getChannelFromResponse(
    response: FetchResult<{ [key: string]: unknown }, Record<string, unknown>, Record<string, unknown>>,
    operation: Operation
  ): string | null {
    return !!response.extensions &&
      !!response.extensions.lighthouse_subscriptions &&
      !!response.extensions.lighthouse_subscriptions.channels
      ? response.extensions.lighthouse_subscriptions.channels[operation.operationName]
      : response.extensions?.lighthouse_subscriptions?.channel;
  }
}

export default PusherLink;
