import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_checkbox_group = _resolveComponent("b-form-checkbox-group")!

  return (_ctx.items)
    ? (_openBlock(), _createBlock(_component_b_form_checkbox_group, {
        key: 0,
        onInput: _ctx.checkItem,
        modelValue: _ctx.checkValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkValue) = $event)),
        options: _ctx.items,
        "value-field": "id",
        "text-field": "name"
      }, null, 8, ["onInput", "modelValue", "options"]))
    : _createCommentVNode("", true)
}