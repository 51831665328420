import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, TransitionGroup as _TransitionGroup, withCtx as _withCtx, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50727317"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "drag-item d-flex mb-2" }
const _hoisted_2 = { class: "col-md-2 align-self-center" }
const _hoisted_3 = { class: "list-btn drag-handle" }
const _hoisted_4 = { class: "col-md-2 align-self-center" }
const _hoisted_5 = { class: "product-image-container" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "col-md-6 align-self-center" }
const _hoisted_8 = { class: "col-md-3 align-self-center" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createBlock(_component_draggable, _mergeProps({
    modelValue: _ctx.list,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event))
  }, _ctx.dragOptions, {
    onStart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDragging = true)),
    onEnd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDragging = false))
  }), {
    default: _withCtx(() => [
      _createVNode(_TransitionGroup, {
        type: "transition",
        name: "flip-list"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (file) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              key: file.id
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("button", _hoisted_3, [
                    _createVNode(_component_fa, { icon: "bars" })
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("figure", _hoisted_5, [
                    (file.image)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: file.image,
                          alt: "item-image",
                          class: "item-img"
                        }, null, 8, _hoisted_6))
                      : (_openBlock(), _createBlock(_component_fa, {
                          key: 1,
                          icon: "file-lines",
                          class: "item-img"
                        }))
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(file.name), 1),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", {
                    class: "list-btn",
                    onClick: ($event: any) => (_ctx.deleteFile(file.id))
                  }, [
                    _createVNode(_component_fa, { icon: "xmark" })
                  ], 8, _hoisted_9)
                ])
              ])
            ]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16, ["modelValue"]))
}