import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, NormalizedCacheObject } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import Pusher from 'pusher-js';
import store from '@/store';
import { LoginActionsEnum } from '@/types/auth';
import { token, getRefreshToken, setToken, getExpiresIn } from './helpers/token';
import { RefreshTokenDocument } from './generated/graphql';
import PusherLink from './pusher-link';

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_API_URL
});

const pusherLink = new PusherLink({
  pusher: new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    authEndpoint: `${process.env.VUE_APP_API_URL}/subscriptions/auth`,
    auth: {
      headers: {
        authorization: `Bearer ${token()}`
      }
    }
  })
});

// eslint-disable-next-line import/no-mutable-exports
export let apolloClient: ApolloClient<NormalizedCacheObject>;

const refreshToken = () => {
  apolloClient
    .mutate({
      mutation: RefreshTokenDocument,
      variables: {
        refresh_token: getRefreshToken()
      }
    })
    .then((res) => {
      if (res?.data?.refreshToken) setToken(res.data.refreshToken);
    })
    .catch((err) => {
      console.error(err);
    });
};

const authLink = setContext((_, { headers }) => {
  // refresh token
  if (getExpiresIn && new Date() > new Date(getExpiresIn as unknown as string)) {
    refreshToken();
  }
  // eslint-disable-next-line implicit-arrow-linebreak
  return {
    headers: {
      ...headers,
      ...(!!token() && { Authorization: `Bearer ${token()}` })
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (graphQLErrors[0]?.extensions?.code === 'invalid-jwt') {
      if (token()) localStorage.removeItem('han_jwt_token');
    } else if (graphQLErrors[0]?.extensions?.code === 401 && token()) {
      localStorage.removeItem('han_jwt_token');
      refreshToken();
    } else if (graphQLErrors[0]?.extensions?.code === 401 && !token() && store.state.auth.status.loggedIn) {
      store.dispatch(LoginActionsEnum.Logout);
    } else if (graphQLErrors[0]?.extensions?.category === 'authentication' && store.state.auth.status.loggedIn) {
      store.dispatch(LoginActionsEnum.Logout);
    } else if (networkError) {
      console.error(networkError?.message);
    } else {
      graphQLErrors.forEach(({ message }) => {
        if (message.startsWith('[{')) {
          // Validation error object
          const error: {
            constraints: {
              [k: string]: string;
            };
          }[] = JSON.parse(message);
          error.forEach((e) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const [, value] of Object.entries(e.constraints)) {
              if (
                value !== 'Unauthenticated.' &&
                value !== 'The refresh token is invalid.' &&
                value !== 'Internal server error'
              ) {
                console.error(value);
              }
            }
          });
        } else if (
          message !== 'Unauthenticated.' &&
          message !== 'The refresh token is invalid.' &&
          message !== 'Internal server error'
        ) {
          console.error(message);
        }
      });
    }
  }
});

// Create the apollo client
// eslint-disable-next-line import/prefer-default-export
apolloClient = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: ApolloLink.from([errorLink, pusherLink as unknown as ApolloLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      errorPolicy: 'all'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  },
  connectToDevTools: true
});

// Create a provider
export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient
});

export default {};
