export const UNLIMITED_QUANTITY = 1000000000;

export const ALREADY_VERIFIED = 'ALREADY_VERIFIED';

export const OLD_CHANNELS = 'oldChannels';

export const CHANNELS = 'channels';

export const CONTACT_PHONE_NUMBER = '502-653-9589';

export const ITEMS_CREATE_TABS = [
  {
    title: 'Information',
    content: 'Information'
  },
  {
    title: 'Details',
    content: 'Details'
  },
  {
    title: 'Warranty Information',
    content: 'WarrantyInformation'
  },
  {
    title: 'Quantity',
    content: 'Quantity'
  },
  {
    title: 'Images',
    content: 'Images'
  },
  {
    title: 'Documents',
    content: 'Documents'
  },
  {
    title: 'Complete',
    content: 'Complete'
  }
];

export const AUCTIONS_ENUM = {
  active: 'active',
  won: 'won',
  history: 'history'
};

export const PAYPAL_ID = '5';
export const PO_ID = '6';
export const MANUFACTURER = 'manufacturer';
export const MPN = 'mpn';

export default {};
