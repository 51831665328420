
import { Item } from '@/generated/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'product-description',
  props: {
    product: Object as PropType<Item>
  }
});
