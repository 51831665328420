import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!

  return (_openBlock(), _createBlock(_component_b_form_input, {
    ref: _ctx.refName,
    loading: _ctx.loading,
    class: _normalizeClass(["v-input", [`theme-${_ctx.theme}`]]),
    type: _ctx.type,
    onInput: _ctx.onInput,
    modelValue: _ctx.inputValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event))
  }, null, 8, ["loading", "class", "type", "onInput", "modelValue"]))
}