
import { defineComponent, ref, watch, onBeforeMount, computed, inject } from 'vue';
import { LocationQueryValue, useRoute, useRouter } from 'vue-router';
import { CLEAR_FILTER } from '@/const/events';
import { Emitter, EventType } from 'mitt';

export default defineComponent({
  name: 'shop-filters',
  props: {
    initialSelected: {
      type: Object,
      default: () => ({})
    },
    marketplaceSettings: {
      type: Object,
      default: () => ({})
    },
    manufacturers: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    },
    conditions: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const showWithImage = ref([{ id: '1', name: 'Yes' }]);
    const keywords = ref<string>('');
    // const checkedImagesOnly = ref('');
    const selectedConditions = ref<LocationQueryValue[]>([]);
    const selectedCategories = ref<LocationQueryValue[]>([]);
    const selectedManufacturers = ref<LocationQueryValue[]>([]);

    const title = computed(() => route.query.title);

    onBeforeMount(() => {
      const { query } = route;
      if (query?.title) keywords.value = query.title?.toString();
      // if (query?.withImages) checkedImagesOnly.value = query.withImages.toString();
      if (query.conditions) {
        // eslint-disable-next-line no-unused-expressions
        typeof query.conditions === 'string'
          ? (selectedConditions.value = [query.conditions])
          : (selectedConditions.value = [...query.conditions]);
      }
      if (query.categories) {
        // eslint-disable-next-line no-unused-expressions
        typeof query.categories === 'string'
          ? (selectedCategories.value = [query.categories])
          : (selectedCategories.value = [...query.categories]);
      }
      if (query.manufacturers) {
        // eslint-disable-next-line no-unused-expressions
        typeof query.manufacturers === 'string'
          ? (selectedManufacturers.value = [query.manufacturers])
          : (selectedManufacturers.value = [...query.manufacturers]);
      }
    });

    const emitter: Emitter<Record<EventType, unknown>> | undefined = inject('emitter');

    const onItemsSearch = (key: string, val = '') => {
      const queryParams = { ...route.query, [key]: val };
      if (!val) {
        delete queryParams[key];
      }
      router.push({ query: queryParams });
    };

    const clearFilter = (key: string) => {
      switch (key) {
      case 'conditions':
        selectedConditions.value = [];
        break;
      case 'manufacturers':
        selectedManufacturers.value = [];
        break;
      case 'categories':
        selectedCategories.value = [];
        break;
      default:
        return;
      }
      emitter?.emit(CLEAR_FILTER, key);

      const queryParams = { ...route.query };
      delete queryParams[key];
      router.push({ query: queryParams });
    };

    watch(
      () => title.value,
      (newVal) => {
        if (newVal) {
          keywords.value = newVal.toString();
        }
      }
    );

    return {
      keywords,
      showWithImage,
      onItemsSearch,
      clearFilter,
      // checkedImagesOnly,
      selectedConditions,
      selectedCategories,
      selectedManufacturers
    };
  }
});
