import find from 'lodash/find';
import { computed, ComputedRef } from 'vue';
import store from '@/store';
import { Maybe, Permission } from '@/generated/graphql';

export default function usePermissions(): {
  userPermissions: ComputedRef<Maybe<Maybe<Permission>[]> | undefined>;
  userCan: (role: string) => boolean;
  } {
  const userPermissions = computed(() => store.state.auth.user?.permissions || []);

  const userCan = (role: string) => !!find(store.state.auth.user?.permissions, { name: role });

  return {
    userPermissions,
    userCan
  };
}
