
import { defineComponent, Ref, ref } from 'vue';
import { CategoriesDocument, Category } from '@/generated/graphql';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useRouter } from 'vue-router';
import { SHOP } from '@/const/routes';
import { getCategoryWithChildren } from '@/helpers/get-category-with-children';

export default defineComponent({
  name: 'categories-menu',
  setup() {
    const router = useRouter();
    const selectedChildren = ref<Category | null>(null);
    const selectedGrandchildren = ref<Category | null>(null);
    const menu = ref<Ref | null>(null);

    const { result, loading, error } = useQuery(CategoriesDocument, {
      page: 1,
      first: 10000,
      showInMarketplace: true,
      hasParent: 0,
      active: true
    });
    const categories = useResult(result, null, (data) => data.categories.data);

    const mainCategorySelected = (category) => {
      if (category.marketplaceChildren && category.marketplaceChildren.length > 0) {
        selectedChildren.value = category;
        menu.value.dropdown.$forceUpdate();
      } else {
        menu.value.hide();
        router.push({ name: SHOP, query: { categories: category.id } });
      }
    };

    const childCategorySelected = (category) => {
      if (category.marketplaceChildren && category.marketplaceChildren.length > 0) {
        selectedGrandchildren.value = category;
        menu.value.dropdown.$forceUpdate();
      } else {
        menu.value.hide();
        router.push({ name: SHOP, query: { categories: category.id } });
      }
    };

    const navigateToShopPage = (category) => {
      const queryParams = getCategoryWithChildren(category);
      menu.value.hide();
      router.push({ name: SHOP, query: { categories: queryParams } });
    };

    const backToChildCategories = () => {
      selectedGrandchildren.value = null;
      menu.value.dropdown.$forceUpdate();
    };

    const backToMainCategories = () => {
      selectedChildren.value = null;
      menu.value.dropdown.$forceUpdate();
    };

    return {
      categories,
      menu,
      selectedChildren,
      selectedGrandchildren,
      loading,
      mainCategorySelected,
      childCategorySelected,
      backToChildCategories,
      backToMainCategories,
      navigateToShopPage,
      error
    };
  }
});
