
import { computed, defineComponent, onBeforeMount, ref, watch, PropType } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { sortOptions } from '@/const/sort-options';
import { PaginatorInfo, Item } from '@/generated/graphql';

export default defineComponent({
  name: 'products-list',
  props: {
    items: {
      type: Array as PropType<Item[]>,
      default: () => []
    },
    pagination: {
      type: Object as PropType<PaginatorInfo>,
      default: () => ({})
    },
    rows: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const repeatCount = ref(new Array(100));
    const type = ref('');
    const sorting = ref({
      active: false,
      sortOptions
    });

    const perPage = computed(() => Number(route.query.first));
    const currentPage = computed(() => Number(route.query.page));
    const sort = computed(() => route.query.sorting);

    onBeforeMount(() => {
      type.value = route.path.includes('grid') ? 'grid' : 'list';
    });

    const onUpdatedLimit = (itemPerPage) => {
      if (itemPerPage !== perPage.value) {
        router.push({ query: { ...route.query, first: itemPerPage } });
      }
    };

    const onUpdatedPage = (page) => {
      router.push({ query: { ...route.query, page } });
    };

    const onItemsSearch = (key, val) => {
      const queryParams = { ...route.query, [key]: val };
      if (!val) {
        delete queryParams[key];
      }
      router.push({ query: queryParams });
    };

    watch(
      () => route.path,
      (newVal) => {
        if (newVal) {
          type.value = route.path.includes('grid') ? 'grid' : 'list';
        }
      }
    );

    return {
      repeatCount,
      type,
      currentPage,
      perPage,
      onUpdatedLimit,
      onUpdatedPage,
      sorting,
      sort,
      onItemsSearch
    };
  }
});
