// store Modules
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const SET_CART_ID = 'SET_CART_ID';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_AUCTION_BEGGINING = 'SET_AUCTION_BEGGINING';
export const SET_SUBMITTED_LOT_ID = 'SET_SUBMITTED_LOT_ID';
export const REMOVE_SUBMITTED_LOT_ID = 'REMOVE_SUBMITTED_LOT_ID';
export const SET_MAX_BID = 'SET_MAX_BID';

export default {};
