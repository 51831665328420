
import { defineComponent } from 'vue';
import { MeDocument, UserStatisticsDocument } from '@/generated/graphql';
import { useQuery, useResult } from '@vue/apollo-composable';
import convertFn from '@/helpers/cost-converter';
import LineChart from './LineChart.vue';

export default defineComponent({
  name: 'dashboard-reporting',
  components: { LineChart },
  setup() {
    const { result: meResult, loading: meLoading } = useQuery(MeDocument);

    const userOrg = useResult(meResult, null, (data) => data.me.organization.name);
    const { result, loading } = useQuery(UserStatisticsDocument);

    const combinedPurchasedItemData = useResult(result, null, (data) =>
      data.userStatistics.purchasedGraphData.reduce((acc, cur) => {
        acc.push({
          total: cur.total_amount,
          date: cur.date
        });
        return acc;
      }, [])
    );

    const combinedSoldItemData = useResult(result, null, (data) =>
      data.userStatistics.soldGraphData.reduce((acc, cur) => {
        acc.push({
          total: cur.total_amount,
          date: cur.date
        });
        return acc;
      }, [])
    );

    const totalSoldAmt = useResult(result, null, (data) => convertFn(data.userStatistics.soldTotalAmount));

    const totalPurchasedAmt = useResult(result, null, (data) =>
      convertFn(data.userStatistics.purchasedTotalAmount || 0)
    );

    const totalSoldCount = useResult(result, null, (data) => data.userStatistics.soldGraphData.length || 0);

    const totalPurchasedCount = useResult(result, null, (data) => data.userStatistics.purchasedGraphData.length || 0);

    return {
      userOrg,
      totalSoldAmt,
      combinedPurchasedItemData,
      combinedSoldItemData,
      totalPurchasedAmt,
      totalSoldCount,
      totalPurchasedCount,
      meLoading,
      loading
    };
  }
});
