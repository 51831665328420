import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pl-0 pl-sm-2 pt-2 pt-sm-0 text-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.total > 0)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, " Total " + _toDisplayString(_ctx.total) + " records found ", 1))
    : _createCommentVNode("", true)
}