
import { ON_CHANGE_TAB } from '@/const/events';
import { Emitter, EventType } from 'mitt';
import { itemsCreateStore as store } from '@/store/item-create';
import { defineComponent, inject } from 'vue';
import { VueEditor } from 'vue3-editor';

export default defineComponent({
  name: 'warranty-information-tab',
  components: { VueEditor },
  setup() {
    const emitter: Emitter<Record<EventType, unknown>> | undefined = inject('emitter'); // Inject `emitter`
    const onChangeTab = (val: string): void => {
      emitter?.emit(ON_CHANGE_TAB, val);
    };

    return {
      store,
      onChangeTab
    };
  }
});
