// Item Create
import { reactive } from 'vue';
import { ItemCreateState } from '@/types/item-create';

const initialState: ItemCreateState = {
  conditionId: '',
  conditionName: '',
  freightShipping: false,
  warranty: '',
  images: [],
  productDetails: '',
  unlimitedQuantity: false,
  quantity: null,
  unitOfMeasure: '',
  qtyPerUnitOfMeasure: null,
  documents: [],
  lotNumber: '',
  expirationData: null,
  rxOnly: false,
  sellPrice: null,
  visibleMpn: null,
  visibleManufacturers: null,
  mpn: {
    name: '',
    id: '',
    productId: '',
    productName: ''
  },
  manufacturer: {
    name: '',
    id: ''
  }
};

export const itemsCreateStore = reactive({
  ...initialState
});

export default {};
