
import { ITEM_SELECT } from '@/const/events';
import { ConditionEnum } from '@/generated/graphql';
import { defineComponent, onMounted, PropType, ref } from 'vue';

export default defineComponent({
  name: 'v-form-dropdown',
  emits: [ITEM_SELECT],
  props: {
    items: {
      type: Array as PropType<ConditionEnum[]>,
      default: () => []
    },
    title: {
      type: String
    },
    value: {
      type: String
    }
  },
  setup(props, { emit }) {
    const selectedItem = ref<string>('');

    const onSelectedItem = (ev) => {
      emit(ITEM_SELECT, ev.id);
      selectedItem.value = ev.name;
    };

    onMounted(() => {
      if (props.value) {
        selectedItem.value = props.value;
      }
    });

    return {
      onSelectedItem,
      selectedItem
    };
  }
});
