import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "slider-wrap" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplideSlide = _resolveComponent("SplideSlide")!
  const _component_Splide = _resolveComponent("Splide")!

  return (_openBlock(), _createElementBlock("figure", _hoisted_1, [
    (_ctx.slides.length > 0)
      ? (_openBlock(), _createBlock(_component_Splide, {
          key: 0,
          options: _ctx.settings,
          "aria-label": "media"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide) => {
              return (_openBlock(), _createBlock(_component_SplideSlide, {
                key: slide?.name
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: slide?.path,
                    alt: "post-gallery-image",
                    onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImageError && _ctx.handleImageError(...args)))
                  }, null, 40, _hoisted_2)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["options"]))
      : (_openBlock(), _createBlock(_component_Splide, {
          key: 1,
          options: _ctx.settings,
          "aria-label": "media"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SplideSlide, null, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require('@/static/images/generic.jpeg'),
                  class: "swiper-slide",
                  alt: "post-gallery-image"
                }, null, 8, _hoisted_3)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["options"]))
  ]))
}