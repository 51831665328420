import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_radio_group = _resolveComponent("b-form-radio-group")!

  return (_ctx.options)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["v-form-radio-group-wrapper", [`theme-${_ctx.theme}`]])
      }, [
        _createVNode(_component_b_form_radio_group, {
          ref: _ctx.refName,
          options: _ctx.options,
          onChange: _ctx.checkItem,
          modelValue: _ctx.checkValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkValue) = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$slots, (_, name) => {
              return _renderSlot(_ctx.$slots, name, {}, undefined, true)
            }), 256))
          ]),
          _: 3
        }, 8, ["options", "onChange", "modelValue"])
      ], 2))
    : _createCommentVNode("", true)
}