import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  class: "tab-pane",
  role: "tabpanel"
}
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_tabs = _resolveComponent("b-tabs")!

  return (_openBlock(), _createBlock(_component_b_tabs, null, {
    default: _withCtx(() => [
      (_ctx.product?.product?.product_description)
        ? (_openBlock(), _createBlock(_component_b_tab, {
            key: 0,
            title: "Description",
            active: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "tab-pane",
                role: "tabpanel",
                innerHTML: _ctx.product?.product?.product_description
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.product?.notes)
        ? (_openBlock(), _createBlock(_component_b_tab, {
            key: 1,
            title: "Details"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "tab-pane",
                role: "tabpanel",
                innerHTML: _ctx.product.notes
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.product?.product?.product_details)
        ? (_openBlock(), _createBlock(_component_b_tab, {
            key: 2,
            title: "Specifications"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "tab-pane",
                role: "tabpanel",
                innerHTML: _ctx.product.product.product_details
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.product?.documents?.length > 0)
        ? (_openBlock(), _createBlock(_component_b_tab, {
            key: 3,
            title: "Documents"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product?.documents, (doc, index) => {
                  return (_openBlock(), _createElementBlock("ul", {
                    class: "docList",
                    key: index
                  }, [
                    _createElementVNode("li", null, [
                      _createElementVNode("a", {
                        href: doc.path,
                        target: "_blank"
                      }, _toDisplayString(doc.name), 9, _hoisted_5)
                    ])
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}