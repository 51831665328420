
import { TIME_EXPIRED } from '@/const/events';
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';

export default defineComponent({
  name: 'v-countdown',
  emits: [TIME_EXPIRED],
  props: {
    deadline: {
      type: String,
      default: ''
    },
    end: {
      type: String,
      default: ''
    },
    stop: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const now = ref(Math.trunc(new Date().getTime() / 1000));
    const date = ref<number | null>(null);
    const diff = ref(0);
    const interval = ref<number | null | undefined>(null);

    onMounted(() => {
      if (!props?.deadline && !props?.end) {
        throw new Error("Missing props 'deadline' or 'end'");
      }

      const endTime = props?.deadline ? props.deadline : props?.end;
      date.value = Math.trunc(Date.parse(endTime.replace(/-/g, '/')) / 1000);

      if (!date.value) {
        throw new Error("Invalid props value, correct the 'deadline' or 'end'");
      }

      interval.value = setInterval(() => {
        now.value = Math.trunc(new Date().getTime() / 1000);
      }, 1000);
    });

    const twoDigits = (value) => {
      if (value.toString().length <= 1) {
        return `0${value.toString()}`;
      }
      return value.toString();
    };

    const seconds = computed(() => twoDigits(Math.trunc(diff.value) % 60));

    const minutes = computed(() => twoDigits(Math.trunc(diff.value / 60) % 60));

    const hours = computed(() => twoDigits(Math.trunc(diff.value / 60 / 60) % 24));

    const days = computed(() => twoDigits(Math.trunc(diff.value / 60 / 60 / 24)));

    watch(
      () => now.value,
      (newVal) => {
        if (date.value) {
          diff.value = date.value - newVal;
          if (diff.value <= 0 || props.stop) {
            diff.value = 0;

            emit(TIME_EXPIRED);

            // Remove interval
            clearInterval(interval.value as number | undefined);
          }
        }
      }
    );

    onUnmounted(() => clearInterval(interval.value as number | undefined));

    return {
      twoDigits,
      seconds,
      minutes,
      hours,
      days
    };
  }
});
