
import { UPDATE_MODEL_VALUE, UPDATED_PAGE } from '@/const/events';
import { defineComponent, watch, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'v-pagination',
  emits: [UPDATE_MODEL_VALUE, UPDATED_PAGE],
  props: {
    totalRows: {
      type: Number
    },
    perPage: {
      type: Number
    },
    currentPage: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const page = ref<number>(0);

    const onClick = (ev) => {
      emit(UPDATE_MODEL_VALUE, ev);
    };

    onMounted(() => {
      if (props.currentPage) page.value = props.currentPage;
    });

    watch(
      () => page.value,
      (newVal) => {
        emit(UPDATED_PAGE, newVal);
      }
    );

    watch(
      () => props.currentPage,
      (newVal) => {
        if (Number(newVal) !== Number(page.value)) {
          page.value = newVal as number;
        }
      }
    );

    return {
      page,
      onClick
    };
  }
});
