
import { computed, defineComponent, PropType, ref } from 'vue';
import { CartAddItemDocument, Item } from '@/generated/graphql';
import { useStore } from 'vuex';
import { notify } from '@kyvg/vue3-notification';
import { useMutation } from '@vue/apollo-composable';
import { UNLIMITED_QUANTITY, CONTACT_PHONE_NUMBER } from '@/const/const';
import { LOGIN, REGISTER } from '@/const/routes';
import { conditionLabel } from '@/helpers/get-condition-labels';

export default defineComponent({
  name: 'product-list-card',
  props: {
    product: Object as PropType<Item>,
    adClass: String,
    isActions: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore();
    const quantity = ref(1);

    const isUserLoggedIn = computed(() => store.state.auth.status.loggedIn);

    const cartId = computed(() => store.state.auth.cartId);

    const { mutate } = useMutation(CartAddItemDocument);

    const totalAvailable = computed(() =>
      props.product?.unlimited_quantity ? UNLIMITED_QUANTITY : props.product?.quantity
    );

    const validQty = computed(
      () =>
        quantity.value > 0 &&
        quantity.value <= (totalAvailable.value as unknown as number) &&
        props?.product?.sell_price
    );

    const addToCart = (product) => {
      const params: {quantity: number, id?: string, itemId: string} = {
        quantity: Number(quantity.value), itemId: product.id
      };
      if(cartId.value) params.id = cartId.value;

      if (validQty.value) {
        mutate(params).then((res) => {
          if (!res?.errors?.length) {
            notify({
              text: 'has been added to your cart!',
              data: product
            });
          }
        });
      }
    };


    return {
      conditionLabel,
      addToCart,
      quantity,
      validQty,
      totalAvailable,
      UNLIMITED_QUANTITY,
      CONTACT_PHONE_NUMBER,
      LOGIN,
      REGISTER,
      isUserLoggedIn
    };
  }
});
