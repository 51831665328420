
import { UNLIMITED_QUANTITY, CONTACT_PHONE_NUMBER } from '@/const/const';
import { useStore } from 'vuex';
import debounce from 'lodash/debounce';
import { CART } from '@/const/routes';
import { CartAddOrUpdateItemDocument, Item } from '@/generated/graphql';
import { useMutation } from '@vue/apollo-composable';
import { computed, defineComponent, PropType, ref } from 'vue';
import { showToast } from '@/helpers/toast-handler';
import { conditionLabel } from '@/helpers/get-condition-labels';

export default defineComponent({
  name: 'product-details',
  props: {
    product: Object as PropType<Item>,
    isProductNav: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore();
    const qty = ref(1);

    const { mutate } = useMutation(CartAddOrUpdateItemDocument);
    const cartId = computed(() => store.state.auth.cartId);

    const updateQty = (quantity) => {
      qty.value = quantity;
    };

    const changeQty = debounce((id, quantity) => {
      qty.value = quantity;
      mutate({ id: cartId.value, itemId: id, quantity });
    }, 500);

    const addToCart = () => {
      mutate({ quantity: Number(qty.value), id: cartId.value, itemId: props.product?.id }).then((res) => {
        if (!res?.errors?.length) {
          showToast('Item has been added to your cart!');
        }
      });
    };

    const totalAvailable = computed(() =>
      props.product?.unlimited_quantity ? UNLIMITED_QUANTITY : props.product?.quantity
    );

    const validQty = computed(
      () => qty.value > 0 && qty.value <= (totalAvailable.value as unknown as number) && props?.product?.sell_price
    );

    return {
      qty,
      addToCart,
      conditionLabel,
      validQty,
      changeQty,
      CART,
      totalAvailable,
      updateQty,
      CONTACT_PHONE_NUMBER
    };
  }
});
