
import { UPDATE_VALUE } from '@/const/events';
import { defineComponent, onMounted, ref, watch } from 'vue';

export default defineComponent({
  name: 'v-input',
  emits: [UPDATE_VALUE],
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    height: {
      type: String,
      default: '37px'
    },
    refName: {
      type: String,
      default: 'v-input'
    },
    theme: {
      type: String,
      default: 'base'
    },
    borderRadius: {
      type: [String, Number],
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  setup(props, { emit }) {
    const inputValue = ref<string | number>('');

    onMounted(() => {
      if (props.value) inputValue.value = props.value;
    });

    watch(
      () => props.value,
      (newVal) => {
        inputValue.value = newVal;
      }
    );

    const onInput = (data) => {
      emit(UPDATE_VALUE, data);
    };

    return {
      inputValue,
      onInput
    };
  }
});
