
import { defineComponent, ref, onMounted, PropType } from 'vue';
import moment from 'moment';
import { UPDATE_MODEL_VALUE } from '@/const/events';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from 'date-fns';

export default defineComponent({
  name: 'v-datepicker',
  emits: [UPDATE_MODEL_VALUE],
  components: {
    Datepicker
  },
  props: {
    modelValue: {
      type: Array as PropType<Date[] | string[] | string>,
      default: () => []
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY'
    }
  },
  setup(props, { emit }) {
    const date = ref<Date[] | [] | string[]>([]);

    const format = (value) =>
      `${moment(value[0]).format(props.dateFormat)}-${moment(value[1]).format(props.dateFormat)}`;

    const presetRanges = ref([
      { label: 'Today', range: [new Date(), new Date()] },
      { label: 'This month', range: [startOfMonth(new Date()), endOfMonth(new Date())] },
      {
        label: 'Last month',
        range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))]
      },
      { label: 'This year', range: [startOfYear(new Date()), endOfYear(new Date())] }
    ]);

    onMounted(() => {
      if (props.modelValue.length) date.value = props.modelValue as string[];
    });

    const onDateUpdate = (value: Date[]) => {
      const tansformedDate: string[] = [];
      if (value) {
        const isBefore = moment(value[0]).isBefore(moment());

        value.forEach((data: Date) => {
          if (data) {
            tansformedDate.push(moment(data).format('YYYY-MM-DD'));
          } else {
            if (isBefore) {
              tansformedDate.push(moment().format('YYYY-MM-DD'));
            } else {
              tansformedDate.push(moment(value[0]).format('YYYY-MM-DD'));
            }

            date.value = tansformedDate;
          }
        });
      }
      emit(UPDATE_MODEL_VALUE, tansformedDate);
    };

    return {
      date,
      presetRanges,
      onDateUpdate,
      format
    };
  }
});
