
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { PRODUCT, SHOP } from '@/const/routes';
import { useQuery, useResult } from '@vue/apollo-composable';
import { SearchItemsDocument } from '@/generated/graphql';
import { debounce } from 'lodash';

export default defineComponent({
  name: 'header-search',
  prop: {
    adClass: {
      type: String,
      default: ''
    },
    adClass1: {
      type: String,
      default: ''
    }
  },
  setup() {
    const router = useRouter();
    const searchLoading = ref(false);

    const keywords = ref<string>('');

    const getVariabales = () => {
      const variables: { filters: { title?: string; inStock: boolean } } = {
        filters: { inStock: true }
      };

      if (keywords.value) variables.filters.title = keywords.value;
      return variables;
    };

    const { result, error, refetch } = useQuery(SearchItemsDocument, getVariabales());

    const items = useResult(result, null, (data) => data.items.data.reduce((acc, cur) => {
      acc.push({
        ...cur,
        label: `${cur?.product?.mpn} ${cur?.product?.product_description} ${cur?.product?.manufacturer?.name}`
      });
      return acc;
    }, []));

    const onItemsSearch = debounce((data) => {
      keywords.value = data;
      searchLoading.value = true;
      refetch({ filters: { title: data, inStock: true } })?.then(() => {
        searchLoading.value = false;
      });
    }, 300);

    const onItemSelect = (id) => {
      keywords.value = '';
      router.push({ name: PRODUCT, params: { id } });
    };

    return {
      keywords,
      onItemsSearch,
      searchLoading,
      refetch,
      error,
      onItemSelect,
      items,
      SHOP,
      result
    };
  }
});
