// auth
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import { Commit } from 'vuex';
import { AuthState } from '@/types/auth';
import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT, SET_CART_ID, SET_TOTAL_COUNT } from '@/const/modules';
import { Maybe } from 'yup/lib/types';
import { Permission, Role, User } from '@/generated/graphql';
import { removeToken } from '@/helpers/token';
import { LOGIN } from '@/const/routes';

const user: User | null = JSON.parse(localStorage.getItem('user') as string) as User;

const initialState = user
  ? {
    status: {
      loggedIn: true
    },
    user,
    cartId: '',
    enabledCartUpdated: true,
    enabledNewOutbid: true,
    totalCount: ''
  }
  : {
    status: {
      loggedIn: false
    },
    user: null,
    cartId: '',
    enabledCartUpdated: false,
    enabledNewOutbid: false,
    totalCount: ''
  };

const authModule = {
  namespaced: true,
  state: initialState,
  getters: {
    isUserLoggedIn: (state: AuthState): boolean => state.status.loggedIn,
    userPermissions: (state: AuthState): Maybe<Array<Maybe<Permission>>> => state?.user?.permissions,
    userRoles: (state: AuthState): Maybe<Array<Maybe<Role>>> => state?.user?.roles,
    cartId: (state: AuthState): string => state?.cartId,
    getUser: (state: AuthState): Maybe<User> => state?.user,
    enabledCartUpdated: (state: AuthState): boolean => state?.enabledCartUpdated,
    enabledNewOutbid: (state: AuthState): boolean => state?.enabledNewOutbid
  },
  actions: {
    logout({ commit }: { commit: Commit }): void {
      commit(LOGOUT);
      if (router.currentRoute.value?.matched[0]?.name !== LOGIN) {
        router.push({ name: LOGIN });
      }
    },
    loginSuccess({ commit }: { state: AuthState; commit: Commit }, payload: User): void {
      commit(LOGIN_SUCCESS, payload);
      localStorage.setItem('user', JSON.stringify(payload));
    },
    loginFailure({ commit }: { state: AuthState; commit: Commit }): void {
      commit(LOGIN_FAILURE);
    },
    setCartId({ commit }: { state: AuthState; commit: Commit }, payload: string): void {
      commit(SET_CART_ID, payload);
    },
    setTotalCount({ commit }: { state: AuthState; commit: Commit }, payload: string): void {
      commit(SET_TOTAL_COUNT, payload);
    }
  },
  mutations: {
    [LOGOUT](state: AuthState): void {
      localStorage.clear();
      removeToken();
      state.status.loggedIn = false;
      state.user = null;
      state.cartId = '';
      state.enabledCartUpdated = false;
      state.enabledNewOutbid = false;
      state.enabledCart = false;
    },
    [LOGIN_SUCCESS](state: AuthState, incomeUser: User | null): void {
      state.status.loggedIn = true;
      state.user = incomeUser;
    },
    [LOGIN_FAILURE](state: AuthState): void {
      state.status.loggedIn = false;
      state.user = null;
      state.cartId = '';
      state.enabledCartUpdated = false;
      state.enabledNewOutbid = false;
    },
    [SET_CART_ID](state: AuthState, id: string): void {
      state.cartId = id;
      state.enabledCartUpdated = true;
      state.enabledNewOutbid = true;
    },
    [SET_TOTAL_COUNT](state: AuthState, count: string): void {
      state.totalCount = count;
    }
  }
};

export default authModule;
