import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, idx) => {
    return (_openBlock(), _createBlock(_component_b_form_checkbox, {
      value: item.id,
      key: idx,
      ref_for: true,
      ref: "v-checkbox",
      disabled: _ctx.disabled,
      onInput: _ctx.checkItem,
      modelValue: _ctx.checkValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkValue) = $event))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(item.name), 1)
      ]),
      _: 2
    }, 1032, ["value", "disabled", "onInput", "modelValue"]))
  }), 128))
}