
import { DELETE_ITEM } from '@/const/events';
import { FileType } from '@/types/files';
import { defineComponent, ref, watch, PropType, computed } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';

export default defineComponent({
  display: 'Transition',
  emits: [DELETE_ITEM],
  components: {
    draggable: VueDraggableNext
  },
  props: {
    files: {
      type: Array as PropType<FileType[]>,
      required: true
    }
  },
  setup(props, { emit }) {
    const list = ref<FileType[] | []>(props.files);

    watch(
      () => props.files,
      (newVal) => {
        list.value = newVal;
      },
      { deep: true }
    );

    const dragOptions = computed(() => ({
      animation: 0,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost'
    }));

    const deleteFile = (id: string) => {
      emit(DELETE_ITEM, id);
    };

    return {
      list,
      dragOptions,
      deleteFile
    };
  }
});
