export interface AuctionState {
  isBeginning: boolean;
  submittedLotIds: Array<number>;
  maxBid: number;
}

// eslint-disable-next-line no-shadow
export enum AuctionsActionsEnum {
  setBeginning = 'auctions/setBeginning',
  setSubmittedLotId = 'auctions/setSubmittedLotId',
  removeSubmittedLotId = 'auctions/removeSubmittedLotId',
  setMaxBid = 'auctions/setMaxBid'
}

// eslint-disable-next-line no-shadow
export enum AuctionsGetterEnum {
  getBeginning = 'auctions/getBeginning',
  getSubmittedLotIds = 'auctions/getSubmittedLotIds',
  getMaxBid = 'auctions/getMaxBid'
}
