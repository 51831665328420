import { Category } from '@/generated/graphql';

export const getCategoryWithChildren = (category: Category): string[] => {
  const queryParams = [category.id];
  if (category.children) {
    category.children.forEach((item) => {
      if (item?.id) queryParams.push(item.id);
      if (item?.children) {
        item.children.forEach((child) => {
          if (child?.id) queryParams.push(child.id);
        });
      }
    });
  }
  return queryParams;
};

export default {};
