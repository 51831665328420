import { ref, computed, ComputedRef, Ref } from 'vue';
import { TreeCategory } from '@/types/tree';

export default function useSearchedTreeItems(items: TreeCategory[]): {
  searchQuery: Ref<string>;
  searchedTreeItems: ComputedRef<TreeCategory[]>;
} {
  const searchQuery = ref('');
  // eslint-disable-next-line max-len
  const searchedTreeItems = computed(() =>
    items ? items.filter((item) => item.text.toLowerCase().includes(searchQuery.value.toLowerCase())) : []
  );

  return {
    searchQuery,
    searchedTreeItems
  };
}
