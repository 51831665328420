
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import { CartAddItemDocument, Item } from '@/generated/graphql';
import { notify } from '@kyvg/vue3-notification';
import { useMutation } from '@vue/apollo-composable';
import { UNLIMITED_QUANTITY, CONTACT_PHONE_NUMBER } from '@/const/const';
import { LOGIN, REGISTER } from '@/const/routes';

export default defineComponent({
  name: 'product-grid-card',
  props: {
    product: Object as PropType<Item>,
    adClass: String,
    isActions: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore();
    const quantity = ref(1);

    const isUserLoggedIn = computed(() => store.state.auth.status.loggedIn);

    const conditionLabel = (id: string): string => {
      if (id === '1') return 'background-color: #25d0bb';
      if (id === '2') return 'background-color: #3e7ce0';
      return 'background-color: #ffc107';
    };

    const { mutate } = useMutation(CartAddItemDocument);
    const cartId = computed(() => store.state.auth.cartId);

    const addToCart = (product) => {
      const params: {quantity: number, id?: string, itemId: string} = {
        quantity: Number(quantity.value), itemId: product.id
      };
      if(cartId.value) params.id = cartId.value;

      mutate(params).then((res) => {
        if (!res?.errors?.length) {
          notify({
            text: 'has been added to your cart!',
            data: product
          });
        }
      });
    };

    const totalAvailable = computed(() =>
      props.product?.unlimited_quantity ? UNLIMITED_QUANTITY : props.product?.quantity
    );

    const validQty = computed(
      () =>
        quantity.value > 0 &&
        quantity.value <= (totalAvailable.value as unknown as number) &&
        props?.product?.sell_price
    );

    return {
      conditionLabel,
      addToCart,
      quantity,
      validQty,
      totalAvailable,
      UNLIMITED_QUANTITY,
      CONTACT_PHONE_NUMBER,
      LOGIN,
      REGISTER,
      isUserLoggedIn
    };
  }
});
