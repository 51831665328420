export const sortOptions = [
  { name: 'Description (default)', id: 'description' },
  { name: 'Newest Additions', id: 'newest' },
  { name: 'Price Low To High', id: 'pricelow' },
  { name: 'Price High To Low', id: 'pricehigh' },
  { name: 'Quantity', id: 'quantity' },
  { name: 'Manufacturer', id: 'manufacturer' }
];

export default {};
