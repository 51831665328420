
import { DELETE_ITEM, SET_MODAL } from '@/const/events';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'confirm-modal',
  emits: [SET_MODAL, DELETE_ITEM],
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    itemId: {
      type: String,
      require: true
    }
  },
  setup(props, { emit }) {
    const isShow = ref(props.openModal);

    const closeModal = () => {
      emit(SET_MODAL);
    };

    const removeItem = () => {
      emit(DELETE_ITEM, props.itemId);
    };

    watch(
      () => props.openModal,
      (newVal) => {
        isShow.value = newVal;
      }
    );

    return {
      closeModal,
      isShow,
      removeItem
    };
  }
});
