import { Commit } from 'vuex';
import { AuctionState } from '@/types/auctions';
import { SET_AUCTION_BEGGINING, SET_SUBMITTED_LOT_ID, REMOVE_SUBMITTED_LOT_ID, SET_MAX_BID } from '@/const/modules';

const auctionsModule = {
  namespaced: true,
  state: {
    isBeginning: true,
    submittedLotIds: [],
    maxBid: 0
  },
  getters: {
    getBeginning: (state: AuctionState): boolean => state.isBeginning,
    getSubmittedLotIds: (state: AuctionState): Array<number> => state.submittedLotIds,
    getMaxBid: (state: AuctionState): number => state.maxBid
  },
  actions: {
    setBeginning({ commit }: { state: AuctionState; commit: Commit }, payload: boolean): void {
      commit(SET_AUCTION_BEGGINING, payload);
    },
    setSubmittedLotId({ commit }: { state: AuctionState; commit: Commit }, payload: number): void {
      commit(SET_SUBMITTED_LOT_ID, payload);
    },
    removeSubmittedLotId({ commit }: { state: AuctionState; commit: Commit }, payload: number): void {
      commit(REMOVE_SUBMITTED_LOT_ID, payload);
    },
    setMaxBid({ commit }: { state: AuctionState; commit: Commit }, payload: number): void {
      commit(SET_MAX_BID, payload);
    }
  },
  mutations: {
    [SET_AUCTION_BEGGINING](state: AuctionState, value: boolean): void {
      state.isBeginning = value;
    },
    [SET_SUBMITTED_LOT_ID](state: AuctionState, value: number): void {
      state.submittedLotIds.push(value);
    },
    [REMOVE_SUBMITTED_LOT_ID](state: AuctionState, value: number): void {
      state.submittedLotIds.splice(state.submittedLotIds.indexOf(value), 1);
    },
    [SET_MAX_BID](state: AuctionState, value: number): void {
      state.maxBid = value;
    }
  }
};

export default auctionsModule;
