
import vErrorText from '@/components/vErrorText.vue';
import { SET_MODAL, UPDATED_PAGE } from '@/const/events';
import {
  UserAddress,
  CreateUserAddressDocument,
  UpdateUserAddressDocument,
  UserAddressTypeEnum
} from '@/generated/graphql';
import { useMutation } from '@vue/apollo-composable';
import { computed, defineComponent, onBeforeMount, PropType, ref, watch } from 'vue';

export default defineComponent({
  components: { vErrorText },
  name: 'address-modal',
  emits: [SET_MODAL, UPDATED_PAGE],
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    addressType: {
      type: String,
      default: ''
    },
    address: {
      type: Object as PropType<UserAddress>
    }
  },
  setup(props, { emit }) {
    const isShow = ref(props.openModal);
    const attentionTo = ref('');
    const addr1 = ref('');
    const addr2 = ref('');
    const city = ref('');
    const state = ref('');
    const zip = ref<string | number>("");
    const zipError = ref('');

    const { mutate: createAddress } = useMutation(CreateUserAddressDocument);
    const { mutate: updateAddress } = useMutation(UpdateUserAddressDocument);

    const getVariables = (key: string) => {
      let variables;

      if (key === 'update') {
        variables = {
          id: props.address?.id,
          input: {
            type:
            props.addressType === 'shipping' ? UserAddressTypeEnum.Shipping : UserAddressTypeEnum.Billing,
            is_primary: false,
            active: true,
            address: {
              zip: zip.value.toString(),
              attention_to: attentionTo.value,
              addr1: addr1.value,
              addr2: addr2.value,
              city: city.value,
              state_or_province: state.value,
              country_id: process.env.VUE_APP_COUNTRY_ID
            }
          }
        };
      } else {
        variables = {
          input: {
            type:
            props.addressType === 'shipping' ? UserAddressTypeEnum.Shipping : UserAddressTypeEnum.Billing,
            is_primary: false,
            active: true,
            address: {
              zip: zip.value.toString(),
              attention_to: attentionTo.value,
              addr1: addr1.value,
              addr2: addr2.value,
              city: city.value,
              state_or_province: state.value,
              country_id: process.env.VUE_APP_COUNTRY_ID
            }
          }
        };
      }
      return variables;
    };

    const clearForm = () => {
      attentionTo.value = '';
      addr1.value = '';
      addr2.value = '';
      city.value = '';
      state.value = '';
      zip.value = "";
    };

    const updateData = () => {
      emit(UPDATED_PAGE);
      emit(SET_MODAL);
      clearForm();
    };

    const handleAddAddress = () => {
      if (props.address) {
        updateAddress(getVariables('update')).then((res) => {
          if (!res?.errors?.length) {
            updateData();
            if (zipError.value) zipError.value = '';
          } else {
            zipError.value = res?.errors[0]?.extensions?.validation['input.address.zip'][0];
          }
        });
      } else {
        createAddress(getVariables('create')).then((res) => {
          if (!res?.errors?.length) {
            updateData();
            if (zipError.value) zipError.value = '';
          } else {
            zipError.value = res?.errors[0]?.extensions?.validation['input.address.zip'][0];
          }
        });
      }
    };

    const closeModal = () => {
      emit(SET_MODAL);
      clearForm();
    };

    onBeforeMount(() => {
      if (props.address) {
        attentionTo.value = props.address?.address?.attention_to || '';
        addr1.value = props.address?.address?.addr1;
        addr2.value = props.address?.address?.addr2 || '';
        city.value = props.address?.address?.city;
        state.value = props.address?.address?.state_or_province || '';
        zip.value = props.address?.address?.zip || "";
      }
    });

    watch(
      () => props.openModal,
      (newVal) => {
        isShow.value = newVal;
      }
    );

    watch(
      () => props.address,
      (newVal) => {
        attentionTo.value = newVal?.address?.attention_to || '';
        addr1.value = newVal?.address?.addr1 || '';
        addr2.value = newVal?.address?.addr2 || '';
        city.value = newVal?.address?.city || '';
        state.value = newVal?.address?.state_or_province || '';
        zip.value = newVal?.address?.zip || "";
      }
    );

    const disabledBtn = computed(() => !attentionTo.value || !addr1.value || !city.value || !state.value || !zip.value);

    return {
      isShow,
      closeModal,
      handleAddAddress,
      addr1,
      addr2,
      city,
      zip,
      state,
      attentionTo,
      disabledBtn,
      zipError
    };
  }
});
