
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue';
import { LineChart, useLineChart } from 'vue-chart-3';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import moment from 'moment';

Chart.register(...registerables);

export default defineComponent({
  name: 'line-chart',
  components: { LineChart },
  props: {
    chartData: {
      type: Array as PropType<{ date: string; total: number }[] | []>
    }
  },
  setup(props) {
    const labels = ref<string[]>([]);
    const total = ref<number[]>([]);

    const getData = computed<ChartData<'line'>>(() => ({
      labels: labels.value,
      datasets: [
        {
          label: '',
          data: total.value,
          fill: false,
          borderColor: '#4bc0c0'
        }
      ]
    }));

    const options = computed<ChartOptions<'line'>>(() => ({
      plugins: {
        legend: {
          display: false
        }
      }
    }));

    onMounted(() => {
      props?.chartData?.forEach((val) => {
        labels.value.push(moment(val.date, 'YYYY-MM-DD').format('MMM YY'));
        total.value.push(val.total);
      });
    });

    watch(
      () => props.chartData,
      (newVal) => {
        if (newVal) {
          newVal.forEach((val) => {
            labels.value.push(moment(val.date, 'YYYY-MM-DD').format('MMM YY'));
            total.value.push(val.total);
          });
        }
      }
    );

    const { lineChartProps } = useLineChart({
      options,
      chartData: getData
    });

    return {
      getData,
      options,
      lineChartProps
    };
  }
});
