
import { defineComponent, computed } from 'vue';
import moment from 'moment';
import { SHOP, LOGIN, FAQ, CONTACT } from '@/const/routes';

export default defineComponent({
  name: 'main-footer',
  setup() {
    const year = computed(() => moment().format('YYYY'));

    return {
      SHOP,
      LOGIN,
      FAQ,
      CONTACT,
      year
    };
  }
});
