import { createToaster } from '@meforma/vue-toaster';

export const showToast = (message = '', type = 'success', duration = 4000): void => {
  const toaster = createToaster({
    type,
    position: 'top-right',
    duration
  });
  toaster.show(message);
};

export default {};
