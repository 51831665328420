
import { ITEMS_SEARCH, UPDATE_MODEL_VALUE } from '@/const/events';
import { defineComponent, PropType } from 'vue';

type item = {
  name: string;
  id: string;
};

export default defineComponent({
  name: 'v-base-select',
  data() {
    return {
      internalValue: ''
    };
  },
  props: {
    items: {
      type: Array as PropType<item[] | []>,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    sort: {
      type: String,
      default: ''
    },
    searchKey: {
      type: String,
      default: ''
    }
  },
  methods: {
    onInput(data: string) {
      this.$emit(UPDATE_MODEL_VALUE, data);
      this.$emit(ITEMS_SEARCH, this.searchKey, data);
    }
  },
  mounted() {
    this.internalValue = this.modelValue ? this.modelValue : this.sort;
  },
  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
    }
  }
});
