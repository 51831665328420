import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tree-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_input = _resolveComponent("v-input")!
  const _component_Tree = _resolveComponent("Tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasInputSearch)
      ? (_openBlock(), _createBlock(_component_v_input, {
          key: 0,
          type: "text",
          class: "input-search",
          placeholder: "Type to search",
          modelValue: _ctx.searchQuery,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Tree, {
      id: "tree",
      ref: "tree",
      "custom-options": _ctx.customOptions,
      "custom-styles": _ctx.customStyles,
      nodes: _ctx.searchedTreeItems
    }, null, 8, ["custom-options", "custom-styles", "nodes"])
  ]))
}