
import { Order } from '@/generated/graphql';
import moment from 'moment';
import { PRODUCT } from '@/const/routes';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'v-accordion',
  props: {
    orders: {
      type: Array as PropType<Order[]>
    }
  },
  setup() {
    const createAt = (date) => moment(date).format('MM/DD/YYYY');

    return {
      createAt,
      PRODUCT
    };
  }
});
