import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "submit",
    class: "btn btn-secondary btn-md w-100",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.recaptcha && _ctx.recaptcha(...args)), ["prevent"])),
    disabled: _ctx.disabled
  }, _toDisplayString(_ctx.title), 9, _hoisted_1))
}