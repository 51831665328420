import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b471c87c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-default left-details product-list" }
const _hoisted_2 = { class: "product-details" }
const _hoisted_3 = { class: "product-title" }
const _hoisted_4 = {
  class: "price-box",
  key: "singlePrice"
}
const _hoisted_5 = { class: "product-price" }
const _hoisted_6 = { class: "product-action cart-table-container table.table-cart tfoot btn btn-shop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_confirm_modal = _resolveComponent("confirm-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_slider, {
      slides: _ctx.product?.images
    }, null, 8, ["slides"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: { name: _ctx.PRODUCT, params: { id: _ctx.product?.id } }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.product?.product?.manufacturer?.name) + " " + _toDisplayString(_ctx.product?.product?.product_description) + " " + _toDisplayString(_ctx.product?.product?.mpn), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("p", null, "Listed: " + _toDisplayString(_ctx.product?.created_at), 1),
      _createElementVNode("p", null, "Quantity: " + _toDisplayString(_ctx.product?.unlimited_quantity ? 'Unlimited available' : `${_ctx.product?.quantity} available`), 1),
      _createElementVNode("p", null, "Unit of Measure: " + _toDisplayString(_ctx.product?.quantityType?.name), 1),
      (_ctx.product?.sell_price)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, "Value: $" + _toDisplayString(_ctx.product.sell_price.toFixed(2)), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        type: "submit",
        class: "btn btn-shop btn-update-cart",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push({ name: _ctx.PRODUCT, params: { id: _ctx.product?.id } })))
      }, " View Listing "),
      _createElementVNode("button", {
        class: "btn btn-dark btn-update-cart mr-4",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.router.push({ name: _ctx.ITEMS_CREATE, params: { id: _ctx.product?.id } })))
      }, " Edit Listing "),
      _createElementVNode("button", {
        class: "btn remove mr-4",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
      }, "Remove Listing")
    ]),
    _createVNode(_component_confirm_modal, {
      openModal: _ctx.isShow,
      onSetModal: _ctx.setModal,
      onDeleteItem: _ctx.removeItem,
      itemId: _ctx.product?.id
    }, null, 8, ["openModal", "onSetModal", "onDeleteItem", "itemId"])
  ]))
}