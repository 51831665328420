import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27681b32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-middle sticky-header mobile-sticky" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "header-left" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "header-col" }
const _hoisted_7 = {
  key: 0,
  class: "cart-wrapper"
}
const _hoisted_8 = {
  key: 0,
  class: "cart-items-quantity"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_categories_menu = _resolveComponent("categories-menu")!
  const _component_header_search = _resolveComponent("header-search")!
  const _component_fa = _resolveComponent("fa")!
  const _component_main_menu = _resolveComponent("main-menu")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: { name: _ctx.HOME },
            class: "logo"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: require('@/static/images/handle-logo.png'),
                width: "101",
                height: "40",
                alt: "Handle Logo"
              }, null, 8, _hoisted_5)
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_categories_menu),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_header_search)
          ])
        ]),
        (_ctx.isUserLoggedIn)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_router_link, {
                to: { name: _ctx.CART }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_fa, {
                    icon: "shopping-cart",
                    "aria-hidden": "true"
                  }),
                  (_ctx.totalCount)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.totalCount), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["to"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_main_menu)
      ])
    ])
  ]))
}