
import { defineComponent, PropType, onMounted, ref, inject } from 'vue';
import { Emitter, EventType } from 'mitt';
import { ITEMS_SEARCH, UPDATE_MODEL_VALUE, CLEAR_FILTER } from '@/const/events';

interface Option {
  id: string;
  name: string;
}

export default defineComponent({
  name: 'v-form-checkbox-group',
  emits: [ITEMS_SEARCH, UPDATE_MODEL_VALUE, CLEAR_FILTER],
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array as PropType<Option[]>,
      default: () => []
    },
    searchKey: {
      type: String
    }
  },
  setup(props, { emit }) {
    const checkValue = ref<string[]>([]);
    const checkItem = (ev) => {
      emit(UPDATE_MODEL_VALUE, ev);
      emit(ITEMS_SEARCH, props.searchKey, ev);
    };

    onMounted(() => {
      if (props.modelValue) checkValue.value = [...props.modelValue];
    });

    const emitter: Emitter<Record<EventType, unknown>> | undefined = inject('emitter');

    emitter?.on(CLEAR_FILTER, (key) => {
      if (key === props.searchKey) checkValue.value = [];
    });

    return {
      checkValue,
      checkItem
    };
  }
});
