
import { UPDATE_MODEL_VALUE } from '@/const/events';
import { defineComponent, ref, onMounted, watch } from 'vue';

export default defineComponent({
  name: 'v-form-radio-group',
  emits: [UPDATE_MODEL_VALUE],
  props: {
    modelValue: {
      type: [String],
      required: true
    },
    borderRadius: {
      type: [String, Number],
      default: 0
    },
    refName: {
      type: String,
      default: 'v-form-radio-group'
    },
    theme: {
      type: String,
      default: 'base'
    },
    options: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const checkValue = ref<string | boolean>('');

    const checkItem = (ev) => {
      emit(UPDATE_MODEL_VALUE, ev);
    };

    onMounted(() => {
      if (props.modelValue) checkValue.value = props.modelValue;
    });

    watch(
      () => props.modelValue,
      (newVal) => {
        checkValue.value = newVal;
      }
    );

    return {
      checkValue,
      checkItem
    };
  }
});
