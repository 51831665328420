import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle({ 'font-size': _ctx.size }),
        class: _normalizeClass([[`text-${_ctx.variant}`], "mt-1 mb-2"])
      }, _toDisplayString(_ctx.error), 7))
    : _createCommentVNode("", true)
}