
import { Item } from '@/generated/graphql';
import { defineComponent, PropType } from 'vue';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';

export default defineComponent({
  name: 'media-section',
  props: {
    product: Object as PropType<Item>
  }
});
