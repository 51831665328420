import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "v-dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_dropdown, { value: _ctx.selectedItem }, {
      "button-content": _withCtx(() => [
        _createElementVNode("span", {
          class: _normalizeClass(["title", { active: _ctx.selectedItem }])
        }, [
          _createTextVNode(_toDisplayString(_ctx.selectedItem ? _ctx.selectedItem : _ctx.title) + " ", 1),
          _createVNode(_component_fa, { icon: "caret-down" })
        ], 2)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_component_b_dropdown_item, {
            key: item?.id,
            onClick: ($event: any) => (_ctx.onSelectedItem(item))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item?.name), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}