
import { UPDATED_LIMIT, UPDATED_PAGE } from '@/const/events';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'v-per-page',
  props: {
    pagination: {
      type: Object,
      required: true
    },
    currentPage: {
      default: 0,
      type: Number
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    limitOptions() {
      return [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '200', value: 200 }
      ];
    },
    disabledPrev() {
      // eslint-disable-next-line
      const { currentPage } = this.pagination;
      // eslint-disable-next-line
      return currentPage === 1;
    },
    disabledNext() {
      // eslint-disable-next-line
      const { hasMorePages } = this.pagination;
      // eslint-disable-next-line
      return !hasMorePages;
    },
    disabledLimit() {
      const { total } = this.pagination;
      const { value } = this.limitOptions[0];
      return this.loading || total < value;
    },
    totalText() {
      const { firstItem, total, lastItem } = this.pagination;

      return total ? `Items: ${firstItem}-${lastItem} of ${total}` : '';
    }
  },
  methods: {
    onPrevClick() {
      const page = this.currentPage;
      if (this.loading || this.disabledPrev) return;
      this.$emit(UPDATED_PAGE, page - 1);
    },
    onNextClick() {
      const page = this.currentPage;
      if (this.loading || this.disabledNext) return;
      this.$emit(UPDATED_PAGE, page + 1);
    },
    onLimitSelect(val) {
      this.$emit(UPDATED_LIMIT, val);
    }
  }
});
