<template>
  <Modal v-model="isShow" :close="closeModal">
    <div class="modal-wrapper product-modal">
      <div class="container">
        <div class="header">
          <h2 class="title">Request Product</h2>
          <button class="close btn btn-default g-0" @click="closeModal">x</button>
        </div>
        <label for="attentionTo">
          First Name
          <span class="required">*</span>
          <input
            disabled
            id="user.firstName"
            type="text"
            class="form-input form-wide mb-2"
            required
            v-model="user.first_name"
          />
        </label>
        <label for="lastName">
          Last Name
          <span class="required">*</span>
          <input
            disabled
            id="lastName"
            type="text"
            class="form-input form-wide mb-2"
            required
            v-model="user.last_name"
          />
        </label>
        <label for="email">
          Email
          <span class="required">*</span>
          <input
            disabled
            type="text"
            class="form-input form-wide mb-2"
            required
            placeholder="Email"
            v-model="user.email"
            id="email"
          />
        </label>

        <label for="phone">
          Mobile Phone
          <input
            id="phone"
            type="text"
            class="form-input form-wide mb-2"
            required
            v-model="phone"
            :class="phone !== null ? 'disabled' : ''"
          />
        </label>

        <label for="manufacturerName">
          Requested Manufacturer
          <span class="required">*</span>
          <input
            id="manufacturerName"
            type="text"
            class="form-input form-wide mb-2"
            required
            v-model="manufacturerNameVal"
            :disabled="manufacturerName.length != 0"
          />
        </label>
        <label for="description">
          Description
          <textarea id="description" type="textarea" class="form-input form-wide mb-2" required v-model="description" />
        </label>

        <label for="mpn">
          Requested Mpn
          <span class="required">*</span>
          <input id="mpn" type="text" class="form-input form-wide mb-2" required v-model="mpn" />
        </label>

        <label for="product">
          Product Name
          <span class="required">*</span>
          <input id="product" type="text" class="form-input form-wide mb-2" required v-model="productName" />
          <v-error-text :error="requestProductError" />
        </label>

        <div class="col-lg-12 p-0 pb-5">
          <div class="action-wrap gap-4">
            <button @click="closeModal" class="btn cancel-btn mr-3">Cancel</button>

            <button
              :disabled="disabledBtn"
              type="submit"
              class="btn btn-secondary"
              @click.prevent="handleRequestProduct"
            >
              Submit
            </button>
          </div>
        </div>
        <button title="Close (Esc)" type="button" class="mfp-close" @click="closeModal">×</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import { SET_MODAL } from '@/const/events';
import { RequestProductDocument } from '@/generated/graphql';
import { useMutation } from '@vue/apollo-composable';
import vErrorText from '@/components/vErrorText.vue';
import { showToast } from '@/helpers/toast-handler';

export default defineComponent({
  name: 'add-product-modal',
  emits: [SET_MODAL],
  components: {
    vErrorText
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    manufacturerName: {
      type: String,
      default: ''
    },
    mpnName: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const isShow = ref(props.openModal);
    const user = ref(JSON.parse(localStorage.getItem('user')));
    const manufacturerNameVal = ref(props.manufacturerName);
    const phone = ref(user.value.phone ?? null);
    const description = ref('');
    const mpn = ref(props.mpnName);
    const productName = ref('');
    const requestProductError = ref('');

    const clearForm = () => {
      manufacturerNameVal.value = '';
      description.value = '';
      mpn.value = '';
      productName.value = '';
    };
    const { mutate: requestAddress } = useMutation(RequestProductDocument);

    const closeModal = () => {
      emit(SET_MODAL);
      clearForm();
    };

    const getVariables = () => {
      const variables = {
        RequestProductInput: {
          manufacturer_name: manufacturerNameVal.value,
          product_name: productName.value,
          mpn: mpn.value,
          phone: phone.value,
          description: description.value
        }
      };
      return variables;
    };

    const handleRequestProduct = () => {
      requestAddress(getVariables()).then((res) => {
        if (!res?.errors?.length) {
          clearForm();
          closeModal();
          showToast('Thank you for your submission. HANDLE will reply shortly.');
        } else {
          requestProductError.value = res?.errors[0].message;
        }
      });
    };

    const disabledBtn = computed(() => !manufacturerNameVal.value || !mpn.value || !productName.value);

    watch([() => props.openModal, () => props.manufacturerName], (newVal) => {
      const [openModalProp, manufacturerName] = newVal;
      isShow.value = openModalProp;
      manufacturerNameVal.value = manufacturerName;
    });
    watch(
      () => props.mpnName,
      (newVal) => {
        mpn.value = newVal;
      }
    );

    return {
      closeModal,
      isShow,
      requestProductError,
      manufacturerNameVal,
      user,
      phone,
      mpn,
      productName,
      disabledBtn,
      handleRequestProduct,
      description
    };
  }
});
</script>

<style lang="scss" scoped>
.product-modal {
  background-color: #fff;
  max-width: 800px;
  min-width: 600px;
  @media only screen and (max-width: 768px) {
    min-width: auto;
  }

  .container {
    display: flex;
    flex-direction: column;
  }
  .cancel-btn {
    margin-right: 10px;
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .close {
    padding: 20px;
    margin: -1rem -1rem -1rem auto;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: $black;
    text-shadow: 0 1px 0 $white;
    opacity: 0.5;
  }
  label {
    color: $black;
  }
  input {
    font-size: 1.4rem;
  }
}
.action-wrap {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  .btn {
    min-width: 160px;
  }
  .cancel-btn {
    background-color: $black;
    color: $white;
  }
}
</style>
